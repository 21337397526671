import { FormikErrors, FormikTouched } from 'formik';
import moment from 'moment-timezone';
import { DateAbsoluteRangePicker, DropdownModel } from 'src/models/AppContextModels';
import { getYearFromDate, isDateWithinRange, isEndOfTheMonth, isStartDateBeforeThanEndDate, isStartOfTheMonth } from 'src/utils/date-time-utilities';
import * as Yup from 'yup';
import { AdminPlanningCycleBusinessGroupsForm, AdminPlanningCycleForm } from '../../models/PlanningCycleFormModel';

// Schema for validating Planning Cycle Open & Lock date ranges.
const planningCycleWindowRangePickerSchema = Yup.object()
  .shape({
    startDate: Yup.string().nullable(true).required('Required'),
    endDate: Yup.string().nullable(true).required('Required')
  })
  .test('date-range-validation', 'Start date must be before end date', function (value) {
    const { startDate, endDate } = value;
    if (!isStartDateBeforeThanEndDate(startDate, endDate)) {
      return this.createError({
        path: `${this.path}`,
        message: { endDate: 'End date must be after start date' }
      });
    }
    return true;
  })
  .test('date-range-validation-with-bo-date', 'Forecast end date should be after budget owner lock date', function (value) {
    const { budget_owner_lock_date } = this.parent;
    const { endDate } = value;
    if (budget_owner_lock_date && !isStartDateBeforeThanEndDate(budget_owner_lock_date, endDate)) {
      return this.createError({
        path: `${this.path}`,
        message: { endDate: 'End date must be after budget owner lock date' }
      });
    }
    return true;
  });

// Schema for validating Actuals & Forecast date ranges.
const dateAbsoluteRangePickerSchema = Yup.object()
  .shape({
    startDate: Yup.string()
      .nullable(true)
      .required('Required')
      .test('start-date-of-month', 'Start date must be the start date of the month', function (value) {
        if (value && !isStartOfTheMonth(value) && moment(value).date() !== 1) {
          return this.createError({
            path: `${this.path}`,
            message: 'Start date must be the start date of the month'
          });
        }
        return true;
      }),
    endDate: Yup.string()
      .nullable(true)
      .required('Required')
      .test('end-date-of-month', 'End date must be the end date of the month', function (value) {
        if (value && !isEndOfTheMonth(value) && moment(value).date() !== moment(value).daysInMonth()) {
          return this.createError({
            path: `${this.path}`,
            message: 'End date must be the end date of the month'
          });
        }
        return true;
      })
  })
  .test('date-range-validation', 'Start date must be before end date', function (value) {
    const { startDate, endDate } = value;
    if (startDate && endDate && !isStartDateBeforeThanEndDate(startDate, endDate)) {
      return this.createError({
        path: `${this.path}`,
        message: { endDate: 'End date must be after start date' }
      });
    }
    return true;
  });

// Schema for validating a business group within the planning cycle.
const adminPlanningCycleBusinessGroupsFormSchema = Yup.object().shape({
  scenario_seq_id: Yup.number().nullable(true),
  data_classification: Yup.object().shape({
    label: Yup.string().required('Required'),
    value: Yup.string().required('Required')
  }),
  // baseline_scenario is optional
  // baseline_scenario: Yup.object().shape({
  //   label: Yup.string().required('Required'),
  //   value: Yup.string().required('Required')
  // }),

  prophecy_actuals_date_range: dateAbsoluteRangePickerSchema
    .test('is-before-prophecy-forecast', 'Prophecy Actuals date range should be before Prophecy Forecast start month', function (value) {
      const { prophecy_forecast_date_range } = this.parent;
      if (!value.startDate || !value.endDate || !prophecy_forecast_date_range) return true; // Skip validation if either range is not provided

      const isProphecyActualsStartBeforeProphecyForecastStart = moment(value.startDate).isBefore(prophecy_forecast_date_range.startDate);
      const isProphecyActualsEndBeforeProphecyForecastStart = moment(value.endDate).isBefore(prophecy_forecast_date_range.startDate);

      return isProphecyActualsStartBeforeProphecyForecastStart && isProphecyActualsEndBeforeProphecyForecastStart;
    })
    .nullable(true),

  prophecy_forecast_date_range: dateAbsoluteRangePickerSchema
    .test('is-after-actuals', 'Prophecy Forecast date range should be after actuals date range', function (value) {
      const { actuals_date_range } = this.parent;
      return isStartDateBeforeThanEndDate(actuals_date_range.endDate, value.startDate);
    })
    .test('is-within-forecast', 'Prophecy Forecast date range should be equal to or within the Forecast date range', function (value) {
      const { forecast_date_range } = this.parent;
      if (!value.startDate || !value.endDate || !forecast_date_range) return true; // Skip validation if either range is not provided

      const isProphecyStartAfterOrEqualForecastStart = moment(value.startDate).isSameOrAfter(forecast_date_range.startDate);
      const isProphecyEndBeforeOrEqualForecastEnd = moment(value.endDate).isSameOrBefore(forecast_date_range.endDate);

      return isProphecyStartAfterOrEqualForecastStart && isProphecyEndBeforeOrEqualForecastEnd;
    })
    .nullable(true),
  planning_cycle_date_time_range: planningCycleWindowRangePickerSchema.nullable(true),
  is_active: Yup.boolean().required('Required'),

  actuals_date_range: dateAbsoluteRangePickerSchema.nullable(true),

  forecast_date_range: dateAbsoluteRangePickerSchema
    .test('is-after-actuals', 'Forecast date range should be after actuals date range', function (value) {
      const { actuals_date_range } = this.parent;
      return isStartDateBeforeThanEndDate(actuals_date_range.endDate, value.startDate);
    })
    .nullable(true)
});

// Main schema for validating the entire admin planning cycle form.
export const adminPlanningCycleFormSchema = (masterScenarioYearList: string[]) => {
  return Yup.object().shape({
    scenario: Yup.object()
      .shape({
        label: Yup.string().required('Required'),
        value: Yup.string().required('Required')
      })
      .required('Required')
      .nullable(true),
    scenario_year: Yup.string().test('unique-scenario-year', 'Planning cycle already exists.', function (value) {
      return !value ? true : !masterScenarioYearList.includes(value);
    }),
    planning_cycle_name: Yup.object()
      .shape({
        label: Yup.string().required('Required'),
        value: Yup.string().required('Required')
      })
      .required('Required'),
    business_groups: Yup.array()
      .of(adminPlanningCycleBusinessGroupsFormSchema)
      .min(1, 'At least one business group is required')
      .required('Required')
      .test('consistent-forecast-year', 'The year in forecast date range must be consistent across all business groups', function (businessGroups) {
        if (!businessGroups || businessGroups.length === 0) {
          return true; // No business groups to validate
        }

        const firstYear = getYearFromDate(businessGroups[0].forecast_date_range.startDate);
        return businessGroups.every((group) => {
          const year = getYearFromDate(group.forecast_date_range.startDate);
          return year === firstYear;
        });
      })
  });
};

// Function to retrieve error for data classification field.
export const getDataClassificationError = (
  touched: FormikTouched<AdminPlanningCycleForm>,
  errors: FormikErrors<AdminPlanningCycleForm>,
  index: number
): string => {
  const error = (errors.business_groups?.[index] as FormikErrors<AdminPlanningCycleBusinessGroupsForm>)
    ?.data_classification as FormikErrors<DropdownModel>;
  const isTouched = touched.business_groups?.[index]?.data_classification;
  if (!isTouched || !error) {
    return '';
  }

  if (typeof error === 'string') {
    return error;
  }

  return error?.label || ''; // Errors on specific fields
};

// Function to retrieve error for actual date range field.
export const getBaselineError = (
  touched: FormikTouched<AdminPlanningCycleForm>,
  errors: FormikErrors<AdminPlanningCycleForm>,
  index: number
): string => {
  const error = (errors.business_groups?.[index] as FormikErrors<AdminPlanningCycleBusinessGroupsForm>)
    ?.baseline_scenario as FormikErrors<DropdownModel>;
  const isTouched = touched.business_groups?.[index]?.baseline_scenario;

  if (!isTouched || !error) {
    return '';
  }

  if (typeof error === 'string') {
    return error;
  }

  return error?.label || '';
};

type DateRangeError = string | FormikErrors<DateAbsoluteRangePicker> | undefined;

/**
 * Generic function to get date range errors
 * @param touched Formik touched object
 * @param errors Formik errors object
 * @param index Index of the business group
 * @param fieldName Name of the date range field
 * @returns Error message string
 */
export const getDateRangeError = (
  touched: FormikTouched<AdminPlanningCycleForm>,
  errors: FormikErrors<AdminPlanningCycleForm>,
  index: number,
  fieldName: keyof Pick<
    AdminPlanningCycleBusinessGroupsForm,
    'actuals_date_range' | 'forecast_date_range' | 'prophecy_actuals_date_range' | 'prophecy_forecast_date_range'
  >
): string => {
  const error = (errors.business_groups?.[index] as FormikErrors<AdminPlanningCycleBusinessGroupsForm>)?.[fieldName] as DateRangeError;
  const isTouched = touched.business_groups?.[index]?.[fieldName];

  if (!isTouched || !error) {
    return '';
  }

  if (typeof error === 'string') {
    return error;
  }

  return (error as FormikErrors<DateAbsoluteRangePicker>).startDate || (error as FormikErrors<DateAbsoluteRangePicker>).endDate || '';
};

export const NonAdminPlanningCycleFormSchema = Yup.object().shape({
  scenario_seq_id: Yup.number().nullable(true).required('Scenario sequence ID is required.'),
  scenario_year: Yup.string().required('Scenario year is required.'),
  planning_cycle_date_time_range: Yup.object().required('Planning cycle date time range is required.'),
  datePickerValue: Yup.string().required('Date picker value is required.'),
  timePickerValue: Yup.string().required('Time picker value is required.'),
  budget_owner_lock_date: Yup.string()
    .test(
      'budget_owner_lock_date-combined-validation',
      'Budget owner lock date is required and must be valid date and time values.',
      function (value) {
        const { datePickerValue, timePickerValue } = this.parent;
        return datePickerValue && timePickerValue;
      }
    )
    .test('is-within-range', 'Budget owner lock date must be within the planning cycle date range.', function (value) {
      const { planning_cycle_date_time_range } = this.parent;
      if (!value || !planning_cycle_date_time_range.startDate || !planning_cycle_date_time_range.endDate) {
        return false;
      }
      return isDateWithinRange(value, planning_cycle_date_time_range.startDate, planning_cycle_date_time_range.endDate);
    }),
  baseline_scenario: Yup.object({
    baseline_scenario_name: Yup.string().nullable(true),
    baseline_scenario_seq_id: Yup.number().nullable(true)
  }).nullable(true)
});
