import { BreadcrumbGroupProps, ButtonDropdownProps } from '@amzn/awsui-components-react';
import { CellValueChangedEvent } from 'ag-grid-community';
import { logger } from 'src/analytics/KatalLogger';
import { BusinessSegmentDataType, CorpSegmentNames } from 'src/constants/corp-segment-constants';
import { TABLE_VIEW_ACTIONS } from 'src/hooks/useGridState';
import { BusinessGroupEntity, SegmentHierarchy } from 'src/models/AppContextModels';
import {
  CorpSegmentFilterSelection,
  ForecastGridRowData,
  ForecastRowDataStructured,
  ForecastTemplateColumns,
  ForecastTemplateCorpSegmentDropdowns,
  ForecastTemplateMasterCorpSegmentDropdownValues,
  OptionDropdown,
  TransformedRowItem
} from 'src/models/ForecastModels';
import { PlanningCycleEntity } from 'src/models/PlanningCycleModel';
import { AccountBudgetTypeMapping } from 'src/models/xPTMappingModels';
import { compareObjects, filterObjectFields } from 'src/utils/comparison-utils';
import { generateMonthList, generateMonthListForExcelImport, getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';
import { generateUniqueId, localeCompareNullableSelectProps } from 'src/utils/generic-utilities';
import { getForecastS3BucketName } from 'src/utils/xpt-s3-bucket-details';
import { v4 as uuidv4 } from 'uuid';
import { customBusinessSegmentSort, customCorpSegmentSort } from './CorpSegmentsUtils';
import { ForecastGridFixedFields } from './ForecastGridConstants';
import { useMemo } from 'react';
import { RING_BUSINESS_GROUP_SHORT_DESC } from 'src/constants/generic-constants';

export const getForecastTemplateBreadcrumbItems = (
  businessGroupBaseBreadcrumbs: BreadcrumbGroupProps.Item[],
  currentBusinessGroupName?: string
): BreadcrumbGroupProps.Item[] => {
  if (!currentBusinessGroupName) {
    return businessGroupBaseBreadcrumbs;
  }

  return [
    ...businessGroupBaseBreadcrumbs,
    {
      text: 'Forecast Input',
      href: `/${currentBusinessGroupName}/forecast-input`
    }
  ];
};

export const getForecastExportFileName = (businessGroupShortDesc: string, scenario_year: string) => {
  const fileName = `${businessGroupShortDesc}_${scenario_year}_Forecast_Template`;
  const sheetName = `${businessGroupShortDesc}_${scenario_year}`;
  return { fileName, sheetName };
};

/**
 * Generates the S3 URI for reading forecast data.
 * @param dataClassificationId The data classification ID.
 * @param dataClassificationShortDesc The short description of the data classification.
 * @param scenarioSeqId The scenario sequence ID.
 * @returns The S3 URI string.
 */
export const forecastDataReadS3URI = (dataClassificationId: number, dataClassificationShortDesc: string, scenarioSeqId: number): string => {
  const forecastS3BucketName = getForecastS3BucketName().bucketName;
  return `s3://${forecastS3BucketName}/${dataClassificationShortDesc}_${dataClassificationId}/${scenarioSeqId}/query/list_forecast_detail.json`;
};

export const forecastDataUpdateFolderKey = (dataClassificationId: number, dataClassificationShortDesc: string, scenarioSeqId: number) => {
  const uniqueId = uuidv4();
  const s3Key = `${dataClassificationShortDesc}_${dataClassificationId}/${scenarioSeqId}/update/${uniqueId}.txt`;
  return s3Key;
};

// Utility function to replace values in the input array with their corresponding displayName from ForecastGridFixedFields
export const replaceWithDisplayNames = (fields: string[]): string[] => {
  return fields.map((field) => {
    // Find the key in ForecastGridFixedFields whose value matches the current field
    const matchingField = Object.values(ForecastGridFixedFields).find((fixedField) => fixedField.value === field);

    // If a match is found, return the displayName, otherwise return the original field
    return matchingField ? matchingField.displayName : field;
  });
};

/**
 * Flattens the forecast row data structured into a simpler format.
 * Ignore Optional Corp Segment Field from the Forecast Template data from S3.
 * @param data The array of forecast row data structured.
 * @returns The flattened array of forecast row data.
 */
export const flattenForecastRowDataStructured = (data: ForecastRowDataStructured[], optionalCorpSegmentsHeader: string[]): ForecastGridRowData[] => {
  try {
    const finalRowDataStructured = data?.map((item) => {
      // Filter and flatten the corp_segments arrays into single objects
      const corpSegmentsFlattened = Object.assign(
        {},
        ...item.corp_segments.map((segment) => {
          const filteredSegment = { ...segment };
          optionalCorpSegmentsHeader.forEach((header) => {
            delete filteredSegment[header];
          });
          return filteredSegment;
        })
      );

      const busSegmentsFlattened = Object.assign({}, ...item.bus_segments);
      const actualMonthsFlattened = Object.assign({}, ...item.actual_months);
      const forecastMonthsFlattened = Object.assign({}, ...item.forecast_months);

      // Merge the flattened objects into the original item, excluding the original arrays
      const { actual_months, bus_segments, corp_segments, forecast_months, ...rest } = item;
      return {
        // FE metadata fields
        [ForecastGridFixedFields.RowId.value]: uuidv4(),
        [ForecastGridFixedFields.IsNewFERow.value]: false,
        [ForecastGridFixedFields.IsTouched.value]: false,
        [ForecastGridFixedFields.IsEdited.value]: false,
        [ForecastGridFixedFields.IsActive.value]: true,
        [ForecastGridFixedFields.IsSegmentEdited.value]: false,

        ...corpSegmentsFlattened,
        ...busSegmentsFlattened,
        ...actualMonthsFlattened,
        ...forecastMonthsFlattened,

        ...rest
      };
    });
    return finalRowDataStructured;
  } catch (error: any) {
    logger.error('Error flattening forecast row data structured:', error);
    throw new Error('Error flattening forecast row data structured');
  }
};

/**
 * Generates values for a corp segment filter based on the latest forecast row data,
 * removes duplicates, and sorts them alphabetically.
 *
 * @param {any[]} latestForecastRowData - The latest forecast row data.
 * @param {object} corpSegment - The corp segment for which values are generated.
 * @returns {OptionDropdown[]} - An array of unique, sorted dropdown options.
 */
export const generateAndProcessCorpSegmentFilterValues = (latestForecastRowData: any[], corpSegment: any): OptionDropdown[] => {
  try {
    const valueMap = new Map<string, OptionDropdown>();

    latestForecastRowData.forEach((row) => {
      const displayNameValue = row[corpSegment.corp_segment_name];
      const descriptionValue = row[corpSegment.corp_segment_name + ' Description'] || '';
      if (!valueMap.has(displayNameValue)) {
        valueMap.set(displayNameValue, { label: displayNameValue, value: displayNameValue, description: descriptionValue });
      }
    });

    // Convert the map values to an array and sort them alphabetically
    const uniqueSortedValues = Array.from(valueMap.values()).sort((a, b) => localeCompareNullableSelectProps(a, b));

    return uniqueSortedValues;
  } catch (error: any) {
    logger.error('Error generating, removing duplicates, and sorting values for corp segment filter:', error);
    return [];
  }
};

export const validateCorpSegmentSelections = (
  currentSelections: CorpSegmentFilterSelection,
  corpSegmentFiltersCompleteList: ForecastTemplateCorpSegmentDropdowns[]
): CorpSegmentFilterSelection => {
  const validatedSelections: CorpSegmentFilterSelection = {};
  corpSegmentFiltersCompleteList.forEach((filter) => {
    const selectedOptions = currentSelections[filter.displayName];
    if (selectedOptions) {
      const validOptions = selectedOptions.filter((option) => filter.fieldDropdownOptions.some((validOption) => validOption.label === option.label));
      validatedSelections[filter.displayName] = validOptions;
    }
  });
  return validatedSelections;
};

export const initializeAllCorpSegmentFilters = (corpSegmentFiltersDropdowns: ForecastTemplateCorpSegmentDropdowns[]): CorpSegmentFilterSelection => {
  const initializedFilters: CorpSegmentFilterSelection = {};

  corpSegmentFiltersDropdowns
    .filter((corpSegmentFiltersDropdown) => corpSegmentFiltersDropdown.isRequired)
    .forEach((dropdown) => {
      if (dropdown.isMultiSelect) {
        // Select all options for multi-select dropdowns
        initializedFilters[dropdown.displayName] = dropdown.fieldDropdownOptions;
      } else {
        // Select the first option for non-multi-select dropdowns
        initializedFilters[dropdown.displayName] = dropdown.fieldDropdownOptions.length > 0 ? [dropdown.fieldDropdownOptions[0]] : [];
      }
    });

  return {
    ...initializedFilters
  };
};

/**
 * Updates the selectedCorpSegmentFilters with newly added details.
 *
 * @param newRowsAdded - An array of newly added rows.
 * @param selectedCorpSegmentFilters - The current selections for corp segments.
 * @param masterCorpSegmentDropdowns - The available dropdown values for corp segments.
 * @returns The updated CorpSegmentFilterSelection.
 */
export const getNewlyAddedSelections = (
  newRowsAdded: any[],
  selectedCorpSegmentFilters: CorpSegmentFilterSelection,
  masterCorpSegmentDropdowns: ForecastTemplateMasterCorpSegmentDropdownValues[]
): CorpSegmentFilterSelection => {
  const corpSegmentKeys = selectedCorpSegmentFilters ? Object.keys(selectedCorpSegmentFilters) : [];
  const updatedSelections: CorpSegmentFilterSelection = { ...selectedCorpSegmentFilters };

  // Iterate over each new row added
  newRowsAdded.forEach((row) => {
    // Iterate over each corp segment key
    corpSegmentKeys.forEach((key) => {
      const masterDropdown = masterCorpSegmentDropdowns.find((dropdown) => dropdown.masterCorpSegmentDisplayName === key);

      if (masterDropdown) {
        const rowValue = row[key];
        if (rowValue !== null && rowValue !== undefined) {
          const option = masterDropdown.masterCorpSegmentDropdownValues.find((opt) => opt.value === rowValue);

          if (option) {
            const existingSelections = updatedSelections[key] || [];
            const alreadySelected = existingSelections.some((sel) => sel.label === option.label);

            // Add the option if it's not already selected
            if (!alreadySelected) {
              updatedSelections[key] = [...existingSelections, option];
            }
          }
        }
      }
    });
  });

  return updatedSelections;
};

/**
 * Converts a CorpSegmentFilterSelection object into a map where each key holds an array of label strings from the Options.
 *
 * @param inputData - The input data of type CorpSegmentFilterSelection.
 * @returns A map where each key is associated with an array of label strings.
 */
export const convertToLabelArrays = (inputData: CorpSegmentFilterSelection): Record<string, string[]> => {
  const result: Record<string, string[]> = {};
  // Iterate over each property in the input data object
  for (const key in inputData) {
    if (inputData.hasOwnProperty(key)) {
      // Map each Options array to an array of label strings
      result[key] = inputData[key].map((option: any) => option?.label);
    }
  }
  return result;
};

/**
 * Filters rowData based on all specified criteria (AND filtering).
 * A row must meet all non-empty filter conditions to be included.
 *
 * @param {ForecastGridRowData[]} rowData - The array of row data to be filtered.
 * @param {Record<string, string[]>} filtersToApplyRowData - The filters to apply.
 * @returns {ForecastGridRowData[]} - The filtered rowData array where all specified filters match.
 */
export const filterRowDataWithAllMatches = (
  rowData: ForecastGridRowData[],
  filtersToApplyRowData: Record<string, string[]>
): ForecastGridRowData[] => {
  // Check if any filter array is empty
  const hasEmptyFilter = Object.values(filtersToApplyRowData).some((values) => values.length === 0);
  if (hasEmptyFilter) {
    return [];
  }

  return rowData.filter((row) => {
    // Iterate over each filter key in filtersToApplyRowData
    return Object.entries(filtersToApplyRowData).every(([key, values]) => {
      // Check if the row should be included based on the filter
      return values.includes(row[key]);
    });
  });
};

/**
 * Filters rowData based on any of the specified criteria (OR filtering).
 * A row is included if it matches any of the non-empty filter conditions.
 *
 * @param {ForecastGridRowData[]} rowData - The array of row data to be filtered.
 * @param {Record<string, string[]>} filtersToApplyRowData - The filters to apply.
 * @returns {ForecastGridRowData[]} - The filtered rowData array where any specified filter matches.
 */
export const filterRowDataWithAnyMatch = (rowData: ForecastGridRowData[], filtersToApplyRowData: Record<string, string[]>): ForecastGridRowData[] => {
  // First, create an array of active filters (those with non-empty values)
  const activeFilters = Object.entries(filtersToApplyRowData).filter(([_, values]) => values.length > 0);

  // Return only those rows that match any of the active filters
  return rowData.filter((row) => {
    return activeFilters.some(([key, values]) => {
      return values.includes(row[key]);
    });
  });
};

export const forecastGridFileActions = (disableActions: boolean, hasChanges: boolean): ButtonDropdownProps.ItemOrGroup[] => {
  const defaultActions: ButtonDropdownProps.ItemOrGroup[] = [
    {
      id: 'discard_all_changes',
      text: 'Discard all changes',
      description: 'refresh ',
      disabled: !hasChanges,
      disabledReason: 'No changes to discard'
    },
    {
      id: 'ag_grid_export_to_excel',
      text: 'Export to Excel'
    }
  ];

  const actions: ButtonDropdownProps.ItemOrGroup[] = [
    ...defaultActions,
    {
      id: 'import_from_excel',
      text: 'Upload from Excel',
      disabled: disableActions,
      disabledReason: 'Planning Cycle is locked'
    },
    ...TABLE_VIEW_ACTIONS
  ] as ButtonDropdownProps.ItemOrGroup[];
  return actions;
};

/**
 * Generates the file import headers based on the business group and selected planning cycle.
 *
 * @param {BusinessGroupEntity} businessGroup - The business group object containing segments.
 * @param {PlanningCycleEntity} selectedPlanningCycle - The selected planning cycle object containing date ranges.
 * @returns {string[]} - An array of header strings for file import.
 */
export const getForecastTemplateHeaderInfo = (
  businessGroup: BusinessGroupEntity,
  selectedPlanningCycle: PlanningCycleEntity
): ForecastTemplateColumns => {
  const lineItemSeqIdDisplayName = ForecastGridFixedFields.XptLineItemSeqId.displayName;
  const lineItemSeqIdValue = ForecastGridFixedFields.XptLineItemSeqId.value;

  const lineItemIdDisplayName = ForecastGridFixedFields.XptLineItemId.displayName;
  const lineItemIdValue = ForecastGridFixedFields.XptLineItemId.value;

  const budgetOwnerDisplayName = ForecastGridFixedFields.BudgetOwner.displayName;
  const budgetOwnerValue = ForecastGridFixedFields.BudgetOwner.value;

  const budgetTypeDisplayName = ForecastGridFixedFields.BudgetType.displayName;
  const budgetTypeValue = ForecastGridFixedFields.BudgetType.value;

  const corpSegmentHeaders = [...businessGroup.corp_segments]
    .sort(customCorpSegmentSort)
    .flatMap((segment) => [`${segment.corp_segment_name}`, `${segment.corp_segment_name} Description`]);

  const corpSegmentMandatoryFields = [...businessGroup.corp_segments]
    .sort(customCorpSegmentSort)
    .filter((corpSegment) => corpSegment.corp_segment_required)
    .flatMap((segment) => [`${segment.corp_segment_name}`, `${segment.corp_segment_name} Description`]);

  const corpSegmentMandatoryFieldsWithoutDescription = [...businessGroup.corp_segments]
    .sort(customCorpSegmentSort)
    .filter((corpSegment) => corpSegment.corp_segment_required)
    .map((segment) => segment.corp_segment_name);

  const corpSegmentOptionalFields = [...businessGroup.corp_segments]
    .sort(customCorpSegmentSort)
    .filter((corpSegment) => !corpSegment.corp_segment_required)
    .flatMap((segment) => [`${segment.corp_segment_name}`, `${segment.corp_segment_name} Description`]);

  const businessSegmentHeaders = [...businessGroup.business_segments]
    .sort(customBusinessSegmentSort)
    .map((businessSegment) => businessSegment.business_segment_name);

  const businessSegmentMandatoryFields = [...businessGroup.business_segments]
    .sort(customBusinessSegmentSort)
    .filter((businessSegment) => businessSegment.is_id_column)
    .map((businessSegment) => businessSegment.business_segment_name);

  const businessSegmentDropdownFields = [...businessGroup.business_segments]
    .sort(customBusinessSegmentSort)
    .filter((businessSegment) => businessSegment.business_segment_data_type === BusinessSegmentDataType.DROPDOWN)
    .map((businessSegment) => businessSegment.business_segment_name);

  const actualMonthIds = generateMonthList(selectedPlanningCycle.actuals_start_month_id, selectedPlanningCycle.actuals_end_month_id);
  const forecastMonthIds = generateMonthList(selectedPlanningCycle.forecast_start_month_id, selectedPlanningCycle.forecast_end_month_id);

  const actualMonthsDisplayFormat = generateMonthListForExcelImport(
    selectedPlanningCycle.actuals_start_month_id,
    selectedPlanningCycle.actuals_end_month_id
  );
  const forecastMonthsDisplayFormat = generateMonthListForExcelImport(
    selectedPlanningCycle.forecast_start_month_id,
    selectedPlanningCycle.forecast_end_month_id
  );

  const forecastTemplateColumns: ForecastTemplateColumns = {
    metadataColumns: [
      ForecastGridFixedFields.IsNew.value,
      ForecastGridFixedFields.IsSegmentEdited.value,
      ForecastGridFixedFields.IsNewFERow.value,
      ForecastGridFixedFields.IsEdited.value,
      ForecastGridFixedFields.RowId.value,
      ForecastGridFixedFields.IsTouched.value,
      ForecastGridFixedFields.XptLineItemSeqId.value,
      ForecastGridFixedFields.ScenarioSeqId.value,
      ForecastGridFixedFields.UpdatedAt.value,
      ForecastGridFixedFields.UpdatedBy.value
    ],
    forecastTemplateImportFileHeader: [
      lineItemIdDisplayName,
      budgetOwnerDisplayName,
      budgetTypeDisplayName,
      ...corpSegmentMandatoryFields,
      ...businessSegmentHeaders,
      ...actualMonthsDisplayFormat,
      ...forecastMonthsDisplayFormat
    ],
    modifiedCheckFields: [lineItemIdValue, budgetOwnerValue, ...corpSegmentMandatoryFields, ...businessSegmentHeaders, ...forecastMonthIds],
    corpAndBussSegmentsMandatoryFields: [...corpSegmentMandatoryFieldsWithoutDescription, ...businessSegmentMandatoryFields],
    mandatoryFields: [budgetOwnerValue, ...corpSegmentMandatoryFieldsWithoutDescription, ...businessSegmentMandatoryFields],
    corpSegmentColumns: corpSegmentHeaders,
    corpSegmentMandatoryFields: corpSegmentMandatoryFields,
    corpSegmentOptionalFields: corpSegmentOptionalFields,
    businessSegmentColumns: businessSegmentHeaders,
    businessSegmentMandatoryFields: businessSegmentMandatoryFields,
    businessSegmentDropdownFields: businessSegmentDropdownFields,
    actualMonthColumnIds: actualMonthIds,
    actualMonthsDisplayFormat: actualMonthsDisplayFormat,
    forecastMonthColumnsIds: forecastMonthIds,
    forecastMonthsDisplayFormat: forecastMonthsDisplayFormat,
    addNewRowColumns: [
      lineItemSeqIdValue,
      lineItemIdValue,
      ForecastGridFixedFields.RowId.value,
      ForecastGridFixedFields.IsNewFERow.value,
      ForecastGridFixedFields.IsTouched.value,
      ForecastGridFixedFields.ScenarioSeqId.value,
      ForecastGridFixedFields.BudgetOwner.value,
      ...corpSegmentMandatoryFields,
      ...businessSegmentHeaders,
      ...actualMonthIds,
      ...forecastMonthIds,
      ForecastGridFixedFields.UpdatedAt.value,
      ForecastGridFixedFields.UpdatedBy.value
    ]
  };

  return forecastTemplateColumns;
};

// Function to prepare forecast template data for upload
export const prepareForecastTemplateUploadData = (
  forecastTemplateDataForSubmission: any[],
  planningCycle: PlanningCycleEntity,
  businessGroup: BusinessGroupEntity
): any[] => {
  console.time('PrepareForecastTemplateUploadData');
  const corpSegmentOptionalFieldDefaultValues = businessGroup.corp_segments
    .filter((corpSegment) => !corpSegment.corp_segment_required)
    .map((corpSegment) => {
      return { [corpSegment.corp_segment_name]: corpSegment.corp_segment_default_value.segment_hierarchy[0] };
    });

  const defaultValues = corpSegmentOptionalFieldDefaultValues.reduce((acc, curr) => {
    const key = Object.keys(curr)[0];
    acc[key] = curr[key];
    return acc;
  }, {} as { [key: string]: string });

  const updatedRelevantData = forecastTemplateDataForSubmission.map((item) => {
    const newItem = { ...item };
    Object.keys(defaultValues).forEach((field) => {
      newItem[`${field}`] = defaultValues[field];
    });
    return newItem;
  });

  // Generate sets of months and segment fields
  const forecastMonthsSet: Set<string> = new Set(generateMonthList(planningCycle.forecast_start_month_id, planningCycle.forecast_end_month_id));
  const actualMonthsSet: Set<string> = new Set(generateMonthList(planningCycle.actuals_start_month_id, planningCycle.actuals_end_month_id));
  const corpSegmentFieldsSet = new Set<string>();
  const corpSegmentDescriptionsToExcludeSet = new Set<string>();
  const busSegmentFieldsSet = new Set<string>();

  // Populate segment fields sets
  businessGroup.corp_segments.forEach((corpSegment) => {
    corpSegmentFieldsSet.add(corpSegment.corp_segment_name);
    corpSegmentDescriptionsToExcludeSet.add(corpSegment.corp_segment_name + ' Description');
  });

  businessGroup.business_segments.forEach((busSegment) => busSegmentFieldsSet.add(busSegment.business_segment_name));

  // Transform data
  const transformedData = updatedRelevantData.map((originalRowItem) => {
    return Object.keys(originalRowItem).reduce(
      (acc, key) => {
        const categories = segregateRowData(
          key,
          originalRowItem,
          forecastMonthsSet,
          corpSegmentFieldsSet,
          busSegmentFieldsSet,
          actualMonthsSet,
          corpSegmentDescriptionsToExcludeSet
        );

        // Ensure arrays are concatenated to maintain structure
        acc.corp_segments = acc.corp_segments.concat(categories.corp_segments);
        acc.bus_segments = acc.bus_segments.concat(categories.bus_segments);
        acc.forecast_months = acc.forecast_months.concat(categories.forecast_months);
        acc = { ...categories.other, ...acc };

        return acc;
      },
      { corp_segments: [], bus_segments: [], forecast_months: [] } as TransformedRowItem
    );
  });

  // Add missing months in forecast_months from forecastMonthsSet (Planning Cycle) with null
  const completedData = transformedData.map((row) => {
    const completeForecastMonths = Array.from(forecastMonthsSet).map((month: string) => {
      const existingMonth = row.forecast_months.find((item: { [key: string]: any }) => month in item);
      return existingMonth || { [month]: null };
    });

    return {
      ...row,
      forecast_months: completeForecastMonths
    };
  });

  console.timeEnd('PrepareForecastTemplateUploadData');
  return completedData;
};

// Function to segregate row data into different categories
export const segregateRowData = (
  key: string,
  originalRowItem: any,
  forecastMonthsSet: Set<string>,
  corpSegmentFieldsSet: Set<string>,
  busSegmentFieldsSet: Set<string>,
  actualMonthsSet: Set<string>,
  corpSegmentDescriptionsToExcludeSet: Set<string>
) => {
  const categories: any = {
    other: {},
    corp_segments: [],
    bus_segments: [],
    forecast_months: []
  };

  if (forecastMonthsSet.has(key)) {
    categories.forecast_months.push({ [key]: originalRowItem[key] });
  } else if (corpSegmentFieldsSet.has(key)) {
    categories.corp_segments.push({ [key]: originalRowItem[key] });
  } else if (busSegmentFieldsSet.has(key)) {
    categories.bus_segments.push({ [key]: originalRowItem[key] });
  } else if (corpSegmentDescriptionsToExcludeSet.has(key)) {
    // Ignore these values
  } else if (!actualMonthsSet.has(key)) {
    categories.other[key] = originalRowItem[key];
  }

  return categories;
};

/**
 * Filters out the non-relevant fields for comparison from the row data.
 * @param {ForecastGridRowData} row - The row data to filter.
 * @returns {Partial<ForecastGridRowData>} The filtered row data.
 */
const filterForecastFields = (row: ForecastGridRowData): Partial<ForecastGridRowData> => {
  return filterObjectFields(row, ['row_id', 'is_new_fe_row', 'is_new', 'is_segment_edited', 'is_touched', 'is_edited', 'updated_at', 'updated_by']);
};

/**
 * Compares the original row data with the new row data to determine if they are different.
 * @param {ForecastGridRowData} originalRow - The original row data.
 * @param {ForecastGridRowData} newRow - The new row data.
 * @returns {boolean} True if the rows are different, false otherwise.
 */
export const isForecastGridRowModified = (originalRow: ForecastGridRowData, newRow: ForecastGridRowData): boolean => {
  const filteredOriginalRow = filterForecastFields(originalRow);
  const filteredNewData = filterForecastFields(newRow);
  return !compareObjects(filteredOriginalRow, filteredNewData);
};

export const isSegmentModified = (originalRow: ForecastGridRowData, newRow: ForecastGridRowData, idFields: string[]): boolean => {
  // Function to filter row data based on idFields
  const filterByIdFields = (row: ForecastGridRowData): Partial<ForecastGridRowData> => {
    return Object.fromEntries(Object.entries(row).filter(([key]) => idFields.includes(key))) as Partial<ForecastGridRowData>;
  };

  // Filter both rows based on the provided idFields
  const filteredOriginalRow = filterByIdFields(originalRow);
  const filteredNewRow = filterByIdFields(newRow);

  // Use compareObjects to check if the filtered rows are different
  return !compareObjects(filteredOriginalRow, filteredNewRow);
};

/**
 * Updates the budget type if the account or account description has changed.
 * @param cellValueChangedEvent - The event triggered by cell value change.
 * @param isAccountDescriptionChanged - Indicates if the account description was changed.
 * @param masterCorpSegmentDropdowns - Array of corporate segment dropdown values.
 * @param accountBudgetTypeMapping - Array of account budget type mappings.
 * @returns The budget type value or null if not found.
 */
export const updateBudgetTypeIfAccountChanged = (
  cellValueChangedEvent: CellValueChangedEvent,
  isAccountDescriptionChanged: boolean,
  masterCorpSegmentDropdowns: ForecastTemplateMasterCorpSegmentDropdownValues[],
  accountBudgetTypeMapping: AccountBudgetTypeMapping[]
): string | null => {
  const { newValue } = cellValueChangedEvent;
  let accountCode: string | null = null;

  if (isAccountDescriptionChanged) {
    // Get account code from description
    accountCode =
      masterCorpSegmentDropdowns
        .find((filter) => filter.masterCorpSegmentDisplayName === CorpSegmentNames.ACCOUNT)
        ?.masterCorpSegmentDropdownValues?.find((option) => option.description === newValue)?.label || null;
  } else {
    // Take account code directly from the event
    accountCode = newValue;
  }

  // Validate account code
  if (!accountCode) {
    logger.warn(`No account code found for the new value: ${newValue}`);
    return null;
  }

  const budgetType = accountBudgetTypeMapping.find((mapping) => mapping.account_code === accountCode)?.budget_type || null;

  if (budgetType === null) {
    logger.warn(`Unable to find Budget Type for Account Code ${accountCode}`);
    return null;
  }

  return budgetType;
};

/**
 * Gets unique budget types from the account budget type mapping.
 * @param accountBudgetTypeMapping - Array of account budget type mappings.
 * @returns An array of unique budget types, sorted alphabetically.
 */
export const getUniqueBudgetTypes = (accountBudgetTypeMapping: AccountBudgetTypeMapping[]): string[] => {
  const uniqueBudgetTypes = new Set<string>();

  accountBudgetTypeMapping.forEach((mapping) => {
    if (mapping.budget_type) {
      uniqueBudgetTypes.add(mapping.budget_type);
    }
  });

  return Array.from(uniqueBudgetTypes).sort((a, b) => a.localeCompare(b));
};

/**
 * Gets unique account codes from the account budget type mapping.
 * @param accountBudgetTypeMapping - Array of account budget type mappings.
 * @returns An array of unique account codes, sorted alphabetically.
 */
export const getUniqueAccountCodes = (accountBudgetTypeMapping: AccountBudgetTypeMapping[]): string[] => {
  const uniqueAccountCodes = new Set<string>();

  accountBudgetTypeMapping.forEach((mapping) => {
    if (mapping.account_code) {
      uniqueAccountCodes.add(mapping.account_code);
    }
  });

  return Array.from(uniqueAccountCodes).sort((a, b) => a.localeCompare(b));
};

/**
 * Updates the account code and description if the budget type has changed.
 * @param cellValueChangedEvent - The event triggered by cell value change.
 * @param masterCorpSegmentDropdowns - Array of corporate segment dropdown values.
 * @param accountBudgetTypeMapping - Array of account budget type mappings.
 * @param currentAccountCode - The current account code.
 * @param currentAccountDescription - The current account description.
 * @returns An object containing the updated account code and description, or nulls if not found.
 */
export const updateAccountAndDescriptionIfBudgetTypeChanged = (
  cellValueChangedEvent: CellValueChangedEvent,
  masterCorpSegmentDropdowns: ForecastTemplateMasterCorpSegmentDropdownValues[],
  accountBudgetTypeMapping: AccountBudgetTypeMapping[],
  currentAccountCode: string | null,
  currentAccountDescription: string | null
): { accountCode: string | null; accountDescription: string | null } => {
  const { newValue } = cellValueChangedEvent;

  const filteredAccountBudgetMappings: AccountBudgetTypeMapping[] = accountBudgetTypeMapping.filter((account) => account.budget_type === newValue);

  if (filteredAccountBudgetMappings.length > 0) {
    const uniqueAccountCodes = getUniqueAccountCodes(filteredAccountBudgetMappings);

    // Return current values if the account code is still valid
    if (uniqueAccountCodes.includes(currentAccountCode || '')) {
      return { accountCode: currentAccountCode, accountDescription: currentAccountDescription };
    }

    const accountCode = filteredAccountBudgetMappings[0].account_code;
    const accountDescription =
      masterCorpSegmentDropdowns
        .find((filter) => filter.masterCorpSegmentDisplayName === CorpSegmentNames.ACCOUNT)
        ?.masterCorpSegmentDropdownValues?.find((option) => option.label === accountCode)?.description || null;

    return { accountCode, accountDescription };
  } else {
    logger.warn(`Unable to find Account Code for Budget Type ${newValue}`);
    return { accountCode: null, accountDescription: null };
  }
};

export const cloneForecastInputRow = (
  row: ForecastGridRowData,
  forecastTemplateColumns: ForecastTemplateColumns,
  includeForecastMonths: boolean,
  userAlias: string
): ForecastGridRowData => {
  const { actualMonthColumnIds, forecastMonthColumnsIds } = forecastTemplateColumns;

  const clonedRow = { ...row };

  const setMonthsToNull = (monthIds: string[]) => {
    monthIds.forEach((monthId) => {
      if (monthId in clonedRow) {
        clonedRow[monthId] = null;
      }
    });
  };

  // Always set actual months to null
  setMonthsToNull(actualMonthColumnIds);

  // Set forecast months to null if not included
  if (!includeForecastMonths) {
    setMonthsToNull(forecastMonthColumnsIds);
  }

  const newRowMetadata = {
    [ForecastGridFixedFields.RowId.value]: generateUniqueId(),
    [ForecastGridFixedFields.IsNewFERow.value]: true,
    [ForecastGridFixedFields.IsTouched.value]: true,
    [ForecastGridFixedFields.IsEdited.value]: true,
    [ForecastGridFixedFields.IsActive.value]: true,
    [ForecastGridFixedFields.IsSegmentEdited.value]: true,

    [ForecastGridFixedFields.XptLineItemId.value]: null,
    [ForecastGridFixedFields.XptLineItemSeqId.value]: null,
    forecast_input_id: null,

    [ForecastGridFixedFields.IsNew.value]: true,

    [ForecastGridFixedFields.UpdatedAt.value]: getCurrentUTCTimeInISO(),
    [ForecastGridFixedFields.UpdatedBy.value]: userAlias
  };

  return { ...clonedRow, ...newRowMetadata };
};

export const customLineItemIdComparator = (valueA: string, valueB: string, nodeA: any, nodeB: any): number => {
  // Convert to numbers for comparison
  const numA = valueA ? parseFloat(valueA) : -Infinity;
  const numB = valueB ? parseFloat(valueB) : -Infinity;

  // Handle 'Total' row if present
  if (nodeA.footer) return 1;
  if (nodeB.footer) return -1;

  // Compare as numbers
  if (numA === numB) return 0;
  return numA > numB ? 1 : -1;
};

/**
 * Finds the 'Account' dropdown for the RNG business group.
 *
 * @param dropdowns - An array of ForecastTemplateMasterCorpSegmentDropdownValues objects.
 * @param businessGroupShortDesc - The short description of the business group.
 * @returns The 'Account' dropdown for RNG if found, otherwise undefined.
 */
const findRingAccountDropdown = (dropdowns: ForecastTemplateMasterCorpSegmentDropdownValues[], businessGroupShortDesc: string) =>
  dropdowns.find(
    (dropdown) => dropdown.masterCorpSegmentDisplayName === CorpSegmentNames.ACCOUNT && businessGroupShortDesc === RING_BUSINESS_GROUP_SHORT_DESC
  );

/**
 * Maps additional accounts to the format required for dropdown values.
 *
 * @param accounts - An array of SegmentHierarchy objects representing additional accounts.
 * @returns An array of objects with label, value, and description properties.
 */
const mapAdditionalAccounts = (accounts: SegmentHierarchy[]) =>
  accounts.map(({ segment_hierarchy, segment_description }) => ({
    label: segment_hierarchy[segment_hierarchy.length - 1],
    value: segment_hierarchy[segment_hierarchy.length - 1],
    description: segment_description
  }));

/**
 * Enhances the dropdowns by adding additional accounts to the 'Account' dropdown.
 *
 * @param dropdowns - An array of ForecastTemplateMasterCorpSegmentDropdownValues objects.
 * @param additionalAccounts - An array of SegmentHierarchy objects representing additional accounts.
 * @returns A new array of ForecastTemplateMasterCorpSegmentDropdownValues with enhanced 'Account' dropdown.
 */
const enhanceDropdowns = (dropdowns: ForecastTemplateMasterCorpSegmentDropdownValues[], additionalAccounts: SegmentHierarchy[]) =>
  dropdowns.map((dropdown) =>
    dropdown.masterCorpSegmentDisplayName === CorpSegmentNames.ACCOUNT
      ? {
          ...dropdown,
          masterCorpSegmentDropdownValues: [...dropdown.masterCorpSegmentDropdownValues, ...mapAdditionalAccounts(additionalAccounts)]
        }
      : dropdown
  );

/**
 * A custom hook that enhances master corp segment dropdowns with additional accounts for RNG business group.
 *
 * This hook checks if the business group is RNG and if an Account dropdown exists. If both conditions are met,
 * it enhances the Account dropdown with additional accounts. Otherwise, it returns the original dropdowns.
 *
 * @param {ForecastTemplateMasterCorpSegmentDropdownValues[]} masterCorpSegmentDropdowns - An array of dropdown values for master corp segments.
 * @param {string} businessGroupShortDesc - The short description of the business group (e.g., 'RNG').
 * @param {SegmentHierarchy[]} additionalAccounts - An array of additional account hierarchies to be added to the Account dropdown.
 * @param {function} [useMemoCb=useMemo] - A memoization callback, defaults to React's useMemo.
 *                                         Can be overridden for testing purposes.
 *
 * @returns {ForecastTemplateMasterCorpSegmentDropdownValues[]} An array of dropdown values, potentially enhanced with additional accounts.
 *
 * @example
 * const enhancedDropdowns = useEnhancedMasterCorpSegmentDropdowns(
 *   originalDropdowns,
 *   'RNG',
 *   additionalAccountsData
 * );
 *
 * @note This hook uses memoization to optimize performance by avoiding unnecessary recalculations.
 *       The memoized value will only be recalculated if any of the dependencies change.
 */
export const useEnhancedMasterCorpSegmentDropdowns = (
  masterCorpSegmentDropdowns: ForecastTemplateMasterCorpSegmentDropdownValues[],
  businessGroupShortDesc: string,
  additionalAccounts: SegmentHierarchy[],
  useMemoCb = useMemo
) => {
  const enhanceDropdownsLogic = () => {
    const accountDropdown = findRingAccountDropdown(masterCorpSegmentDropdowns, businessGroupShortDesc);

    if (!accountDropdown || additionalAccounts.length === 0) {
      return masterCorpSegmentDropdowns;
    }

    const existingAccountCodes = new Set(accountDropdown.masterCorpSegmentDropdownValues.map((value) => value.label));

    const newAccountValues = mapAdditionalAccounts(additionalAccounts).filter((account) => !existingAccountCodes.has(account.label));

    if (newAccountValues.length === 0) {
      return masterCorpSegmentDropdowns;
    }

    return enhanceDropdowns(
      masterCorpSegmentDropdowns,
      additionalAccounts.filter((account) => !existingAccountCodes.has(account.segment_hierarchy[account.segment_hierarchy.length - 1]))
    );
  };

  return useMemoCb(enhanceDropdownsLogic, [masterCorpSegmentDropdowns, businessGroupShortDesc, additionalAccounts]);
};
