import { logger } from 'src/analytics/KatalLogger';

export const CONTROL_CHARACTERS_PATTERN = /[\x00-\x1F\x7F-\x9F"]|\\[rnt]/g;

interface Row {
  [key: string]: any;
}

const processValue = (value: any): any => {
  // Handle arrays
  if (Array.isArray(value)) {
    return value.map((item) => processValue(item));
  }

  // Handle Date objects
  if (value instanceof Date) {
    return value;
  }

  // Handle objects
  if (typeof value === 'object' && value !== null) {
    const processed: { [key: string]: any } = {};
    Object.keys(value).forEach((key) => {
      processed[key] = processValue(value[key]);
    });
    return processed;
  }

  // Handle strings
  if (typeof value === 'string') {
    let processedString = value;
    processedString = processedString.replace(CONTROL_CHARACTERS_PATTERN, ' ');
    processedString = processedString.replace(/\s+/g, ' ').trim();
    return processedString;
  }

  // Return other types as is
  return value;
};

export const replaceSpecialCharactersWithSpace = async (transformedModifiedRows: any[]): Promise<Row[]> => {
  try {
    // Validate and process input array
    const transformedRows = await Promise.all(
      transformedModifiedRows.map(async (row, index) => {
        if (typeof row !== 'object' || row === null) {
          logger.error(`Invalid row at index ${index}`);
          throw new Error(`Invalid row at index ${index}`);
        }

        return processValue(row);
      })
    );

    return transformedRows;
  } catch (error: any) {
    logger.error('Error in replaceSpecialCharactersWithSpace function:', error.message || error);
    throw error;
  }
};
