import { useCallback } from 'react';
import { ExcelExportParams, ProcessCellForExportParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { BusinessGroupEntity } from 'src/models/AppContextModels';
import { PlanningCycleEntity } from 'src/models/PlanningCycleModel';
import { getForecastExportFileName, getForecastTemplateHeaderInfo } from '../forecast-utils/ForecastTemplateUtils';
import { CellDataType } from 'src/utils/ag-grid-utils';
import { ForecastGridFixedFields } from '../forecast-utils/ForecastGridConstants';
import { isEmptyValue } from 'src/utils/generic-utilities';
import { logger } from 'src/analytics/KatalLogger';

export interface ExportHookProps {
  gridRef: React.RefObject<AgGridReact>;
  businessGroup: BusinessGroupEntity | null;
  currentPlanningCycle: PlanningCycleEntity | null;
  businessGroupShortDesc: string;
  userAlias: string;
}

export const useAgGridExcelExport = ({ gridRef, businessGroup, currentPlanningCycle, businessGroupShortDesc, userAlias }: ExportHookProps) => {
  const filterAggregatedColumns = (columns: string[]): string[] => {
    try {
      return columns.filter((column) => {
        const lowerColumn = column.toLowerCase();
        const excludedPatterns = ['total lifetime spend', ' total', 'q1-', 'q2-', 'q3-', 'q4-'];
        return !excludedPatterns.some((pattern) => lowerColumn.includes(pattern) || lowerColumn.startsWith(pattern));
      });
    } catch (error: any) {
      logger.error('Error filtering aggregated columns:', error);
      return [];
    }
  };

  const filterMetadataColumns = (allColumns: string[], metadataColumns: string[]): string[] => {
    try {
      return [...new Set(allColumns.filter((column) => !metadataColumns.includes(column) && !column.endsWith('_1')))];
    } catch (error: any) {
      logger.error('Error filtering metadata columns:', error);
      return [];
    }
  };

  const processCellForExport = ({ value, column }: ProcessCellForExportParams) => {
    if (isEmptyValue(value)) return '';
    return column.getColDef().cellDataType === CellDataType.TEXT ? `'${value}` : value;
  };

  const shouldSkipRow = ({ node }: { node: any }) => {
    const xptLineItemId = node.data?.[ForecastGridFixedFields.XptLineItemId.value];
    return node.footer || xptLineItemId == null;
  };

  const exportToExcel = useCallback(() => {
    if (!businessGroup || !currentPlanningCycle || !gridRef.current?.api) {
      return;
    }

    const allColumns = gridRef.current.api.getColumns()?.map((column) => column.getColId()) || [];
    const metadataColumns = getForecastTemplateHeaderInfo(businessGroup, currentPlanningCycle).metadataColumns;
    const filteredColumns = filterMetadataColumns(allColumns, metadataColumns);

    logger.debug(`All Columns: ${JSON.stringify(allColumns)}`);
    logger.debug(`filtered Columns: ${JSON.stringify(filteredColumns)}`);

    const { fileName, sheetName } = getForecastExportFileName(businessGroupShortDesc, currentPlanningCycle.scenario_year || '');

    const exportParams: ExcelExportParams = {
      fileName,
      sheetName,
      author: userAlias,
      allColumns: true,
      skipColumnGroupHeaders: true,
      skipRowGroups: true,
      columnKeys: filteredColumns,
      rowHeight: 20,
      freezeRows: 'headers',
      freezeColumns: 'pinned',
      processCellCallback: processCellForExport,
      shouldRowBeSkipped: shouldSkipRow
    };

    gridRef.current.api.exportDataAsExcel(exportParams);
  }, [gridRef, businessGroup, currentPlanningCycle, businessGroupShortDesc, userAlias]);

  return {
    filterAggregatedColumns,
    filterMetadataColumns,
    exportToExcel
  };
};
