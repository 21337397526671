import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { Status } from 'src/models/AuthContextModels';
import { RootState } from 'src/store/store';
import { useAuth } from '../../app/auth/AuthContextProvider';
import { selectIsFinanceAdmin, selectIsFinanceAdminForOperations } from '../business-group/access-authorization/redux/userAuthorizationSelectors';

interface AuthWrapperProps {
  children?: React.ReactNode;
}

interface BaseWrapperProps {
  hasAccess: () => boolean;
}

const DEFAULT_REDIRECT_PATH = '/' as const;

/**
 * Hook to check if access data is still loading
 */
const useAccessLoadingStatus = () => {
  const { currentUserAccessLoadingStatus } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore);

  return currentUserAccessLoadingStatus === Status.NotInitiated || currentUserAccessLoadingStatus === Status.Loading;
};

/**
 * Base wrapper component for access control
 */
const BaseWrapper: React.FC<BaseWrapperProps> = ({ hasAccess }) => {
  const isLoading = useAccessLoadingStatus();

  if (isLoading) return <LoadingSpinner />;
  return hasAccess() ? <Outlet /> : <Navigate to={DEFAULT_REDIRECT_PATH} replace />;
};

/**
 * Wrapper component for Finance Admin access control
 */
export const XptFinanceAdminWrapper: React.FC<AuthWrapperProps> = () => {
  const isFinanceAdmin = useSelector(selectIsFinanceAdmin);
  return <BaseWrapper hasAccess={() => isFinanceAdmin} />;
};

/**
 * Wrapper component for Developer access control
 */
export const XPTDevOnlyWrapper: React.FC<AuthWrapperProps> = () => {
  const { isDev } = useAuth();
  return <BaseWrapper hasAccess={() => isDev} />;
};

/**
 * Wrapper component for Operations Admin access control
 */
export const XPTOperationsAdminWrapper: React.FC<AuthWrapperProps> = () => {
  const isOperationsAdmin = useSelector(selectIsFinanceAdminForOperations);
  return <BaseWrapper hasAccess={() => isOperationsAdmin} />;
};
