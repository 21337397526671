import { Box, Button, Modal, Table } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TableEmptyState } from 'src/components/common/TableCommons';
import { PlanningCycleEntity, PlanningCycleFlatTable, PlanningCycleGroupedByScenarioYear } from 'src/models/PlanningCycleModel';
import { RootState } from 'src/store/store';
import { planningCycleLockStatusModalColumnDefinition } from './PlanningCycleConfig';
import { selectAllPlanningCycles } from './planningCycleSelector';
import { convertPlanningsToFlat, selectPlanningCycleByScenarioYearAndDataClassification } from './planningCycleUtils';

interface LockStatusModalProps {
  item: PlanningCycleGroupedByScenarioYear;
  visible: boolean;
  onClose: () => void;
}

export const LockStatusModal: React.FC<LockStatusModalProps> = ({ item, visible, onClose }) => {
  const [businessGroupRows, setBusinessGroupRows] = useState<PlanningCycleFlatTable[]>([]);
  const allPlanningCycles = useSelector(selectAllPlanningCycles);
  const { userAccessForCurrentBusinessGroup } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore);
  const isFinanceAdminOrFinanceOwner = userAccessForCurrentBusinessGroup?.isFinanceAdminOrFinanceOwner || false;

  useEffect(() => {
    const dataClassificationNames = item.data_classification_name_csv
      .split(',')
      .map((name) => name.trim())
      .filter((name) => name);

    const planningCycleDetails = dataClassificationNames
      .map((dataClassificationName) =>
        selectPlanningCycleByScenarioYearAndDataClassification(item.scenario_year, dataClassificationName, allPlanningCycles)
      )
      .filter((detail): detail is PlanningCycleEntity => !!detail);

    setBusinessGroupRows(convertPlanningsToFlat(planningCycleDetails, isFinanceAdminOrFinanceOwner));
  }, [item]);

  return (
    <Modal
      className="custom-modal"
      visible={visible}
      header={`${item.scenario_year}`}
      size="large"
      footer={
        <Box float="right">
          <Button variant="primary" onClick={onClose}>
            Close
          </Button>
        </Box>
      }
      onDismiss={onClose}
    >
      <Table
        variant="embedded"
        columnDefinitions={planningCycleLockStatusModalColumnDefinition()}
        items={businessGroupRows}
        empty={<TableEmptyState resourceName={``} />}
      />
    </Modal>
  );
};
