import { TableProps } from '@amzn/awsui-components-react';
import { xPTAccountHierarchyInfoEntity } from 'src/models/AppContextModels';

export const ACCOUNT_HIERARCHY_DATA: xPTAccountHierarchyInfoEntity[] = [
  { budget_type: 'CAPEX', account_hierarchy: 'A1210' },
  { budget_type: 'OPEX', account_hierarchy: 'A6000' }
];

// Remove the 'as const' for column definitions since TableProps.ColumnDefinition needs to be mutable
export const ACCOUNT_HIERARCHY_COLUMNS: TableProps.ColumnDefinition<xPTAccountHierarchyInfoEntity>[] = [
  {
    id: 'budget_type',
    header: 'Budget Type',
    cell: (item: xPTAccountHierarchyInfoEntity) => item.budget_type
  },
  {
    id: 'account_hierarchy',
    header: 'Account Hierarchy',
    cell: (item: xPTAccountHierarchyInfoEntity) => item.account_hierarchy
  }
];
