import { createSelector } from '@reduxjs/toolkit';
import { BUSINESS_GROUP_CODES } from 'src/constants/business-group-constants';
import { FINANCE_ADMIN } from 'src/constants/generic-constants';
import { BusinessGroupEntity } from 'src/models/AppContextModels';
import { RootState } from 'src/store/store';

// Selector to get xptUsers from state
export const selectXptUsers = (state: RootState) => state.xptAccessAndAuthorizationStore.xptUsers;

// Selector to get all users (now using selectXptUsers instead of selectUserAuthorization)
export const selectAllUsers = createSelector(selectXptUsers, (xptUsers) => xptUsers);

// Selector to get users by alias (handles multiple users with the same alias)
export const selectUsersByAlias = (userAlias: string) =>
  createSelector(selectAllUsers, (users) => users.filter((user) => user.user_alias?.toLowerCase() === userAlias?.toLowerCase() && user.is_active));

// Selector to get currentUserAccessDetails
export const selectCurrentUserAccessDetails = (state: RootState) => state.xptAccessAndAuthorizationStore.currentUserAccessDetails;

// ================ Authorization Selectors ================

/**
 * Determines if the current user is a Finance Admin
 */
export const selectIsFinanceAdmin = createSelector(
  selectCurrentUserAccessDetails,
  (currentUserAccessDetails) =>
    currentUserAccessDetails?.user_data_classifications?.some((classification) => classification.user_type === FINANCE_ADMIN) ?? false
);

// ================ Business Group Selectors ================

/**
 * Selects the Operations business group
 */
export const selectOperationsBusinessGroup = createSelector(
  [(state: RootState) => state.businessGroupStore.businessGroups],
  (businessGroups): BusinessGroupEntity | undefined =>
    businessGroups?.find(
      (businessGroup) => businessGroup.data_classification.data_classification_short_description === BUSINESS_GROUP_CODES.OPERATIONS
    )
);

/**
 * Determines if the current user is a Finance Admin in Operations Group
 */
export const selectIsFinanceAdminForOperations = createSelector(
  [selectOperationsBusinessGroup, selectCurrentUserAccessDetails],
  (operationsGroup, currentUserAccessDetails): boolean => {
    // Early return if Operations group is not found
    if (!operationsGroup) {
      return false;
    }

    const hasOperationsAccess = currentUserAccessDetails?.user_data_classifications?.some(
      (access) => access.data_classification_id === operationsGroup.data_classification.data_classification_id && access.user_type === FINANCE_ADMIN
    );

    return hasOperationsAccess ?? false;
  }
);

/**
 * Determines if the current user is a Finance Admin for a specific business group
 */
export const selectIsFinanceAdminForBusinessGroup = (dataClassificationId: number) =>
  createSelector([selectIsFinanceAdmin, selectCurrentUserAccessDetails], (isFinanceAdmin, currentUserAccessDetails): boolean => {
    if (!isFinanceAdmin) {
      return false;
    }

    return currentUserAccessDetails?.user_data_classifications?.some((access) => access.data_classification_id === dataClassificationId) ?? false;
  });
