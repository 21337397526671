import { createExportForecastDetails, updateForecastDetails } from 'src/api/app-sync-services';
import { ForecastInputDetails } from 'src/models/ForecastModels';
import { uploadToS3 } from 'src/utils/aws-s3-services';
import { getForecastS3BucketName } from 'src/utils/xpt-s3-bucket-details';
import { forecastDataUpdateFolderKey } from './ForecastTemplateUtils';
import { getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';
import { logger } from 'src/analytics/KatalLogger';
import { BusinessGroupEntity } from 'src/models/AppContextModels';
import { PlanningCycleEntity } from 'src/models/PlanningCycleModel';
import XptMessages from 'src/constants/xpt-messages';
import { OperationType } from 'src/constants/generic-constants';
import { generateRequestId } from 'src/utils/generic-utilities';
import { sendSNSNotification } from 'src/utils/aws-sns-service';
import { replaceSpecialCharactersWithSpace } from 'src/utils/special-character-handler';

/**
 * Waits for a specified number of milliseconds.
 * @param {number} ms - The number of milliseconds to wait.
 * @returns {Promise<void>}
 */
const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const RETRY_LIMIT = 3;
const RETRY_WAIT_TIME = 2000; // 2 seconds

interface SubmitForecastData {
  userAlias: string;
  businessGroup: BusinessGroupEntity;
  currentPlanningCycle: PlanningCycleEntity;
  forecastTemplateDataForUpload: any[];
  submitOperationType: OperationType;
}

/**
 * Submits forecast data to S3 and updates the forecast details via a mutation.
 * Retries creating export forecast details up to 3 times if it fails.
 * @param {SubmitForecastData} params - The parameters required for submitting forecast data.
 * @returns {Promise<string>} Success message upon successful submission.
 * @throws {Error} If any operation fails.
 */
export const submitForecastData = async ({
  userAlias,
  businessGroup,
  currentPlanningCycle,
  forecastTemplateDataForUpload,
  submitOperationType
}: SubmitForecastData): Promise<string> => {
  const requestId = generateRequestId();
  const logPrefix = `Forecast Input Submission by ${userAlias} [${requestId}]`;
  const startTime = Date.now();
  logger.info(`${logPrefix} submission process started.`);

  try {
    // Extract necessary details from input parameters
    const dataClassificationId = businessGroup.data_classification.data_classification_id!;
    const dataClassificationName = businessGroup.data_classification.data_classification_name;
    const dataClassificationShortDesc = businessGroup.data_classification.data_classification_short_description;
    const scenarioSeqId = currentPlanningCycle.scenario_seq_id!;
    const { bucketName, region } = getForecastS3BucketName();
    const forecastUpdateS3Key = forecastDataUpdateFolderKey(dataClassificationId, dataClassificationShortDesc, scenarioSeqId);
    const updatedAt = getCurrentUTCTimeInISO();

    logger.info(`${logPrefix} Prepared data for S3 upload and mutation.`);

    // Metadata for the S3 object
    const s3ObjectMetadata: Record<string, string> = {
      forecast_data_for: currentPlanningCycle.scenario_year,
      forecast_data_length: `${forecastTemplateDataForUpload.length}`,
      updated_by: userAlias,
      updated_at: updatedAt
    };

    // Mutation object for updating forecast details
    const forecastMutationObject: ForecastInputDetails = {
      data_classification_id: dataClassificationId,
      scenario_seq_id: scenarioSeqId,
      s3_bucket_name: bucketName,
      s3_region: region,
      s3_update_file_key: forecastUpdateS3Key,
      updated_at: updatedAt,
      updated_by: userAlias,
      operation_type: submitOperationType
    };

    try {
      const parsedForecastData = await replaceSpecialCharactersWithSpace(forecastTemplateDataForUpload);
      logger.info(`${logPrefix} Successfully parsed forecast data and replaced special characters in string fields.`);

      // Upload forecast template data to S3
      await uploadToS3(bucketName, forecastUpdateS3Key, parsedForecastData, false, s3ObjectMetadata);
      logger.info(`${logPrefix} uploaded forecast data to S3.`);

      // Update forecast details via GraphQL mutation
      const submitMutationResponse = await updateForecastDetails(forecastMutationObject);
      logger.info(`${logPrefix} successfully submitted Mutation with response as : ${JSON.stringify(submitMutationResponse)}`);

      // Retry logic for creating export forecast details
      for (let attempt = 1; attempt <= RETRY_LIMIT; attempt++) {
        try {
          await createExportForecastDetails(forecastMutationObject);
          const endTime = Date.now();
          const duration = (endTime - startTime) / 1000; // duration in seconds
          logger.info(`${logPrefix} create export forecast mutation success on attempt ${attempt}. Total time: ${duration} seconds.`);
          return XptMessages.FORECAST_UPDATE_SUCCESS(currentPlanningCycle.scenario_year);
        } catch (error: any) {
          logger.info(`${logPrefix} Attempt ${attempt}/${RETRY_LIMIT} failed to create export forecast details: ${error.message}`);
          if (attempt === RETRY_LIMIT) {
            logger.error(`${logPrefix} Failed to create export forecast details after ${RETRY_LIMIT} attempts.`);
            sendSNSNotification(
              `Forecast Submission - Forecast Export Failed`,
              `Forecast submission - Forecast Export failed by ${userAlias} after ${RETRY_LIMIT} attempts in business group ${dataClassificationName}`,
              'error'
            );
            throw new Error(`${logPrefix} Failed to create export forecast details after ${RETRY_LIMIT} attempts.`);
          }
          await wait(RETRY_WAIT_TIME);
        }
      }
    } catch (error: any) {
      sendSNSNotification(
        `Forecast Submission Failed`,
        `Forecast submission failed by ${userAlias} in business group ${dataClassificationName}`,
        'error'
      );
      logger.error(`${logPrefix} Error during the forecast data submission process: ${error.message}`);
      throw error;
    }
  } catch (error: any) {
    sendSNSNotification(
      `Forecast Submission Failed`,
      `Forecast submission failed by ${userAlias} in business group ${businessGroup.data_classification.data_classification_name}`,
      'error'
    );
    logger.error(`${logPrefix} Error during the forecast data submission process: ${error.message}`);
    throw error;
  }

  // This should never be reached due to the throw statements above,
  // but it ensures the function always returns a Promise<string>.
  const endTime = Date.now();
  const duration = (endTime - startTime) / 1000; // duration in seconds
  logger.error(`${logPrefix} Unexpected error during forecast data submission process. Total time: ${duration} seconds.`);
  return Promise.reject(`${logPrefix} Unexpected error during forecast data submission process.`);
};
