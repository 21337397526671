import { BreadcrumbGroupProps, ButtonDropdownProps, SelectProps } from '@amzn/awsui-components-react';
import { SideBarDef } from 'ag-grid-community';
import moment from 'moment-timezone';
import { logger } from 'src/analytics/KatalLogger';
import { CorpSegmentNames } from 'src/constants/corp-segment-constants';
import { TABLE_VIEW_ACTIONS } from 'src/hooks/useGridState';
import { DropdownGroupModel, DropdownModel } from 'src/models/AppContextModels';
import { MasterScenarios, PlanningCycleEntity, PlanningCycleSnapshot } from 'src/models/PlanningCycleModel';
import { PlanningCycleInfo, PlanningCyclePeriodInfo, XptForecastReportGridRowData, XptReportRowDataStructured } from 'src/models/XptReportingModels';
import { convertUTCtoLocalISO, getCurrentYearMonthDate } from 'src/utils/date-time-utilities';
import { compareNullableNumbers } from 'src/utils/generic-utilities';
import { ForecastGridFixedFields } from '../business-group/forecast-template/forecast-utils/ForecastGridConstants';
import { forecastDataReadS3URI } from '../business-group/forecast-template/forecast-utils/ForecastTemplateUtils';
import { snapshotDataReadS3URI } from '../business-group/planning-cycle-snapshot/SnapshotFormUtils';
import { ReportTypes, XptReportGridFixedFields } from './XptReportGridConstants';

export const getXptReportsBreadcrumbItems = (
  businessGroupBaseBreadcrumbs: BreadcrumbGroupProps.Item[],
  currentBusinessGroupName?: string
): BreadcrumbGroupProps.Item[] => {
  if (!currentBusinessGroupName) {
    return businessGroupBaseBreadcrumbs;
  }

  return [
    ...businessGroupBaseBreadcrumbs,
    {
      text: 'Forecast Report',
      href: `/${currentBusinessGroupName}/forecast-report`
    }
  ];
};

export const getPlanningCyclesDropdownOptions = (
  scenarios: MasterScenarios[],
  planningCycles: PlanningCycleEntity[],
  scenarioSnapshots: PlanningCycleSnapshot[]
): SelectProps.OptionGroup[] => {
  const currentYear = getCurrentYearMonthDate().year;
  const lastYear = currentYear - 1;
  const combinedOptions: SelectProps.OptionGroup[] = [];

  scenarios.forEach((scenario) => {
    // Get Planning Cycles from Last Year to All future years for this scenario
    const planningCyclesForThisScenario = planningCycles.filter(
      (planningCycle) =>
        planningCycle.scenario.scenario_id === scenario.scenario_id &&
        (planningCycle.planning_cycle_year === currentYear || planningCycle.planning_cycle_year >= lastYear)
    );

    const planningCycleOptions = planningCyclesForThisScenario.map((pl) => {
      return {
        label: `${pl.scenario_year}`,
        value: `${pl.scenario_seq_id}`
      } as SelectProps.Option;
    });

    const latestSnapshots: PlanningCycleSnapshot[] = [];
    planningCyclesForThisScenario.forEach((planningCycle) => {
      // Get the latest scenario snapshot for this planning cycle
      const snapshotPerPlanningCycle: PlanningCycleSnapshot[] = scenarioSnapshots
        .filter((snapshot) => snapshot.scenario_seq_id === planningCycle.scenario_seq_id)
        .sort((a, b) => compareNullableNumbers(a.scenario_snapshot_id, b.scenario_snapshot_id, 'desc'));

      if (snapshotPerPlanningCycle.length > 0) {
        latestSnapshots.push(snapshotPerPlanningCycle[0]);
      }
    });

    const snapshotOptions = latestSnapshots.map((snapshot) => {
      return {
        label: `Snapshot ${snapshot.scenario_snapshot_name}`,
        value: `${snapshot.scenario_snapshot_id}`
      } as SelectProps.Option;
    });

    let scenarioOptions: SelectProps.OptionGroup = {
      label: scenario.scenario_name,
      options: [...planningCycleOptions, ...snapshotOptions] as SelectProps.Option[]
    };

    combinedOptions.push(scenarioOptions);
  });

  return combinedOptions;
};

export const isSnapshot = (planningCycle: SelectProps.Option | null): boolean => {
  const selectedOptionLabel = planningCycle?.label || '';
  return selectedOptionLabel.startsWith('Snapshot');
};

export const getPlanningCyclePath = (
  planningCycle: SelectProps.Option | null,
  data_classification_id: number,
  dataClassificationShortDesc: string,
  scenarioSnapshots?: any[]
): { path: string; isSnapshot: boolean } => {
  if (!data_classification_id || !dataClassificationShortDesc) {
    throw new Error('Data classification details are missing');
  }

  if (isSnapshot(planningCycle)) {
    return {
      path: getSnapshotPath(planningCycle, data_classification_id, dataClassificationShortDesc, scenarioSnapshots),
      isSnapshot: true
    };
  } else {
    return {
      path: getCyclePath(planningCycle, data_classification_id, dataClassificationShortDesc),
      isSnapshot: false
    };
  }
};

export const getSnapshotPath = (
  planningCycle: SelectProps.Option | null,
  data_classification_id: number,
  dataClassificationShortDesc: string,
  scenarioSnapshots?: any[]
): string => {
  const snapshotId = planningCycle?.value ? +planningCycle?.value : null;
  const scenarioSeqId = scenarioSnapshots?.find((scenarioSnapshot) => scenarioSnapshot.scenario_snapshot_id === snapshotId)?.scenario_seq_id || null;

  if (snapshotId && scenarioSeqId) {
    const snapshotPath = snapshotDataReadS3URI(data_classification_id, dataClassificationShortDesc, scenarioSeqId, snapshotId);
    return snapshotPath;
  } else {
    logger.error('Unable to find selected Planning Cycle Snapshot details');
    throw new Error('Unable to find selected Planning Cycle Snapshot details');
  }
};

export const getCyclePath = (
  planningCycle: SelectProps.Option | null,
  data_classification_id: number,
  dataClassificationShortDesc: string
): string => {
  const scenarioSeqId = planningCycle?.value ? +planningCycle?.value : null;

  if (scenarioSeqId) {
    const cyclePath = forecastDataReadS3URI(data_classification_id, dataClassificationShortDesc, scenarioSeqId);
    return cyclePath;
  } else {
    logger.error('Unable to find Planning Cycle details');
    throw new Error('Unable to find Planning Cycle details');
  }
};

// Flatten and concatenate corp segments and their descriptions
export const flattenAndConcatenateSegments = (segments: any[], optionalHeaders: string[]): any => {
  return Object.assign(
    {},
    ...segments.map((segment) => {
      const updatedSegment = { ...segment };
      Object.keys(CorpSegmentNames).forEach((key) => {
        const fieldName = CorpSegmentNames[key as keyof typeof CorpSegmentNames];
        const descriptionField = `${fieldName} Description`;
        if (segment[fieldName] && segment[descriptionField]) {
          updatedSegment[fieldName] = `${segment[fieldName]} - ${segment[descriptionField]}`;
          delete updatedSegment[descriptionField];
        }
      });
      optionalHeaders.forEach((header) => {
        delete updatedSegment[header];
      });
      return updatedSegment;
    })
  );
};

/**
 * Flattens and filters the XPT report row data for the Forecast Report.
 *
 * @param dataFromS3 - The structured data from S3
 * @param optionalCorpSegmentsHeader - Optional corporate segments header
 * @param planningCycleYear - The planning cycle year entity
 * @param userCostCentersForCurrentBusinessGroup - User's cost centers for the current business group
 * @param currentUserAlias - The current user's alias
 * @param isBudgetOwner - Boolean indicating if the user is a budget owner
 * @returns Flattened and filtered XPT forecast report grid row data
 */
export const filterAndFlattenForecastReportData = (
  dataFromS3: XptReportRowDataStructured[],
  optionalCorpSegmentsHeader: string[],
  planningCycleYear: PlanningCycleEntity,
  userCostCentersForCurrentBusinessGroup: string[],
  currentUserAlias: string,
  isBudgetOwner: boolean
): XptForecastReportGridRowData[] => {
  try {
    // Check for empty or undefined input data
    if (!dataFromS3 || dataFromS3.length === 0) {
      logger.warn('No data provided for flattening');
      return [];
    }

    // Check for required parameters
    if (!planningCycleYear || !userCostCentersForCurrentBusinessGroup || !currentUserAlias) {
      throw new Error('Missing required parameters for data flattening');
    }

    const finalRowDataStructured: XptForecastReportGridRowData[] = dataFromS3.map((item) => {
      // Flatten corporate segments
      const corpSegmentsFlattened = flattenAndConcatenateSegments(item.corp_segments, optionalCorpSegmentsHeader);

      // Flatten business segments and forecast months
      const busSegmentsFlattened = Object.assign({}, ...(item.bus_segments || []));
      const forecastMonthsFlattened = Object.assign({}, ...(item.forecast_months || []));

      // Remove actual months and destructure the item
      const { bus_segments, corp_segments, forecast_months, actual_months, ...rest } = item;

      // Combine all flattened data
      return {
        ...rest,
        [XptReportGridFixedFields.ScenarioYear.value]: planningCycleYear.scenario_year,
        ...corpSegmentsFlattened,
        ...busSegmentsFlattened,
        ...forecastMonthsFlattened,
        updated_at: item.updated_at ? convertUTCtoLocalISO(item.updated_at) : ''
      };
    });

    // Filter rows based on user permissions and cost centers
    const filteredBasedOnCC: XptForecastReportGridRowData[] = finalRowDataStructured.filter((row: XptForecastReportGridRowData) => {
      const rowCCWithDescription: string = row[CorpSegmentNames.COST_CENTER];
      const rowCCWithoutDescription = rowCCWithDescription.split(' - ')[0];
      const rowBudgetOwner = row[ForecastGridFixedFields.BudgetOwner.value];
      if (isBudgetOwner) {
        return currentUserAlias === rowBudgetOwner && userCostCentersForCurrentBusinessGroup.includes(rowCCWithoutDescription);
      } else {
        return userCostCentersForCurrentBusinessGroup.includes(rowCCWithoutDescription);
      }
    });

    return filteredBasedOnCC;
  } catch (error: any) {
    logger.error('Error flattening forecast row data structured:', error);
    throw new Error(`Error flattening forecast row data structured: ${error.message}`);
  }
};

export const getXptReportFileName = (businessGroupShortDesc: string, scenario_year: string, reportName: ReportTypes) => {
  const fileName = `${businessGroupShortDesc}_${scenario_year}_Xpt_${reportName}_Report`;
  const sheetName = `${scenario_year}`;
  return { fileName, sheetName };
};

export const xptReportGridFileActions = (): ButtonDropdownProps.ItemOrGroup[] => {
  const defaultActions: ButtonDropdownProps.ItemOrGroup[] = [
    {
      id: 'ag_grid_export_to_excel',
      text: 'Export to Excel'
    },
    ...TABLE_VIEW_ACTIONS
  ];

  return defaultActions;
};

export const getSelectedPlanningCycle = (
  selectedPlanningCycle: SelectProps.Option,
  data_classification_id: number,
  dataClassificationShortDesc: string,
  scenarioSnapshots: PlanningCycleSnapshot[],
  planningCycles: PlanningCycleEntity[]
): PlanningCycleInfo => {
  const { path, isSnapshot } = getPlanningCyclePath(selectedPlanningCycle, data_classification_id, dataClassificationShortDesc, scenarioSnapshots);
  const selectedOptionId = selectedPlanningCycle.value ? +selectedPlanningCycle.value : null;
  let planningCycleSelected: PlanningCycleEntity;
  let scenarioSnapshot: PlanningCycleSnapshot | undefined = undefined;

  if (isSnapshot) {
    scenarioSnapshot = scenarioSnapshots.find((snapshot) => snapshot.scenario_snapshot_id === selectedOptionId)!;
    planningCycleSelected = planningCycles.find((cycle) => cycle.scenario_seq_id === scenarioSnapshot?.scenario_seq_id)!;
  } else {
    planningCycleSelected = planningCycles.find((cycle) => cycle.scenario_seq_id === selectedOptionId)!;
  }
  const planningCyclePeriodInfo: PlanningCyclePeriodInfo = getPeriodInfo(
    planningCycleSelected.forecast_start_month_id,
    planningCycleSelected.forecast_end_month_id
  );

  const planningCycleInfo: PlanningCycleInfo = {
    isSnapshot,
    scenarioSnapshot,
    path,
    planningCycleSelected,
    planningCyclePeriodInfo
  };

  return planningCycleInfo;
};

// Function to get the period info including year and quarter details
export const getPeriodInfo = (startMonthId: string, endMonthId: string): PlanningCyclePeriodInfo => {
  try {
    const startMonth = moment(startMonthId, 'YYYY-MM-DD', true);
    const endMonth = moment(endMonthId, 'YYYY-MM-DD', true);

    if (!startMonth.isValid() || !endMonth.isValid()) {
      throw new Error('Invalid date format. Please use YYYY-MM-DD.');
    }

    if (endMonth.isBefore(startMonth)) {
      throw new Error('End month cannot be before start month.');
    }

    const years: number[] = [];
    const yearQuarters: string[] = [];
    const yearQuartersFinal: DropdownModel[] = [];
    const yearQuarterGroupByYear: DropdownGroupModel[] = [];

    let currentMonth = startMonth.clone();
    while (currentMonth.isSameOrBefore(endMonth, 'month')) {
      const { year, quarterValue, quarterReadable, quarterReadableForGroup } = getQuarterHeader(currentMonth);

      if (!years.includes(year)) {
        years.push(year);
        yearQuarterGroupByYear.push({
          label: year.toString(),
          options: []
        });
      }

      if (!yearQuarters.includes(quarterValue)) {
        yearQuarters.push(quarterValue);
        yearQuartersFinal.push({
          label: quarterReadable,
          value: quarterValue
        });

        const yearQuarterGroupIndex = yearQuarterGroupByYear.findIndex((group) => group.label === year.toString());
        yearQuarterGroupByYear[yearQuarterGroupIndex].options.push({
          label: quarterReadableForGroup,
          value: quarterValue
        });
      }

      currentMonth.add(1, 'month');
    }

    const periodInfo: PlanningCyclePeriodInfo = {
      startMonth: startMonth.format('YYYY-MM-DD'),
      endMonth: endMonth.format('YYYY-MM-DD'),
      year: years,
      year_quarter: yearQuartersFinal,
      year_quarter_group_by_year: yearQuarterGroupByYear
    };

    return periodInfo;
  } catch (error: any) {
    logger.error('Error in getPeriodInfo:', error.message);
    throw error; // Re-throw the error after logging
  }
};

// Function to get the common period info between two PlanningCyclePeriodInfo objects
export const getCommonPeriodInfo = (
  planningCyclePeriodInfo: PlanningCyclePeriodInfo,
  comparisonPlanningCyclePeriodInfo: PlanningCyclePeriodInfo
): PlanningCyclePeriodInfo => {
  try {
    const commonYears = planningCyclePeriodInfo.year.filter((year) => comparisonPlanningCyclePeriodInfo.year.includes(year));

    const commonYearQuarters = planningCyclePeriodInfo.year_quarter.filter((yearQuarter) =>
      comparisonPlanningCyclePeriodInfo.year_quarter.some((comparisonYearQuarter) => comparisonYearQuarter.value === yearQuarter.value)
    );

    const commonYearQuarterGroupByYear = planningCyclePeriodInfo.year_quarter_group_by_year.filter((group) =>
      comparisonPlanningCyclePeriodInfo.year_quarter_group_by_year.some((comparisonGroup) => comparisonGroup.label === group.label)
    );

    commonYearQuarterGroupByYear.forEach((group) => {
      group.options = group.options.filter((option) =>
        comparisonPlanningCyclePeriodInfo.year_quarter.some((comparisonOption) => comparisonOption.value === option.value)
      );
    });

    return {
      startMonth: planningCyclePeriodInfo.startMonth,
      endMonth: planningCyclePeriodInfo.endMonth,
      year: commonYears,
      year_quarter: commonYearQuarters,
      year_quarter_group_by_year: commonYearQuarterGroupByYear
    };
  } catch (error: any) {
    logger.error('Error in getCommonPeriodInfo:', error.message);
    throw error; // Re-throw the error after logging
  }
};

export const getQuarterHeader = (currentMonth: moment.Moment) => {
  const year = currentMonth.year();
  const quarterValue = `${year}_Q${Math.ceil((currentMonth.month() + 1) / 3)}`;
  const quarterReadable = `${year} Q${Math.ceil((currentMonth.month() + 1) / 3)}`;
  const quarterReadableForGroup = `Q${Math.ceil((currentMonth.month() + 1) / 3)}`;
  return { year, quarterValue, quarterReadable, quarterReadableForGroup };
};

const toolPanelBaseWidth = {
  minWidth: 180,
  maxWidth: 400,
  width: 250
};

export const getReportingSideNavBarConfig = (showPivotModeOptionInPanel: boolean): SideBarDef => {
  return {
    position: 'right',
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressPivotMode: !showPivotModeOptionInPanel // This enables/disables the Pivot Mode toggle.
        },
        ...toolPanelBaseWidth
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
        toolPanelParams: {
          suppressExpandAll: false,
          suppressFilterSearch: false
        },
        ...toolPanelBaseWidth
      }
    ]
  };
};
