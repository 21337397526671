import { SideNavigationProps } from '@amzn/awsui-components-react';

/**
 * Recursively finds the active href in the side navigation items.
 * This function navigates through the nested structure of the navigation items,
 * checking if the current pathname starts with any of the item's href.
 */
export const findActiveHref = (pathname: string, items: ReadonlyArray<SideNavigationProps.Item> | SideNavigationProps.Item[]): string => {
  // Decode the pathname to handle %20 and other encoded characters
  const decodedPathname = decodeURIComponent(pathname);

  for (const item of items) {
    if ('href' in item) {
      // Decode the href as well for comparison
      const decodedHref = decodeURIComponent(item.href);
      if (decodedPathname.startsWith(decodedHref)) {
        return item.href; // Return the original encoded href
      }
    }
    if ('items' in item) {
      const activeHref = findActiveHref(pathname, item.items as SideNavigationProps.Item[]);
      if (activeHref) return activeHref;
    }
  }
  return '';
};
