import { Container, FormField, Multiselect, Select, SelectProps, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logger, logUserClick } from 'src/analytics/KatalLogger';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { eErrorMessages, SELECT_ALL_OPTION } from 'src/constants/generic-constants';
import { getLocalStorageItem, setLocalStorageItem } from 'src/hooks/useLocalStorage';
import { Status } from 'src/models/AuthContextModels';
import { AccountFilter, CostCenterFilter, POCorpSegmentFilterDropdowns, ProductFilter, ProjectFilter } from 'src/models/POTaggingModel';
import { AppDispatch, RootState } from 'src/store/store';
import { generateUniqueId, getMultiSelectPlaceHolderValue } from 'src/utils/generic-utilities';
import { ConfirmDiscardModal } from '../../components/common/ConfirmDiscardModal';
import { currentBusinessGroupName, currentBusinessGroupShortDesc, currentDataClassificationId } from '../business-group/businessGroupSelectors';
import { usePOTaggingContext } from './POTaggingContext';
import {
  applyFilters,
  fetchActualsFilterDropdowns,
  fetchActualTaggingData,
  setFiltersSelected,
  setIsGridDirty,
  setSelectedActualMonth,
  setSelectedCostCenter,
  clearActualsDataAndFilterDropdownValues
} from './POTaggingSlice';
import { getCurrentMonthFromPOTaggingMonthList, validateSelections } from './POTaggingUtils';

export const POTaggingFilterSelection: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { userAccessForCurrentBusinessGroupStatus, userCostCentersForCurrentBusinessGroup } = useSelector(
    (state: RootState) => state.xptAccessAndAuthorizationStore
  )!;
  const businessGroupName = useSelector(currentBusinessGroupName);
  const dataClassificationId = useSelector(currentDataClassificationId);
  const businessGroupShortDesc = useSelector(currentBusinessGroupShortDesc);

  const localStoragePrefix = `po_tagging_filters`;
  const poTaggingMonthFilterKey = `${localStoragePrefix}_${businessGroupShortDesc}_po_month`;
  const poTaggingCCFilterKey = `${localStoragePrefix}_${businessGroupShortDesc}_po_cc`;

  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [pendingFilterChange, setPendingFilterChange] = useState<(() => void) | null>(null);

  const { displayFlashMessage, clearSpecificFlashMessage } = usePOTaggingContext();
  const {
    actualsFilterDataStatus,
    actualsFilterDropdownValues,
    selectedActualMonth,
    selectedCostCenter,
    actualTaggingData,
    filtersSelected,
    isGridDirty
  } = useSelector((state: RootState) => state.poTaggingStore);

  // Fetch PO Tagging dropdown values (Months & CC's) from the API
  useEffect(() => {
    const initializePOTaggingFilters = async () => {
      const canLoadFilters = dataClassificationId && businessGroupShortDesc && userAccessForCurrentBusinessGroupStatus === Status.Completed;

      if (!canLoadFilters) {
        return;
      }

      setFiltersLoaded(false);

      try {
        await dispatch(
          fetchActualsFilterDropdowns({
            dataClassificationId,
            userCostCentersForCurrentBusinessGroup
          })
        ).unwrap();
      } catch (error) {
        logger.error(`Unable to load PO Tagging filters: ${error}`, { error });
        const messageId = generateUniqueId();
        displayFlashMessage(`Unable to load PO Tagging filters`, 'error', true, messageId);
        setTimeout(() => clearSpecificFlashMessage(messageId), 3000);
      } finally {
        setFiltersLoaded(true);
      }
    };

    initializePOTaggingFilters();
  }, [dataClassificationId, businessGroupShortDesc, userCostCentersForCurrentBusinessGroup, userAccessForCurrentBusinessGroupStatus]);

  // Fetch PO Tagging dropdown values (Months & CC's) from the API
  useEffect(() => {
    if (businessGroupShortDesc && actualsFilterDataStatus === Status.Completed) {
      //If there is no PO filter data then reset the local storage and global filters
      if (actualsFilterDropdownValues.actual_months.length === 0) {
        dispatch(setSelectedActualMonth(''));
        setLocalStorageItem(poTaggingMonthFilterKey, '');
        dispatch(setSelectedCostCenter(''));
        setLocalStorageItem(poTaggingCCFilterKey, '');
        dispatch(
          setFiltersSelected({
            product_filter: [],
            project_filter: [],
            account_filter: [],
            je_category: []
          })
        );
        dispatch(clearActualsDataAndFilterDropdownValues());
        const messageId = generateUniqueId();
        displayFlashMessage(eErrorMessages.NO_DATA_AVAILABLE, 'error', true, messageId);
      } else {
        const storedPOMonth = getLocalStorageItem<string>(poTaggingMonthFilterKey) || '';
        const storedPOCostCenter = getLocalStorageItem<string>(poTaggingCCFilterKey) || '';

        const validSelections = validateSelections({ actualMonth: storedPOMonth, costCenter: storedPOCostCenter }, actualsFilterDropdownValues);

        if (validSelections.actualMonth) {
          dispatch(setSelectedActualMonth(validSelections.actualMonth));
          setLocalStorageItem(poTaggingMonthFilterKey, validSelections.actualMonth);
        } else if (actualsFilterDropdownValues.actual_months.length > 0) {
          const currentMonthFromPoTaggingMonthList = getCurrentMonthFromPOTaggingMonthList(actualsFilterDropdownValues.actual_months);
          dispatch(setSelectedActualMonth(currentMonthFromPoTaggingMonthList));
          setLocalStorageItem(poTaggingMonthFilterKey, currentMonthFromPoTaggingMonthList);
        }

        if (validSelections.costCenter) {
          dispatch(setSelectedCostCenter(validSelections.costCenter));
          setLocalStorageItem(poTaggingCCFilterKey, validSelections.costCenter || '');
        } else if (actualsFilterDropdownValues.cost_center.length > 0) {
          dispatch(setSelectedCostCenter(actualsFilterDropdownValues.cost_center[0].cost_center_code));
          setLocalStorageItem(poTaggingCCFilterKey, actualsFilterDropdownValues.cost_center[0].cost_center_code);
        }
      }
    }
  }, [businessGroupShortDesc, actualsFilterDataStatus, actualsFilterDropdownValues]);

  // Based on Month & Cost Center selection, fetches PO Tagging data
  useEffect(() => {
    if (dataClassificationId && businessGroupShortDesc && filtersLoaded && selectedActualMonth && selectedCostCenter) {
      dispatch(
        fetchActualTaggingData({
          dataClassificationId,
          businessGroupShortDesc,
          selectedActualMonth,
          selectedCostCenter
        })
      )
        .unwrap()
        .catch((error: string) => {
          logger.error(`Error: ${error}`);
          const errorMessage =
            error === eErrorMessages.NO_DATA_FOUND
              ? `No data found for the selected month (${selectedActualMonth}) and cost center (${selectedCostCenter})`
              : `Unable to load PO tagging data`;

          const messageId = generateUniqueId();
          displayFlashMessage(errorMessage, 'error', true, messageId);
          setTimeout(() => clearSpecificFlashMessage(messageId), 3000);
        });
    }
  }, [selectedActualMonth, selectedCostCenter, filtersLoaded]);

  // Month selection change event
  const handleActualMonthChange = (selectedOption: SelectProps.Option) => {
    const change = () => {
      if (businessGroupShortDesc) {
        logUserClick('Changed month filter', 'PO Tagging', businessGroupName);
        dispatch(setSelectedActualMonth(selectedOption.label || ''));
        setLocalStorageItem(poTaggingMonthFilterKey, selectedOption.label || '');
      }
    };
    if (isGridDirty) {
      setPendingFilterChange(() => change);
      setShowDiscardModal(true);
    } else {
      change();
    }
  };

  // CC selection change event
  const handleCostCenterChange = (selectedOption: SelectProps.Option) => {
    const change = () => {
      if (businessGroupShortDesc) {
        logUserClick('Changed cost_center filter', 'PO Tagging', businessGroupName);
        dispatch(setSelectedCostCenter(selectedOption.label || ''));
        setLocalStorageItem(poTaggingCCFilterKey, selectedOption.label || '');
      }
    };
    if (isGridDirty) {
      setPendingFilterChange(() => change);
      setShowDiscardModal(true);
    } else {
      change();
    }
  };

  // Other filters Product, Project, Account & JE Category change event
  const handleFilterChange = (field: keyof POCorpSegmentFilterDropdowns) => (selectedOptions: SelectProps.Options) => {
    const change = () => {
      let selectedValues: any[];
      switch (field) {
        case 'product_filter':
          selectedValues = selectedOptions.map((option: SelectProps.Option) => ({
            product_code: option.value,
            product_description: option.description || ''
          }));
          break;
        case 'project_filter':
          selectedValues = selectedOptions.map((option: SelectProps.Option) => ({
            project_code: option.value,
            project_description: option.description || ''
          }));
          break;
        case 'account_filter':
          selectedValues = selectedOptions.map((option: SelectProps.Option) => ({
            account_code: option.value,
            account_description: option.description || ''
          }));
          break;
        case 'je_category':
          selectedValues = selectedOptions.map((option: SelectProps.Option) => option.value);
          break;
        default:
          selectedValues = [];
      }
      logUserClick(`Changed ${field} filter`, 'PO Tagging', businessGroupName);
      dispatch(setFiltersSelected({ [field]: selectedValues } as Partial<POCorpSegmentFilterDropdowns>));
      dispatch(applyFilters());
    };

    if (isGridDirty) {
      setPendingFilterChange(() => change);
      setShowDiscardModal(true);
    } else {
      change();
    }
  };

  const handleConfirmDiscard = () => {
    setShowDiscardModal(false);
    if (pendingFilterChange) {
      pendingFilterChange();
      setPendingFilterChange(null);
      dispatch(setIsGridDirty(false));
    }
  };

  const handleCancelDiscard = () => {
    setShowDiscardModal(false);
    setPendingFilterChange(null);
  };

  const getCostCenterOptions = () => {
    const costCenters = actualsFilterDropdownValues.cost_center;
    if (!costCenters) return [];

    const costCentersApplicableToUser = costCenters.filter((cc) => userCostCentersForCurrentBusinessGroup.includes(cc.cost_center_code));
    return costCentersApplicableToUser.map((costCenter: CostCenterFilter) => {
      return { label: costCenter.cost_center_code, value: costCenter.cost_center_code, description: costCenter.cost_center_description };
    });
  };

  return (
    <>
      {!filtersLoaded && <LoadingSpinner />}
      {filtersLoaded && (
        <Container disableHeaderPaddings className="transform">
          <SpaceBetween size="m" direction="horizontal">
            <FormField label="Month" className="width-20-rem">
              <Select
                placeholder={getMultiSelectPlaceHolderValue(selectedActualMonth, 'Actuals Months')}
                options={actualsFilterDropdownValues.actual_months?.map((month: string) => {
                  return { label: month, value: month };
                })}
                selectedOption={selectedActualMonth ? { label: selectedActualMonth || '', value: selectedActualMonth || '' } : null}
                onChange={({ detail }) => handleActualMonthChange(detail.selectedOption)}
                expandToViewport
                filteringType="auto"
                empty="No data"
              />
            </FormField>

            <FormField label="Cost Center" className="width-20-rem">
              <Select
                placeholder={getMultiSelectPlaceHolderValue(selectedCostCenter, 'Cost Center')}
                options={getCostCenterOptions()}
                selectedOption={selectedCostCenter ? { label: selectedCostCenter || '', value: selectedCostCenter || '' } : null}
                onChange={({ detail }) => handleCostCenterChange(detail.selectedOption)}
                expandToViewport
                filteringType="auto"
                empty="No data"
              />
            </FormField>

            {/* Distinct Product options from the PO Tagging data will be shown here. */}
            <FormField label="Product" className="width-20-rem">
              <Multiselect
                placeholder={getMultiSelectPlaceHolderValue(
                  filtersSelected.product_filter.map((product: ProductFilter) => ({
                    label: product.product_code,
                    value: product.product_code,
                    description: product.product_description
                  })),
                  'Product'
                )}
                options={[
                  {
                    ...SELECT_ALL_OPTION,
                    options: actualTaggingData.filterData.product_filter?.map((product: ProductFilter) => {
                      return { label: product.product_code, value: product.product_code, description: product.product_description };
                    })
                  }
                ]}
                selectedOptions={filtersSelected.product_filter.map((product: ProductFilter) => ({
                  label: product.product_code,
                  value: product.product_code,
                  description: product.product_description
                }))}
                onChange={({ detail }) => handleFilterChange('product_filter')(detail.selectedOptions)}
                expandToViewport
                filteringType="auto"
                empty="No data"
                hideTokens
              />
            </FormField>

            {/* Distinct Project options from the PO Tagging data will be shown here. */}
            <FormField label="Project" className="width-20-rem">
              <Multiselect
                placeholder={getMultiSelectPlaceHolderValue(
                  filtersSelected.project_filter.map((project: ProjectFilter) => ({
                    label: project.project_code,
                    value: project.project_code,
                    description: project.project_description
                  })),
                  'Project'
                )}
                options={[
                  {
                    ...SELECT_ALL_OPTION,
                    options: actualTaggingData.filterData.project_filter?.map((project: ProjectFilter) => {
                      return { label: project.project_code, value: project.project_code, description: project.project_description };
                    })
                  }
                ]}
                selectedOptions={filtersSelected.project_filter.map((project: ProjectFilter) => ({
                  label: project.project_code,
                  value: project.project_code,
                  description: project.project_description
                }))}
                onChange={({ detail }) => handleFilterChange('project_filter')(detail.selectedOptions)}
                expandToViewport
                filteringType="auto"
                empty="No data"
                hideTokens
              />
            </FormField>

            {/* Distinct Account options from the PO Tagging data will be shown here. */}
            <FormField label="Account" className="width-20-rem">
              <Multiselect
                placeholder={getMultiSelectPlaceHolderValue(
                  filtersSelected.account_filter.map((account: AccountFilter) => ({
                    label: account.account_code,
                    value: account.account_code,
                    description: account.account_description
                  })),
                  'Account'
                )}
                options={[
                  {
                    ...SELECT_ALL_OPTION,
                    options: actualTaggingData.filterData.account_filter?.map((account: AccountFilter) => {
                      return { label: account.account_code, value: account.account_code, description: account.account_description };
                    })
                  }
                ]}
                selectedOptions={filtersSelected.account_filter.map((account: AccountFilter) => ({
                  label: account.account_code,
                  value: account.account_code,
                  description: account.account_description
                }))}
                onChange={({ detail }) => handleFilterChange('account_filter')(detail.selectedOptions)}
                expandToViewport
                filteringType="auto"
                empty="No data"
                hideTokens
              />
            </FormField>

            {/* Distinct JE Category options from the PO Tagging data will be shown here. */}
            <FormField label="JE Category" className="width-20-rem">
              <Multiselect
                placeholder={getMultiSelectPlaceHolderValue(
                  filtersSelected.je_category.map((category: string) => ({ label: category, value: category })),
                  'JE Category'
                )}
                options={[
                  {
                    ...SELECT_ALL_OPTION,
                    options: actualTaggingData.filterData.je_category?.map((category: string) => {
                      return { label: category, value: category };
                    })
                  }
                ]}
                selectedOptions={filtersSelected.je_category.map((category: string) => ({ label: category, value: category }))}
                onChange={({ detail }) => handleFilterChange('je_category')(detail.selectedOptions)}
                expandToViewport
                filteringType="auto"
                empty="No data"
                hideTokens
              />
            </FormField>
          </SpaceBetween>
        </Container>
      )}

      <ConfirmDiscardModal visible={showDiscardModal} onConfirm={handleConfirmDiscard} onCancel={handleCancelDiscard} />
    </>
  );
};
