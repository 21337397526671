import { CellClassParams, ColDef, INumberCellEditorParams, ValueFormatterParams, ValueGetterParams, ValueParserParams } from 'ag-grid-community';
import { logger } from 'src/analytics/KatalLogger';
import { HEADER_TYPES, HeaderType } from 'src/constants/generic-constants';
import { BusinessGroupEntity, BusinessSegmentsEntity, CorpSegmentsEntity } from 'src/models/AppContextModels';
import { PlanningCycleEntity } from 'src/models/PlanningCycleModel';
import { CellDataType, cellValueParser, deepClone, forecastCurrencyFormatter } from 'src/utils/ag-grid-utils';
import {
  convertMonthFormatToDisplay,
  formatISOToReadable,
  generateMonthList,
  getQuarterFromMonth,
  getYearFromMonthYear
} from 'src/utils/date-time-utilities';
import { customBusinessSegmentSort, customCorpSegmentSort } from '../business-group/forecast-template/forecast-utils/CorpSegmentsUtils';
import { ColumnWidths, XptReportGridFixedFields } from './XptReportGridConstants';

export type ColumnGroupType = 'ClosedGroup' | 'OpenGroup';

export const generateForecastReportColumnDefinitions = async (
  userAlias: string,
  planningCycle: PlanningCycleEntity,
  businessGroup: BusinessGroupEntity
): Promise<ColDef[]> => {
  if (!planningCycle) {
    return [];
  }
  try {
    const requiredCorpSegments = businessGroup.corp_segments.filter((corpSegment) => corpSegment.corp_segment_required);
    const businessSegments = businessGroup.business_segments;
    const forecastMonths = generateMonthList(planningCycle.forecast_start_month_id, planningCycle.forecast_end_month_id);
    const xptForecastReportColumnDefinitions: ColDef[] = [
      ...generateReportCorpSegmentsRequiredColumns(requiredCorpSegments),
      ...generateReportBusinessGroupColumns(businessSegments),
      ...generateReportDetailColumns(), // Line Item Id, Scenario Year, Budget Owner, Budget Type
      ...generateForecastColumnsWithGrouping(forecastMonths, HEADER_TYPES.FORECAST, userAlias),
      ...getSuffixColumns() // last updated at, updated by
    ];
    return xptForecastReportColumnDefinitions;
  } catch (error: any) {
    logger.error('Unable to generate column definition', error);
    throw new Error(error?.message || 'Unable to generate column definition');
  }
};

export const generateReportCorpSegmentsRequiredColumns = (corpSegments: [] | CorpSegmentsEntity[]): ColDef[] => {
  const modifiableCorpSegments = deepClone(corpSegments);
  const sortedCorpSegments = modifiableCorpSegments.sort(customCorpSegmentSort);
  const corpSegmentGroup: ColDef[] = sortedCorpSegments.map((segment, index) => {
    return {
      field: segment.corp_segment_name,
      headerName: `${segment.corp_segment_name}`,
      wrapHeaderText: true,
      width: ColumnWidths.CORP_SEGMENT_DESCRIPTION_COLUMN,
      minWidth: ColumnWidths.CORP_SEGMENT_DESCRIPTION_COLUMN,
      cellClass: ['text-field'],
      cellDataType: CellDataType.TEXT,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    };
  });
  return corpSegmentGroup;
};

export const generateReportBusinessGroupColumns = (businessSegments: BusinessSegmentsEntity[] | []): ColDef[] => {
  // Creates a deep copy of `modifiableBusinessSegments` from `businessSegments` to ensure immutability.
  // This allows modifications to the segments without altering the original array.
  // `cloneDeep` is used to copy nested objects, ensuring changes to deep properties do not affect the original data structure.
  const modifiableBusinessSegments = deepClone(businessSegments);
  const sortedBusinessSegments = modifiableBusinessSegments.sort(customBusinessSegmentSort);
  const businessSegmentGroup: ColDef[] = sortedBusinessSegments.map((segment, index) => {
    return {
      field: `${segment.business_segment_name}`,
      headerName: segment.business_segment_name,
      wrapHeaderText: true,
      cellDataType: CellDataType.TEXT,
      width: ColumnWidths.BUSINESS_SEGMENT_COLUMN,
      minWidth: ColumnWidths.BUSINESS_SEGMENT_COLUMN,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    };
  });
  return businessSegmentGroup;
};

export const generateReportDetailColumns = (): ColDef[] => {
  const InitialColumns: ColDef[] = [
    {
      field: XptReportGridFixedFields.XptLineItemId.value,
      headerName: XptReportGridFixedFields.XptLineItemId.displayName,
      wrapHeaderText: true,
      width: ColumnWidths.LINE_ITEM_ID,
      minWidth: ColumnWidths.LINE_ITEM_ID,
      cellClass: ['text-field'],
      cellDataType: CellDataType.TEXT,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      field: XptReportGridFixedFields.ScenarioYear.value,
      headerName: XptReportGridFixedFields.ScenarioYear.displayName,
      wrapHeaderText: true,
      width: ColumnWidths.PLANNING_CYCLE_YEAR_COLUMN,
      minWidth: ColumnWidths.PLANNING_CYCLE_YEAR_COLUMN,
      cellClass: ['text-field'],
      cellDataType: CellDataType.TEXT,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      field: XptReportGridFixedFields.BudgetOwner.value,
      headerName: XptReportGridFixedFields.BudgetOwner.displayName,
      wrapHeaderText: true,
      width: ColumnWidths.USER_ALIAS_COLUMN,
      minWidth: ColumnWidths.USER_ALIAS_COLUMN,
      cellClass: ['text-field'],
      cellDataType: CellDataType.TEXT,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      field: XptReportGridFixedFields.BudgetType.value,
      headerName: XptReportGridFixedFields.BudgetType.displayName,
      wrapHeaderText: true,
      width: ColumnWidths.BUDGET_TYPE_COLUMN,
      minWidth: ColumnWidths.BUDGET_TYPE_COLUMN,
      cellClass: ['text-field'],
      cellDataType: CellDataType.TEXT,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    }
  ];
  return InitialColumns;
};

export const generateForecastColumnsWithGrouping = (monthHeaders: string[], headerFor: HeaderType, userAlias: string): ColDef[] => {
  const agGridHeaderData: ColDef[] = [];

  // Extract unique years from month headers
  const years = new Set<string>();
  monthHeaders?.forEach((monthHeader) => {
    years.add(getYearFromMonthYear(monthHeader));
  });

  years.forEach((year: string) => {
    // Filter month headers for the current year
    const monthsOfThisYear = monthHeaders?.filter((monthHeader) => monthHeader.startsWith(year.toString()));
    const quartersOfThisYear = getQuartersFromMonths(monthsOfThisYear);

    const quarterWithMonthlyHeaderInfo: ColDef[] = [];

    quartersOfThisYear?.forEach((quarter) => {
      // Filter month headers for the current quarter
      const monthsOfThisQuarter = monthsOfThisYear?.filter((month) => quarter === getQuarterFromMonth(month));

      // Generate month column definitions
      const monthColumnDefinitions: ColDef[] = generateReportMonthColumnDefinition(monthsOfThisQuarter, headerFor, userAlias);

      // Generate quarter column definitions including monthly columns
      generateMonthsWithQuarterColumnDefinition(quarterWithMonthlyHeaderInfo, quarter, monthColumnDefinitions, year, headerFor);
    });

    // Generate year column definitions including quarter columns
    const yearColumnDefinitions = generateYearColumnDefinitions(year, quarterWithMonthlyHeaderInfo, headerFor);
    agGridHeaderData.push(yearColumnDefinitions);
  });

  const finalOutput: ColDef[] = [];
  agGridHeaderData.forEach((year: any) => {
    year.children?.forEach((quarter: any) => {
      if (quarter.children?.length > 0) {
        quarter.children?.forEach((monthlyData: any) => {
          finalOutput.push({ ...monthlyData, columnGroupShow: 'open' });
        });
      } else {
        finalOutput.push({ ...quarter, columnGroupShow: 'open' });
        finalOutput.push({ ...quarter, columnGroupShow: 'closed', filter: false });
      }
    });
  });

  return agGridHeaderData;
};

export const generateReportMonthColumnDefinition = (monthsOfThisQuarter: string[], headerFor: string, userAlias: string): ColDef<any, any>[] => {
  return monthsOfThisQuarter?.map((monthInQuarter) => {
    return {
      field: monthInQuarter,
      headerName: convertMonthFormatToDisplay(monthInQuarter),
      suppressFiltersToolPanel: true,
      wrapHeaderText: true,
      width: ColumnWidths.MONTH_COLUMN,
      suppressMovable: true,
      cellDataType: CellDataType.NUMBER,
      columnGroupShow: 'open',
      filter: 'agNumberColumnFilter',
      cellClass: ['forecast-month-cell'],
      cellClassRules: {
        'bold-text': (params: CellClassParams) => params.node.footer
      },
      cellRenderer: 'agAnimateShowChangeCellRenderer',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        showStepperButtons: false,
        preventStepping: true
      } as INumberCellEditorParams,
      valueParser: (params: ValueParserParams) => cellValueParser(params.newValue),
      valueFormatter: (params: ValueFormatterParams) => forecastCurrencyFormatter(params.value),
      aggFunc: 'sum',
      enableValue: true,
      enableRowGroup: false,
      enablePivot: false
    } as ColDef<any, any>;
  });
};

export const getQuartersFromMonths = (monthsOfThisYear: string[]): string[] => {
  const quarters: Set<string> = new Set();
  monthsOfThisYear?.forEach((month) => {
    quarters.add(getQuarterFromMonth(month));
  });
  return Array.from(quarters);
};

export const generateMonthsWithQuarterColumnDefinition = (
  quarterWithMonthlyHeaderInfo: ColDef<any, any>[],
  quarter: string,
  monthColumnDefinitions: ColDef<any, any>[],
  year: string,
  headerFor: string
): void => {
  // Add the monthly columns first
  quarterWithMonthlyHeaderInfo.push(...monthColumnDefinitions);

  // Define the quarterly total column
  const quarterlyTotalColumn: ColDef<any, any> = {
    field: `${year}-${quarter}-${headerFor}-Total`,
    headerName: `${quarter}-${year}`,
    hide: false,
    suppressFiltersToolPanel: false,
    suppressColumnsToolPanel: false,
    wrapHeaderText: true,
    width: ColumnWidths.QUARTER_COLUMN,
    suppressMovable: true,
    cellClass: ['forecast-quarter-cell'],
    cellClassRules: {
      'bold-text': (params: CellClassParams) => params.node.footer || false
    },
    cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellDataType: CellDataType.NUMBER,
    columnGroupShow: 'open',
    filter: 'agNumberColumnFilter',
    valueParser: (params: ValueParserParams) => cellValueParser(params.newValue),
    valueFormatter: (params: ValueFormatterParams) => forecastCurrencyFormatter(params.value),
    valueGetter: (params: ValueGetterParams) => sumUpColumns(params, monthColumnDefinitions),
    aggFunc: 'sum',
    enableValue: true,
    enableRowGroup: false,
    enablePivot: false
  };

  // Add the quarterly total column for the 'open' state
  quarterWithMonthlyHeaderInfo.push({
    ...quarterlyTotalColumn,
    columnGroupShow: 'open'
  });
};

export const generateYearColumnDefinitions = (
  year: string,
  quarterWithMonthlyHeaderInfo: ColDef<any, any>[],
  headerFor: string
): ColDef<any, any> => {
  // Define the yearly total column
  const yearlyTotalColumn: ColDef = {
    headerName: `${year} Total`,
    wrapHeaderText: true,
    width: ColumnWidths.YEAR_COLUMN,
    field: `${year}-${headerFor}-YearlyTotal`,
    suppressMovable: true,
    marryChildren: true,
    cellClass: ['forecast-year-cell'],
    cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClassRules: {
      'bold-text': (params: CellClassParams) => params.node.footer
    },
    cellDataType: CellDataType.NUMBER,
    filter: 'agNumberColumnFilter',
    valueParser: (params: ValueParserParams) => cellValueParser(params.newValue),
    valueFormatter: (params: ValueFormatterParams) => forecastCurrencyFormatter(params.value),
    valueGetter: (params: ValueGetterParams) => sumUpColumns(params, quarterWithMonthlyHeaderInfo),
    aggFunc: 'sum',
    enableValue: true,
    enableRowGroup: false,
    enablePivot: false
  } as ColDef;

  // Create the year column definition including the quarterly and yearly total columns
  return {
    headerName: `${year}`,
    marryChildren: true,
    children: [
      ...quarterWithMonthlyHeaderInfo,
      {
        ...yearlyTotalColumn,
        columnGroupShow: 'open'
      },
      {
        ...yearlyTotalColumn,
        columnGroupShow: 'closed',
        filter: false,
        suppressColumnsToolPanel: true
      } as ColDef
    ]
  } as ColDef<any, any>;
};

/**
 * Calculates the sum of all numeric values from columns within each quarter that do not end in 'total'.
 * This function is designed to aggregate numeric data across multiple months within a year,
 * skipping any aggregated total columns.
 * It returns null if no numeric data is found; otherwise, it returns the sum.
 *
 * @param {ValueGetterParams} params - Contains data for the current row in the grid.
 * @param {ColDef[]} quarterWithMonthlyHeaderInfo - An array of column definitions, each representing a quarter, containing child columns for each month.
 * @returns {number|null} The sum of all numeric monthly values within the year, or null if there are no numbers.
 */
export const sumUpYearlyColumns = (params: ValueGetterParams, quarterWithMonthlyHeaderInfo: ColDef[]): number | null => {
  try {
    let sum = 0;
    let containsNumberOrZero = false; // Flag to track if any number or zero has been added to the sum.

    quarterWithMonthlyHeaderInfo.forEach((quarterMonth: any) => {
      quarterMonth.children?.forEach((monthly: any) => {
        if (monthly.field && !monthly.field.toLowerCase().endsWith('total')) {
          // Skip 'total' columns
          const value = params.data[monthly.field];
          if (value !== null && value !== undefined) {
            if (value === '0' || !isNaN(Number(value))) {
              containsNumberOrZero = true;
              sum += Number(value); // Convert to Number to ensure correct arithmetic operation.
            }
          }
        }
      });
    });

    return containsNumberOrZero ? sum : null; // Return the calculated sum or null if no valid numbers were processed.
  } catch (error: any) {
    return null;
  }
};

/**
 * Sums up numeric values from specified columns in a row of data.
 * If there are no numeric values or the data is absent, returns null.
 * This function considers zero as a valid number contributing to the sum.
 *
 * @param {ValueGetterParams} params - Parameters containing the row data.
 * @param {ColDef[]} columnDefinitions - Definitions of columns to sum up.
 * @returns {number | null} The sum of the column values, or null if no valid numbers are found.
 */
export const sumUpColumns = (params: ValueGetterParams, columnDefinitions: ColDef<any, any>[]): number | null => {
  try {
    // Check if params.data is available
    if (!params.data) {
      return null;
    }

    let sum = 0;
    let containsNumberOrZero = false; // Flag to track if any number or zero has been added to the sum.

    columnDefinitions.forEach((column: any) => {
      if (column.field && !column.field.toLowerCase().endsWith('total')) {
        const value = params.data[column.field];

        // Only process values that are not null or undefined
        if (value !== null && value !== undefined) {
          if (value === '0' || !isNaN(Number(value))) {
            containsNumberOrZero = true;
            sum += Number(value); // Convert to number to ensure correct addition
          }
        }
      }
    });

    // Return sum if any valid number or zero is present, otherwise return null
    return containsNumberOrZero ? sum : null;
  } catch (error) {
    return null;
  }
};

// Applies to Forecast & Actuals Report
export const getSuffixColumns = (): ColDef[] => {
  return [
    {
      field: XptReportGridFixedFields.UpdatedAt.value,
      headerName: XptReportGridFixedFields.UpdatedAt.displayName,
      wrapHeaderText: true,
      hide: true,
      width: ColumnWidths.LAST_UPDATED_AT,
      cellClass: ['text-field'],
      valueFormatter: (params: ValueFormatterParams) => (params.value ? formatISOToReadable(params.value) : ''),
      enableRowGroup: true,
      enablePivot: false,
      enableValue: false
    },
    {
      field: XptReportGridFixedFields.UpdatedBy.value,
      headerName: XptReportGridFixedFields.UpdatedBy.displayName,
      wrapHeaderText: true,
      hide: true,
      width: ColumnWidths.DEFAULT,
      cellClass: ['text-field'],
      cellDataType: CellDataType.TEXT,
      enableRowGroup: true,
      enablePivot: false,
      enableValue: false
    }
  ] as ColDef[];
};
