export const ACTUALS_REPORT_MESSAGES = {
  COST_CENTER: {
    ERROR: 'Cost Center loading failed',
    LOADING: 'Cost Centers loading',
    PLACEHOLDER: {
      EMPTY: 'No cost centers available',
      DEFAULT: 'Select a cost center'
    }
  },
  REPORT: {
    ACTUALS: {
      LOAD_ERROR: 'Unable to load Actuals Report.'
    },
    DISTRIBUTION: {
      LOAD_ERROR: 'Unable to load Distribution Report.',
      NO_ACTUALS_ERROR: 'Unable to generate Distribution Report without Actuals Data'
    }
  }
} as const;