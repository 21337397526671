import { Box, Button, Container, FormField, Select, SelectProps } from '@amzn/awsui-components-react';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Status } from 'src/models/AuthContextModels';
import { OptionDropdown } from 'src/models/ForecastModels';
import { RootState } from 'src/store/store';
import { ACTUALS_REPORT_MESSAGES } from './utils/ActualsMessageConstants';

interface XptActualsReportFilterSelectionProps {
  options: OptionDropdown[];
  selectedOption: SelectProps.Option | null;
  onChange: (option: SelectProps.Option) => void;
  status: Status;
  onDistributionDownload: () => void;
  distributionReportStatus: Status;
}

export const XptActualsReportFilterSelection = memo<XptActualsReportFilterSelectionProps>(
  ({ options, selectedOption, onChange, status, onDistributionDownload, distributionReportStatus }) => {
    const { userAccessForCurrentBusinessGroup } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore);
    const isFinanceAdminOrFinanceOwner = userAccessForCurrentBusinessGroup ? userAccessForCurrentBusinessGroup.isFinanceAdminOrFinanceOwner : false;

    return (
      <Container disableHeaderPaddings>
        <Box>
          <Box float="left" padding={{ bottom: 'm' }}>
            <FormField label="Cost Center" className="width-25-rem">
              <Select
                options={options}
                selectedOption={selectedOption}
                onChange={({ detail }) => onChange(detail.selectedOption)}
                expandToViewport
                filteringType="auto"
                errorText={status === Status.Failed ? ACTUALS_REPORT_MESSAGES.COST_CENTER.ERROR : undefined}
                loadingText={status === Status.Loading ? ACTUALS_REPORT_MESSAGES.COST_CENTER.LOADING : undefined}
                placeholder={options.length === 0 ? ACTUALS_REPORT_MESSAGES.COST_CENTER.PLACEHOLDER.EMPTY : ACTUALS_REPORT_MESSAGES.COST_CENTER.PLACEHOLDER.DEFAULT}
              />
            </FormField>
          </Box>

          {isFinanceAdminOrFinanceOwner && (
            <Box float="right">
              <Button
                iconName="download"
                onClick={onDistributionDownload}
                loading={distributionReportStatus === Status.Loading}
                disabled={distributionReportStatus === Status.Loading || !selectedOption}
              >
                Detailed PO Report
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    );
  }
);
