import { logger } from 'src/analytics/KatalLogger';
import { DistributionDataEntity, POTaggingEntity } from 'src/models/POTaggingModel';
import { DataType } from '../hooks/useS3DataFetcher';
import { calculateProcessingTime } from 'src/utils/date-time-utilities';

interface MatchedRecord extends DistributionDataEntity {
  xpt_line_item_id: number | null;
}

interface ProcessingMetrics {
  businessGroupName: string;
  dataClassificationShortDesc: string;
  dataClassificationId: number;
  selectedCC: string;
  totalActualRecordsCount: number;
  totalDistributionCount: number;
  totalMatchedRecords: number;
  /** Number of actuals records that couldn't be matched */
  totalUnmatchedActualsCount: number;
  /** Number of distribution records that couldn't be matched */
  totalUnmatchedRecords: number;
  timings: {
    mapCreation: number;
    dataMerging: number;
    total: number;
  };
}

export const REQUIRED_KEY_FIELDS = [
  'po_number',
  'po_line_number',
  'po_line_description',
  'period_name',
  'cost_center_code',
  'company_code',
  'location_code',
  'account_code',
  'product_code',
  'channel_code',
  'project_code',
  'je_category'
] as const;

export const verifyRequiredFields = (record: POTaggingEntity | DistributionDataEntity, recordType: DataType): void => {
  const missingFields = REQUIRED_KEY_FIELDS.filter((field) => !(field in record));

  if (missingFields.length > 0) {
    const errorMessage = `Missing required fields in ${recordType} record: ${missingFields.join(', ')}`;
    logger.error('Data validation failed', {
      recordType,
      missingFields,
      recordSample: JSON.stringify(record)
    });
    throw new Error(errorMessage);
  }
};

export const COMPOSITE_KEY_SEPARATOR = '|%%|';

export const generateCompositeKey = (record: POTaggingEntity | DistributionDataEntity, recordType: DataType): string => {
  // Verify all required fields exist
  verifyRequiredFields(record, recordType);

  // Generate composite key with verified fields
  return REQUIRED_KEY_FIELDS.map((field) => {
    const value = record[field as keyof typeof record];
    return value !== undefined && value !== null && value !== '' ? String(value).trim() : '-';
  }).join(COMPOSITE_KEY_SEPARATOR);
};

export const createDistributionMap = (
  distributionData: DistributionDataEntity[]
): { distributionMap: Map<string, DistributionDataEntity[]>; mapCreationTime: number } => {
  const startTime: number = performance.now();
  const distributionMap = new Map<string, DistributionDataEntity[]>();

  try {
    // Verify first record to fail fast if structure is incorrect
    if (distributionData.length > 0) {
      verifyRequiredFields(distributionData[0], 'Distribution');
    }

    distributionData.forEach((dist, index) => {
      try {
        const distKey = generateCompositeKey(dist, 'Distribution');
        if (!distributionMap.has(distKey)) {
          distributionMap.set(distKey, []);
        }
        distributionMap.get(distKey)?.push(dist);
      } catch (error) {
        logger.error('Error processing distribution record', {
          recordIndex: index,
          record: dist,
          error: error instanceof Error ? error.message : 'Unknown error'
        });
        throw error;
      }
    });

    return {
      distributionMap,
      mapCreationTime: performance.now() - startTime
    };
  } catch (error) {
    logger.error('Failed to create distribution map', {
      totalRecords: distributionData.length,
      error: error instanceof Error ? error.message : 'Unknown error'
    });
    throw error;
  }
};

export const logProcessingMetrics = (metrics: ProcessingMetrics, data: any[]) => {
  logger.info('Distribution report processing completed', {
    businessGroup: metrics.businessGroupName,
    dataClassification: metrics.dataClassificationShortDesc,
    costCenter: metrics.selectedCC,
    metrics: {
      actualsCount: metrics.totalActualRecordsCount,
      distributionCount: metrics.totalDistributionCount,
      matchedRecords: metrics.totalMatchedRecords,
      unmatchedDistribution: metrics.totalUnmatchedRecords,
      unmatchedActuals: metrics.totalUnmatchedActualsCount,
      finalReportRecords: data.length,
      matchRate: `${((metrics.totalMatchedRecords / metrics.totalDistributionCount) * 100).toFixed(2)}%`,
      performance: {
        mapCreationTimeInSeconds: (metrics.timings.mapCreation / 1000).toFixed(2),
        dataMergingTimeInSeconds: (metrics.timings.dataMerging / 1000).toFixed(2),
        totalProcessingTimeInSeconds: (metrics.timings.total / 1000).toFixed(2)
      }
    }
  });
};

/**
 * Processes Purchase Order (PO) actuals and distribution data to generate a downloadable report.
 *
 * This function performs the following operations:
 * 1. Validates the input data structure and required fields
 * 2. Creates a map of distribution records for efficient lookup
 * 3. Matches actuals records with their corresponding distribution records
 * 4. Generates an Excel report with the matched records
 *
 * The matching process:
 * - Creates a composite key using multiple fields (PO number, line number, description, etc.)
 * - Matches actuals records with distribution records using this composite key
 * - One actuals record can match with multiple distribution records
 * - Records performance metrics and matching statistics
 *
 * Error handling:
 * - Validates all required fields are present in both data sets
 * - Ensures sufficient distribution records are available
 * - Tracks and logs unmatched records for both actuals and distribution data
 * - Provides detailed error logging with context for debugging
 *
 * Performance tracking:
 * - Measures and logs time taken for each major operation
 * - Tracks memory usage through record counts
 * - Provides detailed metrics for analysis
 *
 * @param {POTaggingEntity[]} actualsData - Array of actuals records containing PO transactions
 * @param {DistributionDataEntity[]} distributionData - Array of distribution records to be matched
 * @param {string} businessGroupName - Name of the business group
 * @param {string} dataClassificationShortDesc - Short description of the data classification
 * @param {number} dataClassificationId - Unique identifier for the data classification
 * @param {string} selectedCC - Selected cost center code for filtering
 *
 * @throws {Error} If required fields are missing in any record
 * @throws {Error} If there are insufficient distribution records
 * @throws {Error} If there are errors during file generation
 *
 * @returns {Promise<void>} Resolves when the report is generated and downloaded
 *
 * @example
 * await processActualsAndPODataForDownload(
 *   actualsData,
 *   distributionData,
 *   'Business Group A',
 *   'ClassA',
 *   123,
 *   'CC001'
 * );
 */
export const processActualsAndPODataForDownload = async (
  actualsData: POTaggingEntity[],
  distributionData: DistributionDataEntity[],
  businessGroupName: string,
  dataClassificationShortDesc: string,
  dataClassificationId: number,
  selectedCC: string
): Promise<MatchedRecord[]> => {
  const startTime = performance.now();

  try {
    // Verify first actuals record to fail fast if structure is incorrect
    if (actualsData.length > 0) {
      verifyRequiredFields(actualsData[0], 'Actuals');
    }

    const metrics: ProcessingMetrics = {
      businessGroupName,
      dataClassificationShortDesc,
      dataClassificationId,
      selectedCC,
      totalActualRecordsCount: actualsData.length,
      totalDistributionCount: distributionData.length,
      totalMatchedRecords: 0,
      totalUnmatchedActualsCount: 0,
      totalUnmatchedRecords: 0,
      timings: { mapCreation: 0, dataMerging: 0, total: 0 }
    };

    if (distributionData.length < actualsData.length) {
      throw new Error('Insufficient distribution data records to match actuals data.');
    }

    logger.info('Starting Distribution report processing', {
      businessGroup: businessGroupName,
      dataClassification: dataClassificationShortDesc,
      costCenter: selectedCC,
      initialCounts: { actualsRecords: metrics.totalActualRecordsCount, distributionRecords: metrics.totalDistributionCount }
    });

    const { distributionMap, mapCreationTime } = createDistributionMap(distributionData);
    metrics.timings.mapCreation = mapCreationTime;

    const mergeStartTime = performance.now();
    const matchedRecords: MatchedRecord[] = [];
    const unmatchedActuals: POTaggingEntity[] = [];
    const processedDistKeys = new Set<string>();

    // Process data matching
    actualsData.forEach((actual, index) => {
      try {
        const actualsKey = generateCompositeKey(actual, 'Actuals');
        const matchingDist = distributionMap.get(actualsKey);

        if (matchingDist?.length) {
          matchingDist.forEach((dist) => {
            matchedRecords.push({ ...dist, xpt_line_item_id: actual.xpt_line_item_id });
          });
          metrics.totalMatchedRecords += matchingDist.length;
          processedDistKeys.add(actualsKey);
        } else {
          unmatchedActuals.push(actual);
        }
      } catch (error) {
        logger.error('Error processing actuals record', {
          recordIndex: index,
          record: actual,
          error: error instanceof Error ? error.message : 'Unknown error'
        });
        throw error;
      }
    });

    // Calculate unmatched distribution records
    metrics.totalUnmatchedRecords = distributionData.filter((dist) => !processedDistKeys.has(generateCompositeKey(dist, 'Distribution'))).length;

    metrics.totalUnmatchedActualsCount = unmatchedActuals.length;
    const data = matchedRecords;

    metrics.timings.dataMerging = performance.now() - mergeStartTime;
    metrics.timings.total = performance.now() - startTime;

    // Update the metrics logging section:
    // TODO: Keep this console logs until ready for prod.
    console.log(`\n=== Processing Metrics for Distribution Report - ${dataClassificationShortDesc} - ${selectedCC} ===`);
    console.log(`Data Volumes:`);
    console.log(`- Actuals Records: ${metrics.totalActualRecordsCount}`);
    console.log(`- Distribution Records: ${metrics.totalDistributionCount}`);
    console.log(`- Matched Records: ${metrics.totalMatchedRecords}`);
    console.log(`- Unmatched Distribution Records: ${metrics.totalUnmatchedRecords}`);
    console.log(`- Unmatched Actuals Records: ${metrics.totalUnmatchedActualsCount}`);
    console.log(`- Final Report Records: ${data.length}`);
    console.log(`\n=== Performance Metrics:`);
    console.log(`- Map Creation: ${metrics.timings.mapCreation.toFixed(2)}ms`);
    console.log(`- Data Merging: ${metrics.timings.dataMerging.toFixed(2)}ms`);
    console.log(`- Total Processing: ${metrics.timings.total.toFixed(2)}ms`);
    console.log(`- Total Processing in seconds: ${(metrics.timings.total / 1000).toFixed(2)} sec`);

    logProcessingMetrics(metrics, data);

    return data;
  } catch (error: any) {
    logger.error('Error processing Distribution report', {
      businessGroup: businessGroupName,
      dataClassification: dataClassificationShortDesc,
      costCenter: selectedCC,
      error: error.message,
      processingTimeInSeconds: calculateProcessingTime(startTime)
    });
    throw error;
  }
};
