import { Box, FlashbarProps, SelectProps } from '@amzn/awsui-components-react';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  currentBusinessGroupName,
  currentBusinessGroupShortDesc,
  currentDataClassificationId
} from 'src/features/business-group/businessGroupSelectors';
import { Status } from 'src/models/AuthContextModels';
import { getActualsS3BucketName } from 'src/utils/xpt-s3-bucket-details';
import { ReportGrid } from '../XptReportGrid';
import { useActualsReport } from './hooks/useActualsReport';
import { useDistributionReport } from './hooks/useDistributionReport';
import { ACTUALS_REPORT_GID_COLUMN_DEFINITION } from './utils/XptActualsReportGridColumnHelper';
import { ErrorAlertWithTicketLink } from 'src/components/common/ErrorAlertWithTicketLink';
import { ACTUALS_REPORT_MESSAGES } from './utils/ActualsMessageConstants';

interface XptActualsReportGridProps {
  selectedCostCenter: SelectProps.Option | null;
  notifyFlashMessage: (content: string, flashBarType: FlashbarProps.Type, isDismissible: boolean, messageId?: string) => void;
  clearSpecificFlashMessage: (messageId: string) => void;
  onDistributionDownloadRef: React.MutableRefObject<() => void>;
  setDistributionReportStatus: (status: Status) => void;
}

export const XptActualsReportGrid: React.FC<XptActualsReportGridProps> = ({
  selectedCostCenter,
  notifyFlashMessage,
  clearSpecificFlashMessage,
  onDistributionDownloadRef,
  setDistributionReportStatus
}) => {
  // Selectors
  const dataClassificationId = useSelector(currentDataClassificationId);
  const dataClassificationShortDesc = useSelector(currentBusinessGroupShortDesc);
  const businessGroupName = useSelector(currentBusinessGroupName);
  const bucketName = getActualsS3BucketName().bucketName;

  // Grid state
  const gridStateKey = `UniqueGridStateKey-XptActualsReport-${dataClassificationShortDesc}-v2`;
  const gridRef = useRef<AgGridReact>(null);
  const [actualsColDefinitions, setActualsColDefinitions] = useState<ColDef[]>([]);

  // Custom hooks
  const { s3KeysFetchStatus, distributionDataS3Keys, actualsDataStatus, actualsData, initializeS3Keys, fetchActualsData, exportFileDetails } =
    useActualsReport({
      dataClassificationId,
      dataClassificationShortDesc,
      bucketName,
      selectedCostCenter,
      notifyFlashMessage
    });

  const { handleDistributionDownload, distributionReportStatus } = useDistributionReport({
    selectedCostCenter,
    distributionDataS3Keys,
    actualsData,
    actualsDataStatus,
    businessGroupName,
    dataClassificationShortDesc,
    dataClassificationId,
    notifyFlashMessage,
    clearSpecificFlashMessage
  });

  // Will update the ref whenever handleDistributionDownload changes
  useEffect(() => {
    onDistributionDownloadRef.current = handleDistributionDownload;
  }, [handleDistributionDownload, onDistributionDownloadRef]);

  useEffect(() => {
    setDistributionReportStatus(distributionReportStatus);
  }, [distributionReportStatus, setDistributionReportStatus]);

  // Effects
  useEffect(() => {
    setActualsColDefinitions(ACTUALS_REPORT_GID_COLUMN_DEFINITION());
  }, []);

  useEffect(() => {
    initializeS3Keys();
  }, [initializeS3Keys]);

  useEffect(() => {
    fetchActualsData();
  }, [fetchActualsData]);

  // Error state
  if (actualsDataStatus === Status.Failed) {
    return (
      <Box padding={{ top: 'm' }}>
        <ErrorAlertWithTicketLink message={`${ACTUALS_REPORT_MESSAGES.REPORT.ACTUALS.LOAD_ERROR}`} />
      </Box>
    );
  }

  return (
    <ReportGrid
      gridRef={gridRef}
      columnDefs={actualsColDefinitions}
      rowData={actualsData}
      uniqueIdField="actuals_item_id"
      isLoading={actualsDataStatus === Status.Loading || s3KeysFetchStatus === Status.Loading}
      localStorageKey={gridStateKey}
      refreshReport={fetchActualsData}
      exportFileDetails={exportFileDetails}
      notificationMessage={notifyFlashMessage}
      showPivotModeOptionInPanel={true}
      enablePivotMode={false}
      page="Actuals Report"
      mainPage={businessGroupName}
    />
  );
};
