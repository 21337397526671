import { ContentLayout, Flashbar, Header, Link } from '@amzn/awsui-components-react';
import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { XptAppLayout } from 'src/components/common/xpt-app-layout/XptAppLayout';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import {
  businessGroupBaseBreadcrumbs,
  currentBusinessGroupName,
  currentBusinessGroupShortDesc
} from 'src/features/business-group/businessGroupSelectors';
import BusinessGroupSideNavigation from 'src/features/xpt-layout/XptBusinessGroupSideNavigation';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { Status } from 'src/models/AuthContextModels';
import { useCostCenterManagement } from './hooks/useCostCenterManagement';
import { getXptActualsReportsBreadcrumbItems } from './utils/XptActualsReportGridColumnHelper';
import { XptActualsReportFilterSelection } from './XptActualsReportFilterSelection';
import { XptActualsReportGrid } from './XptActualsReportGrid';
import { XptActualsReportInfoPanel } from './XptActualsReportInfoPanel';

export const XptActualsReport: React.FC = () => {
  const appLayout = useRef<any>();
  const { flashbarItems, displayFlashMessage, clearSpecificFlashMessage } = useFlashbar();
  const [toolsOpen, setToolsOpen] = useState(false);

  const businessGroupBaseBreadcrumb = useSelector(businessGroupBaseBreadcrumbs);
  const businessGroupName = useSelector(currentBusinessGroupName);
  const businessGroupShortDesc = useSelector(currentBusinessGroupShortDesc);

  // distribution report status
  const [distributionReportStatus, setDistributionReportStatus] = useState<Status>(Status.Loading);
  const handleDistributionDownloadRef = useRef<() => void>(() => {});

  const { costCenterOptions, selectedCostCenter, handleCostCenterChange, masterCorpSegmentDropdownValuesStatus } = useCostCenterManagement(
    businessGroupName,
    businessGroupShortDesc
  );

  const handleToolsChange = useCallback(({ detail }: { detail: { open: boolean } }) => {
    setToolsOpen(detail.open);
  }, []);

  const XptActualsReportHeader = () => (
    <Header
      info={
        <Link variant="info" onFollow={() => setToolsOpen(true)}>
          Info
        </Link>
      }
    >
      Actuals Report
    </Header>
  );

  return (
    <XptAppLayout
      ref={appLayout}
      headerSelector="#h"
      navigation={<BusinessGroupSideNavigation />}
      tools={<XptActualsReportInfoPanel />}
      toolsOpen={toolsOpen}
      onToolsChange={handleToolsChange}
      breadcrumbs={<XPTBreadcrumbs items={getXptActualsReportsBreadcrumbItems(businessGroupBaseBreadcrumb, businessGroupName)} />}
      notifications={<Flashbar stackItems items={flashbarItems} />}
      stickyNotifications={true}
      maxContentWidth={Number.MAX_VALUE}
      contentType="default"
      content={
        <ContentLayout disableOverlap header={<XptActualsReportHeader />}>
          <XptActualsReportFilterSelection
            options={costCenterOptions}
            selectedOption={selectedCostCenter}
            onChange={handleCostCenterChange}
            status={masterCorpSegmentDropdownValuesStatus}
            onDistributionDownload={() => handleDistributionDownloadRef.current()}
            distributionReportStatus={distributionReportStatus}
          />
          <XptActualsReportGrid
            selectedCostCenter={selectedCostCenter}
            notifyFlashMessage={displayFlashMessage}
            clearSpecificFlashMessage={clearSpecificFlashMessage}
            onDistributionDownloadRef={handleDistributionDownloadRef}
            setDistributionReportStatus={setDistributionReportStatus}
          />
        </ContentLayout>
      }
    />
  );
};
