import { SelectProps } from '@amzn/awsui-components-react';
import { useCallback, useEffect, useState } from 'react';
import { XptReportExportFileDetails } from 'src/models/XptReportingModels';

interface UseExportFileDetailsProps {
  dataClassificationShortDesc: string;
  selectedCostCenter: SelectProps.Option | null;
}

export const useExportFileDetails = ({ dataClassificationShortDesc, selectedCostCenter }: UseExportFileDetailsProps) => {
  const [exportFileDetails, setExportFileDetails] = useState<XptReportExportFileDetails>();

  const updateFileDetails = useCallback(() => {
    const fileDetails: XptReportExportFileDetails = {
      fileName: `Actuals_Report_${dataClassificationShortDesc}_${selectedCostCenter?.label}`,
      sheetName: `Actuals_Report_${dataClassificationShortDesc}_${selectedCostCenter?.label}`
    };
    setExportFileDetails(fileDetails);
  }, [dataClassificationShortDesc, selectedCostCenter]);

  useEffect(() => {
    if (selectedCostCenter) {
      updateFileDetails();
    }
  }, [selectedCostCenter, updateFileDetails]);

  return { exportFileDetails };
};
