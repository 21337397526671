import { FlashbarProps } from '@amzn/awsui-components-react';
import { useCallback } from 'react';
import { logger } from 'src/analytics/KatalLogger';
import { eErrorMessages } from 'src/constants/generic-constants';
import { fetchAndCombineS3Data } from 'src/utils/aws-s3-multi-part-request';
import { calculateProcessingTime, convertUTCtoLocalISO } from 'src/utils/date-time-utilities';

interface UseS3DataFetcherProps {
  bucketName: string;
  notifyFlashMessage: (content: string, type: FlashbarProps.Type, isDismissible: boolean) => void;
}

export type DataType = 'Actuals' | 'Distribution';
const MAX_COMBINED_RECORDS = 70000;

export interface FetchDataParams {
  selectedCC: string;
  s3Keys: string[];
  dataType: DataType;
}

export const useS3DataFetcher = ({ bucketName, notifyFlashMessage }: UseS3DataFetcherProps) => {
  const fetchDataFromS3 = useCallback(
    async ({ selectedCC, s3Keys, dataType }: FetchDataParams): Promise<any[]> => {
      const startTime = performance.now();

      if (s3Keys.length === 0) {
        const message = `No ${dataType} data available for Cost Center '${selectedCC}', ${eErrorMessages.GENERIC_ERROR_WITH_TICKET_INFO}`;
        logger.warn(message);
        notifyFlashMessage(message, 'error', true);
        return [];
      }

      try {
        logger.info(`Started fetching ${dataType} Data for Cost Center '${selectedCC}' from S3`);
        const { combinedData, results } = await fetchAndCombineS3Data(bucketName, s3Keys);
        const combinedDataParsedWithTimeStamp = combinedData.map((item) => ({
          ...item,
          updated_at: item.updated_at ? convertUTCtoLocalISO(item.updated_at) : ''
        }));

        const hasError = results.some((result) => !result.data);
        if (hasError) {
          const message = `Failed to fetch complete ${dataType} Data for Cost Center '${selectedCC}'. Some months might be missing.`;
          logger.error(message);
          notifyFlashMessage(message, 'error', true);
        }

        if (combinedDataParsedWithTimeStamp.length > MAX_COMBINED_RECORDS) {
          logger.warn(`Total combined ${dataType} data for Cost Center '${selectedCC}' is greater than 70,000 records`);
        }

        const processingTimeInSeconds = calculateProcessingTime(startTime);

        logger.info(
          `Completed fetching ${dataType} data from ${s3Keys.length} keys for Cost Center '${selectedCC}' in ${processingTimeInSeconds} sec(s):`,
          {
            recordCount: combinedDataParsedWithTimeStamp.length,
            processingTimeInSeconds,
            s3KeysProcessed: s3Keys.length,
            averageTimePerKey: (Number(processingTimeInSeconds) / s3Keys.length).toFixed(2)
          }
        );

        return combinedDataParsedWithTimeStamp;
      } catch (error: any) {
        const processingTimeInSeconds = calculateProcessingTime(startTime);

        const message = `Unable to load ${dataType} Data for Cost Center '${selectedCC}'. Contact support for assistance.`;
        logger.error(`Error fetching and combining ${dataType} S3 data:`, {
          error,
          processingTimeInSeconds,
          s3KeysAttempted: s3Keys.length
        });
        notifyFlashMessage(message, 'error', true);
        return [];
      }
    },
    [bucketName, notifyFlashMessage]
  );

  return { fetchDataFromS3 };
};
