import { SelectProps } from '@amzn/awsui-components-react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { logUserClick } from 'src/analytics/KatalLogger';
import { CorpSegmentNames } from 'src/constants/corp-segment-constants';
import { getUserCostCenters } from 'src/features/business-group/access-authorization/redux/AccessAuthorizationUtils';
import { getLocalStorageItem, setLocalStorageItem } from 'src/hooks/useLocalStorage';
import { Status } from 'src/models/AuthContextModels';
import { OptionDropdown } from 'src/models/ForecastModels';
import { RootState } from 'src/store/store';

export const useCostCenterManagement = (businessGroupName: string, businessGroupShortDesc: string) => {
  const [costCenterOptions, setCostCenterOptions] = useState<OptionDropdown[]>([]);
  const [selectedCostCenter, setSelectedCostCenter] = useState<SelectProps.Option | null>(null);

  const { userAccessForCurrentBusinessGroup, userAccessForCurrentBusinessGroupStatus } = useSelector(
    (state: RootState) => state.xptAccessAndAuthorizationStore
  )!;

  const { masterCorpSegmentDropdownValues, masterCorpSegmentDropdownValuesStatus } = useSelector((state: RootState) => state.corpSegmentsStore);

  const localStoragePrefix = `actuals_report_filters`;
  const actualsFilterCostCenterKey = `${localStoragePrefix}_${businessGroupShortDesc}_cc`;

  const initializeCostCenters = useCallback(() => {
    if (
      businessGroupShortDesc &&
      userAccessForCurrentBusinessGroupStatus === Status.Completed &&
      masterCorpSegmentDropdownValuesStatus === Status.Completed &&
      userAccessForCurrentBusinessGroup
    ) {
      const userCCs = getUserCostCenters(userAccessForCurrentBusinessGroup);
      const masterCostCenterDropdowns = masterCorpSegmentDropdownValues.find(
        (corpSegment) => corpSegment.masterCorpSegmentDisplayName === CorpSegmentNames.COST_CENTER
      );
      const authorizedCostCenterDropdowns =
        masterCostCenterDropdowns?.masterCorpSegmentDropdownValues.filter((segmentDropdown) => userCCs.includes(segmentDropdown.value)) || [];

      setCostCenterOptions(authorizedCostCenterDropdowns);
      return authorizedCostCenterDropdowns;
    }
    return [];
  }, [
    businessGroupShortDesc,
    userAccessForCurrentBusinessGroupStatus,
    masterCorpSegmentDropdownValuesStatus,
    userAccessForCurrentBusinessGroup,
    masterCorpSegmentDropdownValues
  ]);

  const initializeSelectedCostCenter = useCallback(
    (authorizedCostCenterDropdowns: SelectProps.Options) => {
      const storedCostCenter = getLocalStorageItem<SelectProps.Option | null>(actualsFilterCostCenterKey);
      if (storedCostCenter && authorizedCostCenterDropdowns.some((cc) => cc.value === storedCostCenter.value)) {
        return storedCostCenter;
      } else if (authorizedCostCenterDropdowns.length > 0) {
        const newSelectedCostCenter = authorizedCostCenterDropdowns[0];
        setLocalStorageItem(actualsFilterCostCenterKey, newSelectedCostCenter);
        return newSelectedCostCenter;
      }
      return null;
    },
    [actualsFilterCostCenterKey]
  );

  useEffect(() => {
    const initializeData = async () => {
      const authorizedCostCenterDropdowns = initializeCostCenters();
      const initialSelectedCostCenter = initializeSelectedCostCenter(authorizedCostCenterDropdowns);
      setCostCenterOptions(authorizedCostCenterDropdowns);
      setSelectedCostCenter(initialSelectedCostCenter);
    };

    initializeData();
  }, [initializeCostCenters, initializeSelectedCostCenter]);

  const handleCostCenterChange = useCallback(
    (selectedOption: SelectProps.Option) => {
      logUserClick(`Cost Center filter change`, 'Actuals Report', businessGroupName);
      setSelectedCostCenter(selectedOption);
      setLocalStorageItem(actualsFilterCostCenterKey, selectedOption);
    },
    [actualsFilterCostCenterKey, businessGroupName]
  );

  return {
    costCenterOptions,
    selectedCostCenter,
    handleCostCenterChange,
    masterCorpSegmentDropdownValuesStatus
  };
};
