import { BreadcrumbGroupProps } from '@amzn/awsui-components-react';
import { CellClassParams, ColDef } from 'ag-grid-community';
import { logger } from 'src/analytics/KatalLogger';
import ENVIRONMENT_VARIABLES from 'src/constants/environment-variables';
import { ValidationErrorDetail, ValidationStatusEntity } from 'src/models/XptGenericModels';
import { ProjectData, ProjectDetailsInput, ProjectMappingEntity } from 'src/models/xPTMappingModels';
import { CellDataType } from 'src/utils/ag-grid-utils';
import { compareObjects, filterObjectFields } from 'src/utils/comparison-utils';
import { getForecastS3BucketName, getOnboardSegmentBucketName } from 'src/utils/xpt-s3-bucket-details';
import { AdminBaseBreadcrumbs } from '../AdminConsole';
import { eAgGridThemes } from 'src/constants/generic-constants';

/**
 * Returns breadcrumb items for project mapping.
 * @returns {BreadcrumbGroupProps.Item[]} - Array of breadcrumb items.
 */
export const getProjectBreadcrumbItems = (): BreadcrumbGroupProps.Item[] => {
  return [
    ...AdminBaseBreadcrumbs,
    {
      text: 'Project',
      href: '/admin-console/project'
    }
  ];
};

/**
 * Default column definition for the project mapping table.
 */
export const defaultProjectColDef: ColDef = {
  resizable: true,
  sortable: true,
  autoHeaderHeight: true,
  filter: true,
  floatingFilter: false,
  filterParams: {
    applyMiniFilterWhileTyping: true
  },
  headerClass: 'header-center',
  editable: false,
  enableRowGroup: false
};

/**
 * Column definitions for the project mapping table.
 */
export const projectMappingColumnDefinition = (themeClassName: eAgGridThemes): ColDef[] => {
  return [
    {
      headerName: 'Row Id',
      field: 'row_id',
      hide: true,
      suppressColumnsToolPanel: true
    },
    {
      headerName: 'Project Code',
      field: 'project_code',
      cellClass: 'cell-center',
      minWidth: 100,
      sortable: true,
      filter: true,
      editable: false
    },
    {
      headerName: 'Project Description',
      field: 'project_description',
      minWidth: 500,
      editable: true,
      cellDataType: CellDataType.TEXT,
      cellClass: ['text-field', 'editable-cell'],
      cellClassRules: {
        'text-field': () => true,
        'editable-cell': (params: CellClassParams) => themeClassName === eAgGridThemes.Quartz,
        'editable-cell-dark-theme': (params: CellClassParams) => themeClassName !== eAgGridThemes.Quartz
      }
    }
  ];
};

/**
 * Filters out the 'is_modified' field from a project mapping entity.
 * @param {ProjectMappingEntity} row - The project mapping entity.
 * @returns {Partial<ProjectMappingEntity>} - Filtered entity.
 */
const filterProjectMappingFields = (row: ProjectMappingEntity): Partial<ProjectMappingEntity> => {
  return filterObjectFields(row, ['is_modified']);
};

/**
 * Determines if a project mapping row has been modified.
 * @param {ProjectMappingEntity} originalRow - The original project mapping entity.
 * @param {ProjectMappingEntity} newRow - The new project mapping entity.
 * @returns {boolean} - True if the row has been modified, false otherwise.
 */
export const isProjectTypeRowModified = (originalRow: ProjectMappingEntity, newRow: ProjectMappingEntity): boolean => {
  const filteredOriginalRow = filterProjectMappingFields(originalRow);
  const filteredNewData = filterProjectMappingFields(newRow);

  return !compareObjects(filteredOriginalRow, filteredNewData);
};

/**
 * Validates a list of project mapping entities.
 * @param {ProjectMappingEntity[]} projectMappingData - The project mapping data to validate.
 * @returns {Promise<ValidationStatusEntity[]>} - The validation status.
 */
export const validateProjectMapping = async (projectMappingData: ProjectMappingEntity[]): Promise<ValidationStatusEntity[]> => {
  const validations: ValidationStatusEntity[] = [];
  validations.push(await mandatoryFieldsValidator(projectMappingData));
  return validations;
};

/**
 * Messages used for validation and submission feedback.
 */
export const ProjectMappingValidationMessages = {
  MANDATORY_FIELDS_DEFAULT_MESSAGE: 'Project description is required.',
  MANDATORY_FIELDS_VALIDATION_FAILED: 'Validation failed: Required fields are missing or exceed character limit.',
  MANDATORY_FIELDS_VALIDATION_SUCCESS: 'Validation passed: All required fields are present.',
  SUBMISSION_IN_PROGRESS: 'Submitting project mapping data...',
  SUBMISSION_SUCCESS: (count: number) => `Submission successful: ${count} record${count !== 1 ? 's' : ''} updated.`,
  SUBMISSION_FAILED: 'Submission failed: Unable to submit the request.'
};

/**
 * Validates mandatory fields for project mapping entities.
 * @param {ProjectMappingEntity[]} projectMappingData - The project mapping data to validate.
 * @returns {Promise<ValidationStatusEntity>} - The validation status.
 */
export const mandatoryFieldsValidator = async (projectMappingData: ProjectMappingEntity[]): Promise<ValidationStatusEntity> => {
  const validationErrorDetails: ValidationErrorDetail[] = [];

  projectMappingData.forEach((item, index) => {
    if (!item.project_description || item.project_description.trim() === '') {
      validationErrorDetails.push({
        rowIndex: index,
        message: 'Project description is required.'
      });
    } else if (item.project_description.length > 250) {
      validationErrorDetails.push({
        rowIndex: index,
        message: 'Project description exceeds the maximum length of 250 characters.'
      });
    }
  });

  if (validationErrorDetails.length > 0) {
    logger.debug('Mandatory fields validation failed.' + JSON.stringify(validationErrorDetails, null, 2));
    return {
      colorOverride: 'red',
      validationStatus: 'error',
      validationMessage: ProjectMappingValidationMessages.MANDATORY_FIELDS_VALIDATION_FAILED,
      validationDefaultMessage: ProjectMappingValidationMessages.MANDATORY_FIELDS_DEFAULT_MESSAGE,
      validationErrorDetails
    };
  }

  return {
    colorOverride: 'green',
    validationStatus: 'success',
    validationMessage: ProjectMappingValidationMessages.MANDATORY_FIELDS_VALIDATION_SUCCESS,
    validationDefaultMessage: ProjectMappingValidationMessages.MANDATORY_FIELDS_DEFAULT_MESSAGE,
    validationErrorDetails: []
  };
};

/**
 * Converts modified project mapping data into a format suitable for mutation.
 * @param {ProjectMappingEntity[]} projectMappingModifiedData - Array of modified project mapping data.
 * @param {string} userAlias - The alias of the user making the modifications.
 * @returns {ProjectDetailsInput} - project details input suitable for mutation.
 */
export const convertToProjectMutation = (projectMappingModifiedData: ProjectMappingEntity[], userAlias: string): ProjectDetailsInput => {
  // Map project mapping data to project mutation data
  const projectMutationData: ProjectData[] = projectMappingModifiedData.map((data) => ({
    child_id: data.project_code,
    child_name: data.project_description
  }));

  // Retrieve necessary environment variables
  const s3Bucket = getOnboardSegmentBucketName();
  const region = ENVIRONMENT_VARIABLES.env.Region;
  const updated_by = userAlias;

  // Construct the project details input
  const projectDetailsInput: ProjectDetailsInput = {
    data: projectMutationData,
    item_metadata: {
      s3_bucket_name: s3Bucket,
      s3_region: region,
      updated_by,
      forecast_s3_bucket: getForecastS3BucketName().bucketName
    }
  };

  return projectDetailsInput;
};
