import { BreadcrumbGroupProps } from '@amzn/awsui-components-react';
import { CellClassParams, ColDef, ValueFormatterParams, ValueParserParams } from 'ag-grid-community';
import { cellValueParser, customMonthPivotComparator, poTaggingCurrencyFormatter } from 'src/utils/ag-grid-utils';
import { getSuffixColumns } from '../../XptReportColumnGenerator';

// Constants
const DEFAULT_COLUMN_CONFIG = {
  hide: false,
  enableRowGroup: true,
  enablePivot: true,
  enableValue: false,
  wrapHeaderText: true
};

const CURRENCY_COLUMN_CONFIG = {
  ...DEFAULT_COLUMN_CONFIG,
  valueParser: (params: ValueParserParams) => cellValueParser(params.newValue),
  valueFormatter: (params: ValueFormatterParams) => poTaggingCurrencyFormatter(params.value),
  cellClassRules: {
    'bold-text': (params: CellClassParams) => params.node.footer || false
  }
};

// Breadcrumb Functions
export const getXptActualsReportsBreadcrumbItems = (
  businessGroupBaseBreadcrumbs: BreadcrumbGroupProps.Item[],
  currentBusinessGroupName?: string
): BreadcrumbGroupProps.Item[] =>
  currentBusinessGroupName
    ? [
        ...businessGroupBaseBreadcrumbs,
        {
          text: 'Actuals Report',
          href: `/${currentBusinessGroupName}/actuals-report`
        }
      ]
    : businessGroupBaseBreadcrumbs;

// Filter Functions
export const filterS3KeysBasedOnCC = (strings: string[], substrings: string[]): string[] =>
  strings.filter((str) => substrings.some((substring) => str.toLowerCase().includes(substring.toLowerCase())));

// Column Definitions
const createBaseColumn = (headerName: string, field: string, width?: number): ColDef => ({
  ...DEFAULT_COLUMN_CONFIG,
  headerName,
  field,
  width,
  minWidth: width
});

const createCurrencyColumn = (headerName: string, field: string, width: number, options = {}): ColDef => ({
  ...CURRENCY_COLUMN_CONFIG,
  headerName,
  field,
  width,
  minWidth: width,
  ...options
});

const createDescriptionColumn = (prefix: string): ColDef[] => [
  createBaseColumn(`${prefix} Code`, `${prefix.toLowerCase()}_code`, 120),
  createBaseColumn(`${prefix} Description`, `${prefix.toLowerCase()}_description`, 170)
];

export const ACTUALS_REPORT_GID_COLUMN_DEFINITION = (): ColDef[] => [
  {
    headerName: 'Actual Item ID',
    field: 'actuals_item_id',
    hide: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true
  },
  {
    ...createBaseColumn('PO #', 'po_number', 130),
    sort: 'asc',
    sortIndex: 0
  },
  {
    ...createBaseColumn('PO Line #', 'po_line_number', 130),
    sort: 'asc',
    sortIndex: 1
  },
  createBaseColumn('Line Item ID', 'xpt_line_item_id', 155),
  {
    ...createBaseColumn('Month', 'period_name', 120),
    pivot: true,
    pivotComparator: customMonthPivotComparator,
    comparator: (valueA: string, valueB: string) => customMonthPivotComparator(valueA, valueB)
  },
  createCurrencyColumn('Monthly Actual Spent (USD)', 'entered_amount_usd', 170, {
    aggFunc: 'sum',
    enableValue: true,
    enableRowGroup: false,
    enablePivot: false
  }),
  createCurrencyColumn('PO Line Approved Amount (USD)', 'po_line_approved_amt_usd', 150, {
    enableValue: false,
    enablePivot: false
  }),
  createCurrencyColumn('PO Header Approved Amount (USD)', 'po_header_approved_amt_usd', 150, {
    enableValue: false,
    enablePivot: false
  }),
  createBaseColumn('PO Need By Date', 'po_need_by_date', 130),
  createBaseColumn('PO Requestor', 'po_requestor', 130),
  {
    ...createBaseColumn('Line Description', 'po_line_description', 150),
    hide: true
  },
  createBaseColumn('JE Category', 'je_category'),
  createBaseColumn('Status', 'status', 120),
  ...createDescriptionColumn('Cost Center'),
  ...createDescriptionColumn('Account'),
  ...createDescriptionColumn('Product'),
  ...createDescriptionColumn('Project'),
  ...createDescriptionColumn('Channel'),
  ...createDescriptionColumn('Company'),
  ...createDescriptionColumn('Location'),
  ...getSuffixColumns()
];
