import { Alert, Box, Button, Form, FormField, Header, Modal, Multiselect, Select, SpaceBetween } from '@amzn/awsui-components-react';
import { FieldArray, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { useAuth } from 'src/app/auth/AuthContextProvider';
import { ErrorFallback } from 'src/components/common/ErrorFallback';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { COST_CENTER_SEGMENT_NAME } from 'src/constants/corp-segment-constants';
import { eLocalStorageKeys, SELECT_ALL_OPTION, USER_TYPES } from 'src/constants/generic-constants';
import { Status, UserAuthContext } from 'src/models/AuthContextModels';
import { CorpSegmentAccess, CurrentUserAccessDetails } from 'src/models/XptUsersModel';
import { RootState } from 'src/store/store';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { arrayOfStringsToSelectDropdownOptions, compareNullableNumbers, getMultiSelectPlaceHolderValue } from 'src/utils/generic-utilities';

export interface ModifyUserAccessProps {
  showModal: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ModifyUserAccess: React.FC<ModifyUserAccessProps> = ({ showModal, onCancel, onConfirm }) => {
  const { updateUserAuthDetails, resetToDefaultAuthDetails } = useAuth();

  const dispatch = useAppDispatch();
  const { currentUserAccessDetails, currentUserAccessLoadingStatus } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore);
  const { businessGroups, businessGroupLoadingStatus } = useSelector((state: RootState) => state.businessGroupStore);
  const { masterCostCenterDropdownValuesForAllBusinessGroups } = useSelector((state: RootState) => state.corpSegmentsStore);

  const [formInitializing, setFormInitializing] = useState<boolean>(true);
  const [userAccessForm, setUserAccessForm] = useState<CurrentUserAccessDetails>({} as CurrentUserAccessDetails);
  const userAccessFormRef = useRef<FormikProps<CurrentUserAccessDetails>>(null);

  useEffect(() => {
    if (showModal) {
      userAccessFormRef?.current?.resetForm();
    }
  }, [showModal]);

  useEffect(() => {
    if (businessGroupLoadingStatus === Status.Completed && currentUserAccessLoadingStatus === Status.Completed && currentUserAccessDetails) {
      setFormInitializing(true);

      const activeDataClassificationsIDs = businessGroups
        .filter((bg) => bg.item_metadata.is_active)
        .map((dt) => dt.data_classification.data_classification_id!);

      const currentUserAccess: CurrentUserAccessDetails = {
        ...currentUserAccessDetails,
        user_data_classifications: currentUserAccessDetails.user_data_classifications.filter((dt) =>
          activeDataClassificationsIDs.includes(dt.data_classification_id)
        )
      };
      setUserAccessForm(currentUserAccess);

      setFormInitializing(false);
    }
  }, [businessGroups, businessGroupLoadingStatus, currentUserAccessLoadingStatus, currentUserAccessDetails]);

  const getBusinessGroupName = (dataClassificationId: number) => {
    const businessGroupName =
      businessGroups.find((bg) => bg.data_classification.data_classification_id === dataClassificationId)?.data_classification
        .data_classification_name || '';
    return businessGroupName;
  };

  const getUserTypes = () => {
    const userTypeOptions = USER_TYPES.map((userType) => ({ label: userType, value: userType }));
    return userTypeOptions;
  };

  const getCostCentersOfBusinessGroup = (dataClassificationId: number) => {
    const costCenterOptions: string[] =
      masterCostCenterDropdownValuesForAllBusinessGroups.find((cc) => cc.dataClassificationId === dataClassificationId)?.costCenters || [];
    return arrayOfStringsToSelectDropdownOptions(costCenterOptions);
  };

  const getSelectedCCOptions = (corpSegmentAccess: CorpSegmentAccess[]) => {
    const selectedCostCenters: string[] = corpSegmentAccess.find((cc) => cc.segment_name === COST_CENTER_SEGMENT_NAME)?.segment_value || [];
    return arrayOfStringsToSelectDropdownOptions(selectedCostCenters);
  };

  const handleReset = () => {
    localStorage.removeItem(eLocalStorageKeys.UserAccessDetails);
    resetToDefaultAuthDetails();
    onCancel();
    window.location.reload();
  };

  const handleSubmit = async (formValues: CurrentUserAccessDetails, formikHelpers: FormikHelpers<CurrentUserAccessDetails>) => {
    try {
      const userAccessDetailsToStoreInLocal: CurrentUserAccessDetails = {
        ...formValues,
        user_access_modified: true
      };
      localStorage.setItem(eLocalStorageKeys.UserAccessDetails, JSON.stringify(userAccessDetailsToStoreInLocal));
      const updates: Partial<UserAuthContext> = {
        isDev: false
      };
      updateUserAuthDetails(updates, true);
      onCancel();
      window.location.reload();
    } catch (error: any) {
      console.error('Error while storing local storage ', error);
    }
  };

  return (
    <Modal
      data-testid="modify-auth-context"
      onDismiss={onCancel}
      visible={showModal}
      size="large"
      header={<Header description="You can modify access permissions to test different application behaviors.">{'Modify Your Access'}</Header>}
    >
      <div className="modify-access-modal-content">
        {formInitializing && <LoadingSpinner />}
        {!formInitializing && (
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Formik<CurrentUserAccessDetails>
              innerRef={userAccessFormRef}
              enableReinitialize={true}
              initialValues={userAccessForm}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={handleSubmit}
            >
              {({ errors, handleBlur, handleSubmit, touched, dirty, isValid, setFieldTouched, values, setFieldValue, isSubmitting }) => {
                return (
                  <Form>
                    <SpaceBetween size="m" direction="vertical">
                      {values.user_access_modified && (
                        <Alert
                          type="info"
                          action={
                            <Button variant="normal" onClick={handleReset}>
                              Reset to Default
                            </Button>
                          }
                        >
                          {`You are currently viewing modified permission. Click "Rest to Default" to reset it.`}
                        </Alert>
                      )}
                      <FieldArray
                        name="user_data_classifications"
                        render={(arrayHelpers) => (
                          <SpaceBetween size="l" direction="vertical">
                            {values.user_data_classifications
                              ?.sort((a, b) => compareNullableNumbers(a.data_classification_id, b.data_classification_id, 'asc'))
                              .map((dataClassification, index) => (
                                <Fragment key={index}>
                                  <Box padding={{ bottom: 's' }}>
                                    <SpaceBetween size="l" direction="horizontal">
                                      <FormField label=" " className="width-15-rem">
                                        <Box padding={{ top: 'm' }} variant="h3" fontWeight="light">
                                          {getBusinessGroupName(dataClassification.data_classification_id)}
                                        </Box>
                                      </FormField>

                                      <FormField label="User Type" className="width-15-rem">
                                        <Select
                                          filteringType="auto"
                                          expandToViewport
                                          options={getUserTypes()}
                                          selectedOption={{ label: dataClassification.user_type, value: dataClassification.user_type }}
                                          onChange={({ detail }) => {
                                            setFieldValue(`user_data_classifications[${index}].user_type`, detail.selectedOption.value);
                                          }}
                                          onBlur={() => setFieldTouched(`user_data_classifications[${index}].user_type`, true)}
                                        />
                                      </FormField>

                                      <FormField label="Cost Center" className="width-20-rem">
                                        <Multiselect
                                          expandToViewport
                                          hideTokens
                                          filteringType="auto"
                                          placeholder={getMultiSelectPlaceHolderValue(
                                            getSelectedCCOptions(dataClassification.corp_segment_access),
                                            'Cost Center'
                                          )}
                                          options={[
                                            {
                                              ...SELECT_ALL_OPTION,
                                              options: getCostCentersOfBusinessGroup(dataClassification.data_classification_id)
                                            }
                                          ]}
                                          selectedOptions={getSelectedCCOptions(dataClassification.corp_segment_access)}
                                          onChange={({ detail }) => {
                                            setFieldValue(`user_data_classifications[${index}].corp_segment_access`, [
                                              {
                                                segment_name: COST_CENTER_SEGMENT_NAME,
                                                segment_value: detail.selectedOptions.map((option) => option.value)
                                              }
                                            ]);
                                          }}
                                          onBlur={() => setFieldTouched(`user_data_classifications[${index}].corp_segment_access`, true)}
                                        />
                                      </FormField>
                                    </SpaceBetween>
                                  </Box>
                                  <div className="horizontal-bar"></div>
                                </Fragment>
                              ))}
                          </SpaceBetween>
                        )}
                      ></FieldArray>

                      <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                          <Button variant="link" onClick={onCancel}>
                            Cancel
                          </Button>
                          <Button variant="primary" onClick={() => handleSubmit()} disabled={!dirty}>
                            Submit
                          </Button>
                        </SpaceBetween>
                      </Box>
                    </SpaceBetween>
                  </Form>
                );
              }}
            </Formik>
          </ErrorBoundary>
        )}
      </div>
    </Modal>
  );
};
