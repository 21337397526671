import { Icon, Link, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectSupportTicketTemplates } from 'src/app/AppMetadataSelector';

export const XPTFooter: React.FC = () => {
  const supportTemplates = useSelector(selectSupportTicketTemplates);

  const onSupportClick = useCallback(() => {
    const generalSupportLink = supportTemplates?.fixedTickets.find((tt) => tt.name === 'xPT App Support')?.url;
    generalSupportLink && window.open(generalSupportLink, '_blank');
  }, [supportTemplates]);

  return (
    <div className="footer">
      <SpaceBetween direction="horizontal" size="xs" alignItems="center">
        <Link color="inverted" variant="secondary" fontSize="body-s" onFollow={onSupportClick}>
          <SpaceBetween direction="horizontal" size="xs">
            <span>Support</span>
            <Icon name="external" size="small" />
          </SpaceBetween>
        </Link>
      </SpaceBetween>
    </div>
  );
};
