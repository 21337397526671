import { useEffect, useState } from 'react';
import { calculateLockDates } from 'src/features/planning-cycle/planningCycleUtils';
import { PlanningCycleEntity } from 'src/models/PlanningCycleModel';

const useLockStatus = (planningCycle: PlanningCycleEntity | null | undefined, isAdmin: boolean) => {
  const [isCycleLocked, setIsCycleLocked] = useState(true);
  const [buttonText, setButtonText] = useState('');
  const [popOverText, setPopOverText] = useState('');
  const [statusType, setStatusType] = useState<'info' | 'success' | 'error'>('info');

  useEffect(() => {
    // Function to calculate and update lock status
    const calculateLockStatus = () => {
      if (!planningCycle) {
        setIsCycleLocked(true);
        setPopOverText('');
        setButtonText('-');
        return;
      }

      const { isLocked, buttonText, popOverText, statusType } = calculateLockDates(planningCycle, isAdmin);

      setIsCycleLocked(isLocked);
      setButtonText(buttonText);
      setPopOverText(popOverText);
      setStatusType(statusType);
    };

    // Initial calculation
    calculateLockStatus();

    // Update every second
    const intervalId = setInterval(calculateLockStatus, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [planningCycle, isAdmin]); // Re-run effect on dependency changes

  return { isCycleLocked, buttonText, popOverText, statusType };
};

export default useLockStatus;
