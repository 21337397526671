import { AppLayout, Box, ColumnLayout, ContentLayout, Grid, GridProps, Header, Link } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { APP_NAME } from 'src/constants/generic-constants';
import AnnouncementWidget from '../xpt-widgets/announcements-widget/AnnouncementWidget';
import ContactsWidget from '../xpt-widgets/contacts-widget/ContactsWidget';
import { PlanningCycleHomePageWidget } from '../xpt-widgets/planning-cycle-home-page-widget/PlanningCycleHomePageWidget';
import ResourceWidget from '../xpt-widgets/resources-widget/ResourceWidget';
import UserMetricsWidget from '../xpt-widgets/user-metrics/UserMetrics';
import XptHomePageToolPanel from './XptHomePageToolPanel';
import XptAccountHierarchyInfo from '../xpt-widgets/xpt-account-hierarchy-info/XptAccountHierarchyInfo';

const Widgets_Grid_layout_Defs: GridProps.ElementDefinition[] = [
  { colspan: { default: 12, xl: 6, l: 6, m: 6, s: 6, xs: 6, xxs: 12 } },
  { colspan: { default: 12, xl: 6, l: 6, m: 6, s: 6, xs: 6, xxs: 12 } },
  { colspan: { default: 12, xl: 6, l: 6, m: 6, s: 6, xs: 6, xxs: 12 } },
  { colspan: { default: 12, xl: 6, l: 6, m: 6, s: 6, xs: 6, xxs: 12 } },
  { colspan: { default: 12, xl: 6, l: 6, m: 6, s: 6, xs: 6, xxs: 12 } }
];

const XptHomePage: React.FC = () => {
  const [toolsOpen, setToolsOpen] = useState(false);

  const handleInfoClick = () => {
    setToolsOpen(true);
  };

  return (
    <>
      <AppLayout
        headerSelector="#h"
        navigationHide={true}
        tools={<XptHomePageToolPanel />}
        toolsOpen={toolsOpen}
        onToolsChange={({ detail }) => setToolsOpen(detail.open)}
        contentType="dashboard"
        maxContentWidth={Number.MAX_VALUE}
        content={
          <div className="xpt-app-layout-content">
            <Box margin={{ left: 'l', right: 'l' }} padding={{ left: 'l', right: 'l' }}>
              <ContentLayout
                header={
                  <Header
                    info={
                      <Link variant="info" onFollow={() => handleInfoClick()}>
                        Info
                      </Link>
                    }
                  >
                    {APP_NAME}
                  </Header>
                }
              >
                <ColumnLayout columns={2}>
                  <PlanningCycleHomePageWidget />
                  <Grid gridDefinition={Widgets_Grid_layout_Defs}>
                    <ResourceWidget />
                    <AnnouncementWidget />
                    <ContactsWidget />
                    <UserMetricsWidget />
                    <XptAccountHierarchyInfo />
                  </Grid>
                </ColumnLayout>
              </ContentLayout>
            </Box>
          </div>
        }
      />
    </>
  );
};

export default XptHomePage;
