import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { currentUserToUserAccessForCurrentBusinessGroup } from 'src/features/business-group/access-authorization/redux/AccessAuthorizationUtils';
import {
  setUserAccessForCurrentBusinessGroup,
  setUserAccessForCurrentBusinessGroupStatus
} from 'src/features/business-group/access-authorization/redux/userAuthorizationSlice';
import { selectBusinessGroupByName } from 'src/features/business-group/businessGroupSelectors';
import { fetchBusinessGroups, setCurrentBusinessGroup } from 'src/features/business-group/businessGroupSlice';
import { clearForecastTemplateCompleteData } from 'src/features/business-group/forecast-template/redux/forecastTemplateThunks';
import { Status } from 'src/models/AuthContextModels';
import { UserDataClassifications } from 'src/models/XptUsersModel';
import { fetchMasterCorpSegmentDropdownValues } from 'src/store/slices/corpSegmentSlice';
import { RootState } from 'src/store/store';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { useAuth } from './auth/AuthContextProvider';

export const XPTBusinessGroupWrapper: React.FC = () => {
  const { businessGroupName } = useParams<{ businessGroupName: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true); // Local loading state

  const businessGroupsLoaded = useSelector(
    (state: RootState) => state.businessGroupStore.businessGroups.filter((businessGroup) => businessGroup.item_metadata.is_active).length > 0
  );

  const { userAlias } = useAuth();
  const businessGroup = useSelector((state: RootState) => (businessGroupName ? selectBusinessGroupByName(businessGroupName)(state) : null));
  const { currentUserAccessDetails } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore);

  useEffect(() => {
    if (!businessGroupsLoaded) {
      dispatch(fetchBusinessGroups());
    }
  }, [businessGroupsLoaded, dispatch]);

  useEffect(() => {
    const fetchDataAndSetMetadata = async () => {
      if (!businessGroupsLoaded || !businessGroupName || !businessGroup) {
        console.error('Navigating in Business Group Wrapper...');
        navigate(`/`, { replace: true });
        return;
      }

      try {
        const dataClassificationId = businessGroup.data_classification?.data_classification_id;
        if (!dataClassificationId) {
          console.error('No data classification ID found for the business group');
          throw new Error('No data classification ID found for the business group');
        }

        const currentUserAccessDataClassification: UserDataClassifications | undefined = currentUserAccessDetails?.user_data_classifications.find(
          (dt) => dt.data_classification_id === dataClassificationId
        );

        if (!currentUserAccessDataClassification) {
          console.error('No user data classification found for the business group');
          throw new Error('No user data classification found for the business group');
        }

        dispatch(setUserAccessForCurrentBusinessGroupStatus(Status.Loading));

        const userAccessForCurrentBusinessGroup = currentUserToUserAccessForCurrentBusinessGroup(currentUserAccessDataClassification, userAlias);

        dispatch(setCurrentBusinessGroup(businessGroup));
        dispatch(setUserAccessForCurrentBusinessGroup(userAccessForCurrentBusinessGroup));
        dispatch(fetchMasterCorpSegmentDropdownValues({ businessGroup }));

        dispatch(setUserAccessForCurrentBusinessGroupStatus(Status.Completed));
      } catch (error) {
        console.error('Failed to fetch or set business group metadata:', error);
        navigate(`/`, { replace: true });
      } finally {
        setLoading(false); // Ensure loading state is set to false after fetching data
      }
    };

    fetchDataAndSetMetadata();

    return () => {
      setLoading(true);
      dispatch(setCurrentBusinessGroup(null));
      dispatch(setUserAccessForCurrentBusinessGroup(null));
      dispatch(clearForecastTemplateCompleteData());
      dispatch(setUserAccessForCurrentBusinessGroupStatus(Status.NotInitiated));
    };
  }, [businessGroupName, businessGroup, businessGroupsLoaded, dispatch, navigate]);

  if (loading) {
    return <LoadingSpinner />; // Show loading spinner until fetchDataAndSetMetadata completes
  }

  return <Outlet />;
};

// This Wrapper deals when user is inside a Business Group.
// Will check the user type for this business group.
export const XptFinanceAdminOrFinanceOwnerWrapper: React.FC = () => {
  const { userAccessForCurrentBusinessGroup, userAccessForCurrentBusinessGroupStatus } = useSelector(
    (state: RootState) => state.xptAccessAndAuthorizationStore
  );

  if (userAccessForCurrentBusinessGroupStatus === Status.NotInitiated || userAccessForCurrentBusinessGroupStatus === Status.Loading) {
    return <LoadingSpinner />;
  }

  const isFinanceAdminOrFinanceOwner = userAccessForCurrentBusinessGroup?.isFinanceAdminOrFinanceOwner || false;

  // Navigate away if the user doesn't have access rights
  if (!isFinanceAdminOrFinanceOwner) {
    return <Navigate to="/" replace />;
  }

  // If the user has access, render the child routes
  return <Outlet />;
};
