import { ROLL_UP_PERIOD } from '../XptVarianceReportUtils';

/**
 * Represents the mapping of suffix patterns to their display formats
 */
export type VarianceHeaderSuffixMapping = {
  readonly [key: string]: string;
};

/**
 * Represents the structure of a period after parsing
 */
export interface ParsedPeriod {
  readonly basePeriod: string;
  readonly suffix: string;
}

/**
 * Creates a header string for variance comparison between planning cycles
 * @param currentPlanningCycleName - Name of the current planning cycle
 * @param comparisonPlanningCycleName - Name of the comparison planning cycle
 * @param selectedRollupPeriod - Selected roll-up period (YEARLY or QUARTERLY)
 * @param period - Period string to be formatted
 * @returns Formatted header string
 */
export const getReadableVarianceHeader = (
  currentPlanningCycleName: string,
  comparisonPlanningCycleName: string,
  selectedRollupPeriod: ROLL_UP_PERIOD,
  period: string
): string => {
  // Define suffix patterns and their corresponding display formats
  const suffixMap: VarianceHeaderSuffixMapping = {
    _current: ` (${currentPlanningCycleName})`,
    _comparison: ` (${comparisonPlanningCycleName})`,
    _variance_percent: ' Variance %',
    _variance: ' Variance $'
  } as const;

  /**
   * Parses the period string to extract base period and suffix
   * @param periodString - Period string to parse
   * @returns ParsedPeriod object containing base period and suffix
   */
  const parsePeriod = (periodString: string): ParsedPeriod | null => {
    const matchingSuffix = Object.keys(suffixMap).find((suffix) => periodString.includes(suffix));

    if (!matchingSuffix) return null;

    return {
      basePeriod: periodString.replace(matchingSuffix, ''),
      suffix: matchingSuffix
    };
  };

  /**
   * Formats the period based on roll-up period type
   * @param parsedPeriod - Parsed period information
   * @returns Formatted period string
   */
  const formatPeriod = (parsedPeriod: ParsedPeriod): string => {
    const { basePeriod, suffix } = parsedPeriod;

    if (selectedRollupPeriod === ROLL_UP_PERIOD.YEARLY) {
      return `${basePeriod}${suffixMap[suffix]}`;
    }

    // Handle quarterly format
    const [year, quarter] = basePeriod.split('_');
    return `${year} ${quarter}${suffixMap[suffix]}`;
  };

  const parsedPeriod = parsePeriod(period);
  if (!parsedPeriod) {
    return ''; // Return empty string for invalid period formats
  }

  return formatPeriod(parsedPeriod);
};
