import React, { lazy, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { XPTGlobalTopNavigation } from 'src/features/top-navigation/XptGlobalTopNavigation';
import AuthWrapper from 'src/features/xpt-layout/AuthWrapper';
import { XPTFooter } from 'src/features/xpt-layout/XPTFooter';
import XptHomePage from 'src/features/xpt-layout/XptHomePage';
import { ErrorFallback } from '../components/common/ErrorFallback';
import { LoadingSpinner } from '../components/common/LoadingSpinner';
import { PageNotFound } from '../components/common/PageNotFound';
import { XptApplicationLevelBanner } from './AppBanner';
import { XPTBusinessGroupWrapper, XptFinanceAdminOrFinanceOwnerWrapper } from './XPTBusinessGroupWrapper';

// Static imports
import { AuditLog } from 'src/features/admin-console/dev-console/audit-log/AuditLog';
import OnboardingForm from 'src/features/admin-console/dev-console/onboarding-business-groups/OnboardingForm';
import OnboardingHomePage from 'src/features/admin-console/dev-console/onboarding-business-groups/OnboardingHomePage';
import { ProjectMapping } from 'src/features/admin-console/project-mapping/ProjectMapping';
import { AccessAuthorization } from 'src/features/business-group/access-authorization/AccessAuthorization';
import BudgetOwnerTransfer from 'src/features/business-group/budget-owner-transfer/BudgetOwnerTransfer';
import { ExpenseTypeMapping } from 'src/features/business-group/expense-type-mapping/ExpenseTypeMapping';
import SnapshotsHomePage from 'src/features/business-group/planning-cycle-snapshot/SnapshotHomePage';
import { UsefulLifeMapping } from 'src/features/business-group/useful-life mapping/UsefulLifeMapping';
import PlanningCycleHomePageAdmin from 'src/features/planning-cycle/PlanningCycleHomePageAdmin';
import PlanningCycleHomePageNonAdmin from 'src/features/planning-cycle/PlanningCycleHomePageNonAdmin';
import PlanningCycleManageFormAdmin from 'src/features/planning-cycle/PlanningCycleManageFormAdmin';
import { POTagging } from 'src/features/po-tagging/POTagging';
import { XptForecastReport } from 'src/features/xpt-reports/forecast-report/XptForecastReport';
import { XptVarianceReport } from 'src/features/xpt-reports/variance-report/XptVarianceReport';

// Wrappers for access control
import { CapexFileUpload } from 'src/features/admin-console/capex/CapexFileUpload';
import { APIStats } from 'src/features/admin-console/dev-console/kpi-dashboards/api-stats/APIStats';
import { UserVisitsDashboard } from 'src/features/admin-console/dev-console/kpi-dashboards/user-visits/UserVisitsDashboard';
import { XPTDevOnlyWrapper, XptFinanceAdminWrapper, XPTOperationsAdminWrapper } from 'src/features/admin-console/XPTAdminWrapper';
import ForecastTemplateMainPage from 'src/features/business-group/forecast-template/ForecastTemplate';
import { XptActualsReport } from 'src/features/xpt-reports/actuals-report/XptActualsReport';

export const AppRouter: React.FC = () => {
  return (
    <div data-testid="app-router">
      <BrowserRouter>
        <XPTGlobalTopNavigation />
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={<LoadingSpinner />}>
            <AuthWrapper>
              <XptApplicationLevelBanner />
              <Routes>
                {/* Landing Page */}
                <Route path="/" element={<XptHomePage />} />

                {/* Business Group Routes */}
                <Route path="/:businessGroupName" element={<XPTBusinessGroupWrapper />}>
                  <Route index element={<ForecastTemplateMainPage />} />

                  {/* Routes for Finance Admins or Finance Owners */}
                  <Route element={<XptFinanceAdminOrFinanceOwnerWrapper />}>
                    <Route path="access-authorization" element={<AccessAuthorization />} />
                    <Route path="planning-cycle" element={<PlanningCycleHomePageNonAdmin />} />
                    <Route path="snapshots" element={<SnapshotsHomePage />} />
                    <Route path="budget-owner-transfer" element={<BudgetOwnerTransfer />} />
                    <Route path="expense-type" element={<ExpenseTypeMapping />} />
                    <Route path="useful-life" element={<UsefulLifeMapping />} />
                  </Route>

                  {/* Routes accessible to other roles */}
                  <Route path="forecast-input" element={<ForecastTemplateMainPage />} />
                  <Route path="po-tagging" element={<POTagging />} />
                  <Route path="forecast-report" element={<XptForecastReport />} />
                  <Route path="variance-report" element={<XptVarianceReport />} />
                  <Route path="actuals-report" element={<XptActualsReport />} />
                </Route>

                {/* Admin Console Routes */}
                <Route path="/admin-console" element={<XptFinanceAdminWrapper />}>
                  <Route index element={<PlanningCycleHomePageAdmin />} />
                  <Route path="planning-cycle" element={<PlanningCycleHomePageAdmin />}>
                    <Route path="manage" element={<PlanningCycleManageFormAdmin />} />
                  </Route>

                  {/* CapEx route with Operations Admin access control */}
                  <Route element={<XPTOperationsAdminWrapper />}>
                    <Route path="capex" element={<CapexFileUpload />} />
                  </Route>

                  <Route path="project" element={<ProjectMapping />} />

                  {/* Developer-only Routes */}
                  <Route element={<XPTDevOnlyWrapper />}>
                    <Route path="onboarding" element={<OnboardingHomePage />}>
                      <Route path=":id" element={<OnboardingForm />} />
                    </Route>
                  </Route>

                  <Route path="audit-log" element={<XPTDevOnlyWrapper />}>
                    <Route index element={<AuditLog />} />
                  </Route>

                  <Route path="user-visits" element={<XPTDevOnlyWrapper />}>
                    <Route index element={<UserVisitsDashboard />} />
                  </Route>

                  <Route path="api-stats" element={<XPTDevOnlyWrapper />}>
                    <Route index element={<APIStats />} />
                  </Route>
                </Route>

                {/* Fallback Route */}
                <Route path="*" element={<PageNotFound />} />
              </Routes>
              <XPTFooter />
            </AuthWrapper>
          </Suspense>
        </ErrorBoundary>
      </BrowserRouter>
    </div>
  );
};
