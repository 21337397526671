import { Box, HelpPanel, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

const CAPEX_PANEL_TEXTS = {
  HEADER: 'Capex',
  ARIA_LABEL: 'Capex information panel',
  ACCESS_MESSAGE: 'This page is accessible only to users from OPD Operations group and users with the Finance Admin role.',
  FEATURE_DESCRIPTION: 'This feature allows Finance Admins to upload CapEx Actuals data, which is required for Push to Prophecy.',
  INSTRUCTIONS: {
    DOWNLOAD_UPLOAD:
      'Click the Download Template button, make changes in the Excel file and save it, then click the Choose File button to upload it.',
    ERROR_HANDLING:
      'If the Upload button is inactive, follow the error messages shown to troubleshoot. Once all errors are resolved, the Upload button will become active.'
  }
};

export const CapexInfoPanel: React.FC = () => {
  return (
    <HelpPanel header={<h2>{CAPEX_PANEL_TEXTS.HEADER}</h2>} aria-label={CAPEX_PANEL_TEXTS.ARIA_LABEL}>
      <SpaceBetween size="l">
        <Box variant="p">{CAPEX_PANEL_TEXTS.ACCESS_MESSAGE}</Box>
        <Box variant="p">{CAPEX_PANEL_TEXTS.FEATURE_DESCRIPTION}</Box>
        <ul>
          <li>
            <Box variant="p">{CAPEX_PANEL_TEXTS.INSTRUCTIONS.DOWNLOAD_UPLOAD}</Box>
          </li>
          <li>
            <Box variant="p">{CAPEX_PANEL_TEXTS.INSTRUCTIONS.ERROR_HANDLING}</Box>
          </li>
        </ul>
      </SpaceBetween>
    </HelpPanel>
  );
};
