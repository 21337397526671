import {
  Badge,
  Box,
  BoxProps,
  Button,
  ColumnLayout,
  Container,
  ContentLayout,
  DateRangePicker,
  DateRangePickerProps,
  FormField,
  Header,
  Icon,
  Input,
  Select,
  SpaceBetween,
  StatusIndicator
} from '@amzn/awsui-components-react';
import { FieldArray, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { logger } from 'src/analytics/KatalLogger';
import { useAuth } from 'src/app/auth/AuthContextProvider';
import { ConfirmationModal, ShowModalInfo } from 'src/components/common/ConfirmationModal';
import DisplayContentInCode from 'src/components/common/DisplayContentInCode';
import { ErrorFallback } from 'src/components/common/ErrorFallback';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { FINANCE_ADMIN } from 'src/constants/generic-constants';
import { getActiveDataClassifications, selectAllBusinessGroups } from 'src/features/business-group/businessGroupSelectors';
import {
  calculateLockDates,
  convertToAdminPlanningCycleForm,
  convertToPlanningCycle as convertToPlanningCycleForMutation,
  disableAddingNewBusinessGroupButton,
  getBaseLineScenarioOptions,
  getPlanningCycleYearOptions,
  isValidScenarioYear,
  selectPlanningCyclesByScenarioYear
} from 'src/features/planning-cycle/planningCycleUtils';
import { dateOnlyRangeI18n, dateRangeI18n } from 'src/i18n-strings';
import { Status } from 'src/models/AuthContextModels';
import { RootState } from 'src/store/store';
import { useAppDispatch } from 'src/store/useAppDispatch';
import {
  formatUTCAsLocalReadable,
  getCurrentMonthFirstDayLocalInISO,
  getNextMonthLastDayLocalInISO,
  getCurrentUTCTimeInISO,
  getPacificTimeOffset,
  isDateInThePast,
  isEndOfTheMonth,
  isStartOfTheMonth,
  getCurrentMonthFirstDay,
  getLastMonthFirstDay,
  getLastMonthLastDay,
  getNextMonthLastDay
} from 'src/utils/date-time-utilities';
import { getHeaderCounter } from 'src/utils/generic-utilities';
import { v4 as uuidv4 } from 'uuid';
import { AdminPlanningCycleBusinessGroupsForm, AdminPlanningCycleForm } from '../../models/PlanningCycleFormModel';
import { scenarioToPlanningCycleNameSelectOptions, scenarioToSelectOptions } from './PlanningCycleConfig';
import { adminPlanningCycleFormSchema, getBaselineError, getDataClassificationError, getDateRangeError } from './PlanningCycleFormSchema';
import { useAdminPlanningContext } from './PlanningCycleHomePageAdmin';
import { selectAllPlanningCycles, selectAllScenarios, selectPlanningCycleScenarioYears } from './planningCycleSelector';
import { fetchPlanningCycles, submitPlanningCycle } from './planningCycleSlice';
import { PlanningCycleEntity } from 'src/models/PlanningCycleModel';
import { sendSNSNotification } from 'src/utils/aws-sns-service';

const EMPTY_DROPDOWN = {
  label: '',
  value: ''
};

const ADMIN_PLANNING_CYCLE_INITIAL_FORM: AdminPlanningCycleForm = {
  scenario: EMPTY_DROPDOWN,
  scenario_year: '',
  planning_cycle_name: EMPTY_DROPDOWN,
  planning_cycle_year: EMPTY_DROPDOWN,
  business_groups: []
};

const BUSINESS_GROUP_FIELDS = [
  'data_classification',
  'baseline_scenario',
  'actuals_date_range',
  'forecast_date_range',
  'prophecy_actuals_date_range',
  'prophecy_forecast_date_range',
  'planning_cycle_date_time_range'
] as const;

const initialBusinessGroup: AdminPlanningCycleBusinessGroupsForm = {
  scenario_seq_id: null,
  forecast_data_available: false,
  data_classification: EMPTY_DROPDOWN,
  baseline_scenario: EMPTY_DROPDOWN,
  actuals_date_range: { type: 'absolute', startDate: getLastMonthFirstDay(), endDate: getLastMonthLastDay() },
  forecast_date_range: { type: 'absolute', startDate: getCurrentMonthFirstDay(), endDate: getNextMonthLastDay() },

  prophecy_actuals_date_range: { type: 'absolute', startDate: getLastMonthFirstDay(), endDate: getLastMonthLastDay() },
  prophecy_forecast_date_range: { type: 'absolute', startDate: getCurrentMonthFirstDay(), endDate: getNextMonthLastDay() },
  planning_cycle_date_time_range: { type: 'absolute', startDate: getCurrentMonthFirstDayLocalInISO(), endDate: getNextMonthLastDayLocalInISO() },
  budget_owner_lock_date: null,

  is_prophecy_enabled: false,
  last_synced_to_prophecy: null,
  prophecy_created_at: null,
  prophecy_created_by: null,

  is_active: true,
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: ''
};

const PlanningCycleManageFormAdmin: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userAlias } = useAuth();

  const { currentUserAccessDetails, currentUserAccessLoadingStatus } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore);

  const adminPlanningCycleContext = useAdminPlanningContext();
  const [planningCycleSelectedInForm, setPlanningCycleSelectedInForm] = useState('');
  const [yearFromStartDate, setYearFromStartDate] = useState<number>();

  const searchParams = new URLSearchParams(location.search);
  const scenarioYearPramId = searchParams.get('planningCycle');
  const formAction = scenarioYearPramId === 'new' ? 'Create' : 'Update';
  const [formInitializing, setFormInitializing] = useState<boolean>(true);
  const [showModalInfo, setShowModalInfo] = useState<ShowModalInfo>({
    showModal: false,
    eventHeader: '',
    eventContentType: 'closing_planning_cycle',
    entityInfo: {}
  });
  const [adminPlanningCycleForm, setAdminPlanningCycleForm] = useState<AdminPlanningCycleForm>(ADMIN_PLANNING_CYCLE_INITIAL_FORM);
  const adminPlanningCycleFormRef = useRef<FormikProps<AdminPlanningCycleForm>>(null);

  const dispatch = useAppDispatch();
  const allBusinessGroups = useSelector(selectAllBusinessGroups);
  const activeDataClassifications = useSelector(getActiveDataClassifications);
  const allPlanningCycles = useSelector(selectAllPlanningCycles);
  const allScenarios = useSelector(selectAllScenarios);
  const planningCycleYears = useSelector(selectPlanningCycleScenarioYears);

  const [userDataClassificationIds, setUserDataClassificationIds] = useState<number[]>([]);

  useEffect(() => {
    if (currentUserAccessLoadingStatus === Status.Completed) {
      const dtIds =
        currentUserAccessDetails?.user_data_classifications.filter((dt) => dt.user_type === FINANCE_ADMIN).map((dt) => dt.data_classification_id) ||
        [];
      setUserDataClassificationIds(dtIds);
    }
  }, [currentUserAccessDetails, currentUserAccessLoadingStatus]);

  useEffect(() => {
    if (formAction === 'Create') {
      initializeEmptyForm();
    } else {
      loadExistingForm(scenarioYearPramId);
    }
  }, [scenarioYearPramId]);

  const initializeEmptyForm = () => {
    setAdminPlanningCycleForm(ADMIN_PLANNING_CYCLE_INITIAL_FORM);
    setFormInitializing(false);
  };

  const loadExistingForm = (scenarioYearParam: string | null) => {
    if (scenarioYearParam) {
      const isValidScenario = isValidScenarioYear(scenarioYearParam, allPlanningCycles);
      if (!isValidScenario) {
        adminPlanningCycleContext.notificationMessage('Not a valid planning cycle', 'warning', true);
        goToAllPlanningCycles();
        return;
      }

      const planningCyclesBasedOnScenarioYear = selectPlanningCyclesByScenarioYear(scenarioYearParam, allPlanningCycles);
      const planningCycleForm = convertToAdminPlanningCycleForm(planningCyclesBasedOnScenarioYear);
      setAdminPlanningCycleForm(planningCycleForm);
      setFormInitializing(false);
    } else {
      adminPlanningCycleContext.notificationMessage('Not a valid planning cycle', 'warning', true);
      goToAllPlanningCycles();
    }
  };

  const dataClassificationsToSelectOptions = (business_groups: AdminPlanningCycleBusinessGroupsForm[]) => {
    // Filter and map activeDataClassifications to an array of data_classification_id
    const dataClassificationIds: number[] = activeDataClassifications
      .filter((dc) => dc.data_classification_id !== null)
      .map((dc) => dc.data_classification_id as number);

    // Create a Set of already selected data_classification_id values
    const alreadySelectedIds = new Set(
      business_groups
        .filter((group) => group.data_classification && group.data_classification.value !== null)
        .map((group) => group?.data_classification?.value && +group?.data_classification?.value)
    );

    return dataClassificationIds
      .filter((id) => !alreadySelectedIds.has(id))
      .filter((id) => userDataClassificationIds.includes(id)) // Filter only Data Classifications that are applicable to the Finance Admin.
      .map((id) => {
        const dataClassification = activeDataClassifications.find((dc) => dc.data_classification_id === id);
        return {
          label: dataClassification?.data_classification_name ?? '',
          value: String(id)
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  const goToAllPlanningCycles = () => {
    navigate('/admin-console/planning-cycle');
  };

  const handleClosePlanningCycle = (index: number) => {
    const formRef = adminPlanningCycleFormRef?.current;

    if (!formRef) {
      return;
    }

    // Set all fields as touched
    BUSINESS_GROUP_FIELDS.forEach((field) => {
      formRef.setFieldTouched(`business_groups[${index}].${field}`, true, true);
    });

    setTimeout(() => {
      // Proceed with closing planning cycle
      closingPlanningCycle(index);
    }, 100);
  };

  const closingPlanningCycle = async (index: number) => {
    const formRef = adminPlanningCycleFormRef?.current;

    // Early return if form reference is not available
    if (!formRef) {
      return;
    }

    try {
      // Validate the form
      await formRef.validateForm();

      // Check form validity
      if (!formRef.isValid) {
        return;
      }

      const group = formRef.values.business_groups[index];

      // Early return if group is not found
      if (!group?.planning_cycle_date_time_range) {
        return;
      }

      const modalConfig: ShowModalInfo = {
        showModal: true,
        eventHeader: 'Close Planning Cycle',
        eventContentType: isDateInThePast(group.planning_cycle_date_time_range.endDate)
          ? 'closing_planning_cycle'
          : 'lock_it_before_closing_planning_cycle',
        entityInfo: { removeIndex: index }
      };

      setShowModalInfo(modalConfig);
    } catch (error) {
      if (error instanceof Error) {
        logger.error('Error during planning cycle closure', error);
      } else {
        logger.error('Error during planning cycle closure', new Error(String(error)));
      }
    }
  };

  const onConfirm = async ({}) => {
    if (showModalInfo.eventContentType === 'closing_planning_cycle' && !isNaN(showModalInfo.entityInfo?.removeIndex)) {
      onConfirmingToClose(showModalInfo.entityInfo?.removeIndex, false);
    }

    if (showModalInfo.eventContentType === 'lock_it_before_closing_planning_cycle' && !isNaN(showModalInfo.entityInfo?.removeIndex)) {
      onConfirmingToClose(showModalInfo.entityInfo?.removeIndex, true);
    }
  };

  const onCancel = () => {
    closeModal();
  };

  const closeModal = () => {
    setShowModalInfo({
      showModal: false,
      eventHeader: '',
      eventContentType: 'closing_planning_cycle',
      entityInfo: {}
    });
  };

  const onClickOfRemove = (index: number) => {
    if (adminPlanningCycleFormRef?.current) {
      // Get the current values of the form
      const currentValues = adminPlanningCycleFormRef.current.values;
      // Create a new array with the specified group removed
      const updatedBusinessGroups = currentValues.business_groups.filter((_, i) => i !== index);
      // Update the form values with the new array
      adminPlanningCycleFormRef.current.setFieldValue('business_groups', updatedBusinessGroups);
      // Revalidate the form
      adminPlanningCycleFormRef?.current?.validateForm().then(() => {});
    }
  };

  const onConfirmingToClose = (index: number, lockBeforeClosing: boolean) => {
    if (adminPlanningCycleFormRef?.current) {
      // Get the current values of the form
      const currentValues = adminPlanningCycleFormRef.current.values;
      // Make a copy of the business groups
      const updatedBusinessGroups = [...currentValues.business_groups];
      const group = updatedBusinessGroups[index];

      // Update the `is_active` property of the specified group
      if (group) {
        if (lockBeforeClosing && group.planning_cycle_date_time_range) {
          // Set endDate to current time to lock the cycle
          group.planning_cycle_date_time_range.endDate = getCurrentUTCTimeInISO();
        }
        group.is_active = false;
        // Update the form values with the modified business groups array
        adminPlanningCycleFormRef.current.setFieldValue('business_groups', updatedBusinessGroups);
        adminPlanningCycleFormRef.current.setFieldTouched('business_groups', true, true);
        closeModal();
      }
    }
  };

  const handleSubmit = async (formValues: AdminPlanningCycleForm, formikHelpers: FormikHelpers<AdminPlanningCycleForm>) => {
    const isFormValid = adminPlanningCycleFormRef.current?.isValid;
    if (!isFormValid) {
      return;
    }

    formikHelpers.setSubmitting(true);
    const inProgressMessageId = uuidv4();
    adminPlanningCycleContext.notificationMessage('Request in progress', 'in-progress', false, inProgressMessageId);
    try {
      const planningCycleSubmits = convertToPlanningCycleForMutation(formValues, userAlias, allBusinessGroups);
      await dispatch(submitPlanningCycle(planningCycleSubmits)).unwrap();
      await dispatch(fetchPlanningCycles()).unwrap();
      sendSNSNotification(
        `Planning Cycle ${formAction}d in Finance Admin Tools`,
        `Planning cycle ${formAction}d in Finance Admin Tools by ${userAlias}`,
        'success'
      );
      const successMessage = `Successfully ${formAction?.toLowerCase()}d ${formValues?.scenario.label}`;
      logger.info(successMessage);
      adminPlanningCycleContext.notificationMessage(successMessage, 'success', true);
      navigate('/admin-console/planning-cycle');
    } catch (error) {
      const errorMessage = `Unable to ${formAction?.toLowerCase()} ${formValues?.scenario.label}`;
      sendSNSNotification(
        `Planning Cycle ${formAction === 'Create' ? 'creation' : 'modification'} failed in Finance Admin Tools`,
        `Planning cycle  ${formAction === 'Create' ? 'creation' : 'modification'} failed in Finance Admin Tools by ${userAlias}`,
        'error'
      );
      logger.error(errorMessage, { error: error });
      adminPlanningCycleContext.notificationMessage(errorMessage, 'error', true);
    } finally {
      formikHelpers.setSubmitting(false);
      adminPlanningCycleContext.clearSpecificFlashMessage(inProgressMessageId);
    }
  };

  // Handler for scenario dropdown onBlur
  const handleScenarioBlur = (selectedOption: string) => {
    setPlanningCycleSelectedInForm(selectedOption);
    // Update scenario_year with new label and existing year
    adminPlanningCycleFormRef?.current?.setFieldValue(
      'scenario_year',
      `${selectedOption}_${yearFromStartDate || adminPlanningCycleFormRef?.current?.values.scenario_year.split('_')[1]}`
    );
  };

  // Handler for forecast_date_range onBlur
  const handleDateRangeBlur = (planningCycleYear: string | null) => {
    if (planningCycleYear) {
      setYearFromStartDate(+planningCycleYear);
      // Update scenario_year with existing label and new year
      adminPlanningCycleFormRef?.current?.setFieldValue(
        'scenario_year',
        `${planningCycleSelectedInForm || adminPlanningCycleFormRef?.current?.values.scenario_year.split('_')[0]}_${planningCycleYear}`
      );
    }
  };

  const getPlanningCycleStatus = (group: AdminPlanningCycleBusinessGroupsForm) => {
    if (!hasValidDateRange(group)) {
      return renderPendingStatus();
    }

    const planningCyclePartial = extractPlanningCycleDates(group);
    const { isLocked, buttonText, statusType } = calculateLockDates(planningCyclePartial as PlanningCycleEntity, true);

    return renderLockStatus(isLocked, buttonText, statusType);
  };

  const hasValidDateRange = (group: AdminPlanningCycleBusinessGroupsForm): boolean => {
    return !!(group?.planning_cycle_date_time_range?.startDate && group?.planning_cycle_date_time_range?.endDate);
  };

  const renderPendingStatus = () => (
    <StatusIndicator colorOverride="grey" type="pending">
      {`Pending`}
    </StatusIndicator>
  );

  const extractPlanningCycleDates = (group: AdminPlanningCycleBusinessGroupsForm) => ({
    planning_cycle_open_date: group?.planning_cycle_date_time_range?.startDate,
    planning_cycle_lock_date: group?.planning_cycle_date_time_range?.endDate,
    budget_owner_lock_date: group?.budget_owner_lock_date
  });

  const renderLockStatus = (isLocked: boolean, buttonText: string, statusType: 'success' | 'info' | 'error') => {
    const textColor: BoxProps.Color = `text-status-${statusType || 'info'}`;
    return (
      <SpaceBetween size="s" direction="horizontal" alignItems="center">
        <Icon name={isLocked ? 'lock-private' : 'unlocked'} />
        <Box color={textColor}>{buttonText}</Box>
      </SpaceBetween>
    );
  };
  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          window.location.reload();
        }}
      >
        <ConfirmationModal showModalInfo={showModalInfo} onConfirm={onConfirm} onCancel={onCancel} />
        {formInitializing && <LoadingSpinner />}
        {!formInitializing && (
          <Formik<AdminPlanningCycleForm>
            innerRef={adminPlanningCycleFormRef}
            enableReinitialize={true}
            initialValues={adminPlanningCycleForm}
            validationSchema={adminPlanningCycleFormSchema(formAction === 'Create' ? planningCycleYears : [])}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={handleSubmit}
          >
            {({ errors, handleBlur, handleSubmit, touched, dirty, isValid, setFieldTouched, values, setFieldValue, isSubmitting }) => {
              return (
                <>
                  <Form onSubmit={handleSubmit}>
                    <ContentLayout
                      header={
                        <Header
                          variant="h1"
                          actions={
                            <SpaceBetween size="m" direction="horizontal">
                              <Button variant="primary" formAction="submit" disabled={isSubmitting || !dirty || !isValid}>
                                {'Submit'}
                              </Button>
                            </SpaceBetween>
                          }
                          description={`This feature allows Finance Admins to create, edit and close planning cycles.`}
                        >{`${formAction} planning cycle`}</Header>
                      }
                    >
                      <SpaceBetween size="m" direction="vertical" className="transform">
                        <Container>
                          <Box margin={{ top: 'm' }}>
                            <SpaceBetween size="m" direction="horizontal">
                              <SpaceBetween size="m" direction="vertical">
                                {/* Planning cycle  */}
                                <FormField label="Planning cycle" className="width-25-rem" errorText={touched.scenario && errors.scenario?.label}>
                                  {formAction === 'Update' ? (
                                    <Input value={values.scenario.label} readOnly></Input>
                                  ) : (
                                    <Select
                                      selectedOption={values.scenario}
                                      onChange={({ detail }) => {
                                        setFieldValue('scenario', detail.selectedOption);
                                        setFieldValue('planning_cycle_name', EMPTY_DROPDOWN);
                                      }}
                                      onBlur={() => {
                                        setFieldTouched('scenario', true, true);
                                      }}
                                      options={scenarioToSelectOptions(allScenarios)}
                                    />
                                  )}
                                </FormField>
                                <FormField errorText={errors.scenario_year}></FormField>
                              </SpaceBetween>

                              <FormField
                                label="Planning Cycle Name"
                                className="width-25-rem"
                                errorText={touched.planning_cycle_name && errors.planning_cycle_name?.label}
                              >
                                {formAction === 'Update' ? (
                                  <Input value={values.planning_cycle_name.label} readOnly></Input>
                                ) : (
                                  <Select
                                    selectedOption={values.planning_cycle_name}
                                    onChange={({ detail }) => {
                                      setFieldValue('planning_cycle_name', detail.selectedOption);
                                    }}
                                    onBlur={() => {
                                      setFieldTouched('planning_cycle_name', true, true);
                                      handleScenarioBlur(values.planning_cycle_name.label);
                                    }}
                                    options={scenarioToPlanningCycleNameSelectOptions(allScenarios, values.scenario.label)}
                                  />
                                )}
                              </FormField>

                              <FormField
                                label="Planning Cycle Year"
                                className="width-25-rem"
                                errorText={touched.planning_cycle_year && errors.planning_cycle_year?.label}
                              >
                                {formAction === 'Update' ? (
                                  <Input value={values.planning_cycle_year.label} readOnly></Input>
                                ) : (
                                  <Select
                                    selectedOption={values.planning_cycle_year}
                                    onChange={({ detail }) => {
                                      setFieldValue('planning_cycle_year', detail.selectedOption);
                                    }}
                                    onBlur={() => {
                                      setFieldTouched('planning_cycle_year', true, true);
                                      handleDateRangeBlur(values.planning_cycle_year.label);
                                    }}
                                    options={getPlanningCycleYearOptions()}
                                  />
                                )}
                              </FormField>
                            </SpaceBetween>
                          </Box>
                        </Container>

                        <Container
                          header={
                            <Header
                              counter={getHeaderCounter([], values?.business_groups)}
                              description="Select all business groups to apply this planning cycle to."
                            >{`Business group cycle dates`}</Header>
                          }
                        >
                          {/* Business Groups */}
                          <FieldArray
                            name="business_groups"
                            render={(arrayHelpers) => (
                              <SpaceBetween size="m" direction="vertical">
                                {values.business_groups.map((group, index) => (
                                  <Container
                                    key={index}
                                    header={
                                      !group.scenario_seq_id ? (
                                        <Header
                                          actions={
                                            <SpaceBetween size="m">
                                              <Button onClick={() => onClickOfRemove(index)}>{`Remove`}</Button>
                                            </SpaceBetween>
                                          }
                                        ></Header>
                                      ) : undefined
                                    }
                                  >
                                    <SpaceBetween size="m" direction="vertical">
                                      <SpaceBetween size="m" direction="horizontal">
                                        <FormField
                                          label={'Business group'}
                                          className="width-30-rem"
                                          errorText={getDataClassificationError(touched, errors, index)}
                                        >
                                          {group.scenario_seq_id !== null || !group.is_active ? (
                                            <Input value={group.data_classification.label} readOnly></Input>
                                          ) : (
                                            <>
                                              <Select
                                                selectedOption={group.data_classification}
                                                onChange={({ detail }) => {
                                                  setFieldValue(`business_groups[${index}].data_classification`, detail.selectedOption);
                                                  setFieldValue(`business_groups[${index}].baseline_scenario`, EMPTY_DROPDOWN);
                                                }}
                                                onBlur={() => setFieldTouched(`business_groups[${index}].data_classification`, true, true)}
                                                options={dataClassificationsToSelectOptions(values.business_groups)}
                                              />
                                            </>
                                          )}
                                        </FormField>

                                        <FormField
                                          label={
                                            <span>
                                              {'Baseline scenario'} <i>- optional</i>{' '}
                                            </span>
                                          }
                                          className="width-30-rem"
                                          errorText={getBaselineError(touched, errors, index)}
                                        >
                                          {group.scenario_seq_id !== null || !group.is_active ? (
                                            <Input value={group.baseline_scenario.label} readOnly></Input>
                                          ) : (
                                            <>
                                              <Select
                                                options={getBaseLineScenarioOptions(group.data_classification.label || '', allPlanningCycles)}
                                                selectedOption={group.baseline_scenario}
                                                onChange={({ detail }) =>
                                                  setFieldValue(`business_groups[${index}].baseline_scenario`, detail.selectedOption)
                                                }
                                                onBlur={() => setFieldTouched(`business_groups[${index}].baseline_scenario`, true, true)}
                                              />
                                            </>
                                          )}
                                        </FormField>

                                        <FormField
                                          label={'xPT Actuals date range'}
                                          className="width-30-rem"
                                          errorText={getDateRangeError(touched, errors, index, 'actuals_date_range')}
                                        >
                                          <DateRangePicker
                                            value={group.actuals_date_range as DateRangePickerProps.Value}
                                            onChange={(event) => setFieldValue(`business_groups[${index}].actuals_date_range`, event.detail.value)}
                                            onBlur={() => setFieldTouched(`business_groups[${index}].actuals_date_range`, true, true)}
                                            isValidRange={() => ({ valid: true })}
                                            isDateEnabled={(date: Date) => isStartOfTheMonth(date) || isEndOfTheMonth(date)}
                                            relativeOptions={[]}
                                            i18nStrings={dateOnlyRangeI18n()}
                                            dateOnly={true}
                                            placeholder="YYYY/MM - YYYY/MM"
                                            rangeSelectorMode="absolute-only"
                                            readOnly={!group.is_active}
                                            disabled={!userDataClassificationIds.includes(+group.data_classification.value)}
                                          />
                                        </FormField>

                                        <FormField
                                          label={'xPT Forecast date range'}
                                          className="width-30-rem"
                                          errorText={getDateRangeError(touched, errors, index, 'forecast_date_range')}
                                        >
                                          <DateRangePicker
                                            value={group.forecast_date_range as DateRangePickerProps.Value}
                                            onChange={(event) => setFieldValue(`business_groups[${index}].forecast_date_range`, event.detail.value)}
                                            onBlur={() => {
                                              setFieldTouched(`business_groups[${index}].forecast_date_range`, true, true);
                                            }}
                                            isValidRange={() => ({ valid: true })}
                                            isDateEnabled={(date: Date) => isStartOfTheMonth(date) || isEndOfTheMonth(date)}
                                            relativeOptions={[]}
                                            i18nStrings={dateOnlyRangeI18n()}
                                            dateOnly={true}
                                            placeholder="YYYY/MM - YYYY/MM"
                                            rangeSelectorMode="absolute-only"
                                            readOnly={!group.is_active}
                                            disabled={!userDataClassificationIds.includes(+group.data_classification.value)}
                                          />
                                        </FormField>
                                      </SpaceBetween>

                                      <SpaceBetween size="m" direction="horizontal">
                                        <FormField
                                          label={'Prophecy CapEx Actuals date range'}
                                          className="width-30-rem"
                                          errorText={getDateRangeError(touched, errors, index, 'prophecy_actuals_date_range')}
                                        >
                                          <DateRangePicker
                                            value={group.prophecy_actuals_date_range as DateRangePickerProps.Value}
                                            onChange={(event) =>
                                              setFieldValue(`business_groups[${index}].prophecy_actuals_date_range`, event.detail.value)
                                            }
                                            onBlur={() => {
                                              setFieldTouched(`business_groups[${index}].prophecy_actuals_date_range`, true, true);
                                            }}
                                            isValidRange={() => ({ valid: true })}
                                            isDateEnabled={(date: Date) => isStartOfTheMonth(date) || isEndOfTheMonth(date)}
                                            relativeOptions={[]}
                                            i18nStrings={dateOnlyRangeI18n()}
                                            dateOnly={true}
                                            placeholder="YYYY/MM - YYYY/MM"
                                            rangeSelectorMode="absolute-only"
                                            readOnly={!group.is_active}
                                            disabled={!userDataClassificationIds.includes(+group.data_classification.value)}
                                          />
                                        </FormField>

                                        <FormField
                                          label={'Prophecy Forecast date range'}
                                          className="width-30-rem"
                                          errorText={getDateRangeError(touched, errors, index, 'prophecy_forecast_date_range')}
                                        >
                                          <DateRangePicker
                                            value={group.prophecy_forecast_date_range as DateRangePickerProps.Value}
                                            onChange={(event) =>
                                              setFieldValue(`business_groups[${index}].prophecy_forecast_date_range`, event.detail.value)
                                            }
                                            onBlur={() => {
                                              setFieldTouched(`business_groups[${index}].prophecy_forecast_date_range`, true, true);
                                            }}
                                            isValidRange={() => ({ valid: true })}
                                            isDateEnabled={(date: Date) => isStartOfTheMonth(date) || isEndOfTheMonth(date)}
                                            relativeOptions={[]}
                                            i18nStrings={dateOnlyRangeI18n()}
                                            dateOnly={true}
                                            placeholder="YYYY/MM - YYYY/MM"
                                            rangeSelectorMode="absolute-only"
                                            readOnly={!group.is_active}
                                            disabled={!userDataClassificationIds.includes(+group.data_classification.value)}
                                          />
                                        </FormField>
                                      </SpaceBetween>

                                      <SpaceBetween size="m" direction="horizontal">
                                        <FormField label={'Budget Owner lock date'} className="width-30-rem">
                                          <Input
                                            value={group.budget_owner_lock_date ? formatUTCAsLocalReadable(group.budget_owner_lock_date) : 'N/A'}
                                            readOnly
                                          ></Input>
                                        </FormField>

                                        <FormField
                                          label={'Planning cycle window'}
                                          className="width-55-rem"
                                          errorText={
                                            touched.business_groups &&
                                            touched.business_groups[index]?.planning_cycle_date_time_range &&
                                            errors.business_groups &&
                                            (errors?.business_groups[index]
                                              ? (errors?.business_groups[index] as unknown as AdminPlanningCycleBusinessGroupsForm)
                                                  ?.planning_cycle_date_time_range?.endDate || ''
                                              : null)
                                          }
                                        >
                                          <DateRangePicker
                                            rangeSelectorMode="absolute-only"
                                            absoluteFormat="long-localized"
                                            value={group.planning_cycle_date_time_range as DateRangePickerProps.Value}
                                            onChange={(event) =>
                                              setFieldValue(`business_groups[${index}].planning_cycle_date_time_range`, event.detail.value)
                                            }
                                            onBlur={() => setFieldTouched(`business_groups[${index}].planning_cycle_date_time_range`, true, true)}
                                            isValidRange={() => ({ valid: true })}
                                            relativeOptions={[]}
                                            i18nStrings={dateRangeI18n(
                                              'Range must be planning cycle window. Use 24 hour format in America/Los Angeles time.'
                                            )}
                                            placeholder="Select plan cycle window"
                                            timeInputFormat="hh:mm"
                                            readOnly={!group.is_active}
                                            hideTimeOffset
                                            getTimeOffset={() => getPacificTimeOffset()}
                                            disabled={!userDataClassificationIds.includes(+group.data_classification.value)}
                                          />
                                        </FormField>

                                        <FormField label={'Status'} className="width-15-rem">
                                          {getPlanningCycleStatus(group)}
                                        </FormField>

                                        {/* Close/Remove Button Field */}
                                        <Box margin={{ top: 'l', left: 'm' }}>
                                          <FormField>
                                            {group.scenario_seq_id !== null ? (
                                              <Button
                                                variant="normal"
                                                formAction="none"
                                                disabled={!group.is_active || !userDataClassificationIds.includes(+group.data_classification.value)}
                                                onClick={() => handleClosePlanningCycle(index)}
                                              >
                                                {group.is_active ? 'Close' : 'Closed'}
                                              </Button>
                                            ) : (
                                              <Button
                                                variant="normal"
                                                formAction="none"
                                                onClick={() => onClickOfRemove(index)}
                                                disabled={!userDataClassificationIds.includes(+group.data_classification.value)}
                                              >
                                                Remove
                                              </Button>
                                            )}
                                          </FormField>
                                        </Box>
                                      </SpaceBetween>
                                    </SpaceBetween>
                                  </Container>
                                ))}

                                {/* Add Business Group */}
                                <Box margin={{ top: 'm' }}>
                                  <Button
                                    iconName="add-plus"
                                    disabled={disableAddingNewBusinessGroupButton(
                                      userDataClassificationIds,
                                      values?.business_groups?.map((dataClassification) => +dataClassification.data_classification.value)
                                    )}
                                    onClick={() => {
                                      if (values.business_groups.length > 0) {
                                        const businessGroup = {
                                          ...initialBusinessGroup,
                                          actuals_date_range: values.business_groups[0].actuals_date_range,
                                          forecast_date_range: values.business_groups[0].forecast_date_range,
                                          prophecy_forecast_date_range: values.business_groups[0].prophecy_forecast_date_range,
                                          prophecy_actuals_date_range: values.business_groups[0].prophecy_actuals_date_range,
                                          planning_cycle_date_time_range: values.business_groups[0].planning_cycle_date_time_range
                                        };
                                        arrayHelpers.push(businessGroup);
                                      } else {
                                        arrayHelpers.push(initialBusinessGroup);
                                      }
                                    }}
                                  >
                                    Add Business Group
                                  </Button>
                                </Box>

                                <div>
                                  {typeof errors.business_groups === 'string' && (
                                    <StatusIndicator colorOverride="red" type="error">
                                      {errors.business_groups}
                                    </StatusIndicator>
                                  )}
                                </div>
                              </SpaceBetween>
                            )}
                          />
                        </Container>

                        <ColumnLayout columns={2}>
                          <DisplayContentInCode details={values} />
                          <DisplayContentInCode details={{ form_dirty: dirty, form_valid: isValid, form_errors: errors, form_touched: touched }} />
                        </ColumnLayout>
                      </SpaceBetween>
                    </ContentLayout>
                  </Form>
                </>
              );
            }}
          </Formik>
        )}
      </ErrorBoundary>
    </>
  );
};

export default PlanningCycleManageFormAdmin;
