import { FlashbarProps, SelectProps } from '@amzn/awsui-components-react';
import { useCallback, useState } from 'react';
import { logger } from 'src/analytics/KatalLogger';
import { Status } from 'src/models/AuthContextModels';
import { POTaggingEntity } from 'src/models/POTaggingModel';
import { filterS3KeysBasedOnCC } from '../utils/XptActualsReportGridColumnHelper';
import { useActualsS3Keys } from './useActualsS3Keys';
import { useExportFileDetails } from './useExportFileDetails';
import { useS3DataFetcher } from './useS3DataFetcher';

interface UseActualsReportProps {
  dataClassificationId: number;
  dataClassificationShortDesc: string;
  bucketName: string;
  selectedCostCenter: SelectProps.Option | null;
  notifyFlashMessage: (content: string, flashBarType: FlashbarProps.Type, isDismissible: boolean, messageId?: string) => void;
}

interface UseActualsReportReturn {
  s3KeysFetchStatus: Status;
  distributionDataS3Keys: string[];
  actualsDataStatus: Status;
  actualsData: POTaggingEntity[];
  initializeS3Keys: () => Promise<void>;
  fetchActualsData: () => Promise<void>;
  exportFileDetails:
    | {
        fileName: string;
        sheetName: string;
      }
    | undefined;
}

export const useActualsReport = ({
  dataClassificationId,
  dataClassificationShortDesc,
  bucketName,
  selectedCostCenter,
  notifyFlashMessage
}: UseActualsReportProps): UseActualsReportReturn => {
  // States
  const [actualsDataStatus, setActualsDataStatus] = useState<Status>(Status.NotInitiated);
  const [actualsData, setActualsData] = useState<POTaggingEntity[]>([]);

  // Hooks
  const { initializeS3Keys, s3KeysFetchStatus, actualsDataS3Keys, distributionDataS3Keys } = useActualsS3Keys({
    dataClassificationId,
    dataClassificationShortDesc,
    bucketName,
    notifyFlashMessage
  });

  const { fetchDataFromS3 } = useS3DataFetcher({ bucketName, notifyFlashMessage });

  const { exportFileDetails } = useExportFileDetails({
    dataClassificationShortDesc,
    selectedCostCenter
  });

  // Callbacks
  const fetchActualsData = useCallback(async () => {
    if (!selectedCostCenter || s3KeysFetchStatus !== Status.Completed) return;

    const selectedCC = selectedCostCenter.value || '';
    const s3KeysForSelectedCCs = filterS3KeysBasedOnCC(actualsDataS3Keys, [selectedCC]);

    setActualsDataStatus(Status.Loading);
    setActualsData([]);

    try {
      const data = await fetchDataFromS3({
        selectedCC,
        s3Keys: s3KeysForSelectedCCs,
        dataType: 'Actuals'
      });

      logger.info('Fetched actuals data', {
        costCenter: selectedCC,
        recordCount: data.length
      });

      if (data.length > 0) {
        setActualsData(data);
        setActualsDataStatus(Status.Completed);
      } else {
        setActualsDataStatus(Status.Failed);
      }
    } catch (error: any) {
      setActualsDataStatus(Status.Failed);
      logger.error('Error fetching actuals data', {
        costCenter: selectedCC,
        error: error instanceof Error ? error.message : 'Unknown error'
      });
    }
  }, [selectedCostCenter, s3KeysFetchStatus, actualsDataS3Keys, fetchDataFromS3]);

  return {
    s3KeysFetchStatus,
    distributionDataS3Keys,
    actualsDataStatus,
    actualsData,
    initializeS3Keys,
    fetchActualsData,
    exportFileDetails
  };
};
