import { Alert, Box, Container, Flashbar, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useRef, useState } from 'react';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { XptAppLayout } from 'src/components/common/xpt-app-layout/XptAppLayout';
import { XPTBreadcrumbs } from 'src/components/common/XptBreadcrumb';
import { useFlashbar } from 'src/hooks/useFlashbar';
import { Status } from 'src/models/AuthContextModels';
import { getCWDashboard } from 'src/utils/aws-cloudwatch-services';
import { geUserInsightsBreadcrumbItems } from './UserVisitsConfig';
import { UserVisitsTable } from './UserVisitsTable';
import AdminConsoleSideNavigation from 'src/features/admin-console/admin-console-side-navigation/AdminConsoleSideNavigation';

const USER_VISITS_DASHBOARD_NAME = 'xPT-FE-UserVisits';

export const UserVisitsDashboard: React.FC = () => {
  const appLayout = useRef<any>();
  const { flashbarItems, displayFlashMessage } = useFlashbar();

  const [dashboardLoadingStatus, setDashboardLoadingStatus] = useState<Status>(Status.NotInitiated);
  const isDashboardLoading = dashboardLoadingStatus === Status.Loading;
  const [dashboardLoadingError, setDashboardLoadingError] = useState<string | null>(null);
  const [logTableQuery, setLogTableQuery] = useState<string | null>(null);

  const fetchDashboard = async () => {
    setDashboardLoadingStatus(Status.Loading);
    setDashboardLoadingError(null);
    try {
      const dashboardData = await getCWDashboard(USER_VISITS_DASHBOARD_NAME);
      const dashboardBodyJson = JSON.parse(dashboardData.DashboardBody || '{}');
      const logWidget = dashboardBodyJson.widgets.find((widget: any) => widget.type === 'log');
      if (logWidget) {
        setLogTableQuery(logWidget.properties.query);
        setDashboardLoadingStatus(Status.Completed);
      } else {
        setDashboardLoadingStatus(Status.Completed);
      }
    } catch (err) {
      setDashboardLoadingStatus(Status.Failed);
      setDashboardLoadingError('Failed to load dashboard. Please try again later.');
      displayFlashMessage('Failed to load dashboard. Please try again later.', 'error', true);
    }
  };

  useEffect(() => {
    fetchDashboard();
  }, []);

  return (
    <XptAppLayout
      ref={appLayout}
      headerSelector="#h"
      navigation={<AdminConsoleSideNavigation />}
      breadcrumbs={<XPTBreadcrumbs items={geUserInsightsBreadcrumbItems()} />}
      notifications={<Flashbar items={flashbarItems} stackItems />}
      stickyNotifications={true}
      maxContentWidth={Number.MAX_VALUE}
      toolsHide={true}
      contentType="dashboard"
      content={
        <SpaceBetween size="s">
          <Alert type="info" header="CloudWatch Direct Log Retrieval - Beta Feature">
            This feature enables direct log fetching from CloudWatch, streamlining the process without additional infrastructure.
          </Alert>
          {isDashboardLoading && <LoadingSpinner />}
          {dashboardLoadingError && (
            <Alert type="error" header="Error">
              {dashboardLoadingError}
            </Alert>
          )}
          {!isDashboardLoading && !dashboardLoadingError && !logTableQuery && (
            <Alert type="warning" header="No data">
              No dashboard data available.
            </Alert>
          )}
          {!isDashboardLoading && !dashboardLoadingError && logTableQuery && (
            <Box padding={{ top: 'm' }}>
              <UserVisitsTable widgetPropertyQuery={logTableQuery} />
            </Box>
          )}
        </SpaceBetween>
      }
    />
  );
};
