import { Alert, Link } from '@amzn/awsui-components-react';
import { useSelector } from 'react-redux';
import { selectGeneralSupportLink } from 'src/app/AppMetadataSelector';

interface ErrorAlertWithTicketLinkProps {
  message: string;
}

export const ErrorAlertWithTicketLink: React.FC<ErrorAlertWithTicketLinkProps> = ({ message }) => {
  const ticketLink = useSelector(selectGeneralSupportLink);

  return (
    <Alert type="error">
      {message}{' '}
      <Link external href={ticketLink} variant="primary">
        Contact support
      </Link>
      .
    </Alert>
  );
};
