import { Alert, Button, ButtonDropdown, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import {
  CellValueChangedEvent,
  ColDef,
  ColumnGroupOpenedEvent,
  GetContextMenuItemsParams,
  GetRowIdParams,
  GridReadyEvent,
  IMenuActionParams,
  MenuItemDef,
  ProcessCellForExportParams,
  SideBarDef
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { logger, logUserClick } from 'src/analytics/KatalLogger';
import { useAuth } from 'src/app/auth/AuthContextProvider';
import { ConfirmDiscardModal } from 'src/components/common/ConfirmDiscardModal';
import { ErrorFallback } from 'src/components/common/ErrorFallback';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import XptGridOverlay from 'src/components/common/XptGridDataStatusOverlay';
import { CorpSegmentNames } from 'src/constants/corp-segment-constants';
import { OperationType } from 'src/constants/generic-constants';
import XptMessages from 'src/constants/xpt-messages';
import { useGridState } from 'src/hooks/useGridState';
import { setLocalStorageItem } from 'src/hooks/useLocalStorage';
import useUnsavedChangesWarning from 'src/hooks/useUnsavedChangesWarning';
import { ForecastGridRowData, ForecastTemplateColumns } from 'src/models/ForecastModels';
import { UserAccessEntity } from 'src/models/XptUsersModel';
import { selectActiveExpenseTypesForCurrentGroup } from 'src/store/selectors/xPTMapperSelector';
import { RootState } from 'src/store/store';
import { useAppDispatch } from 'src/store/useAppDispatch';
import {
  addNewRowToGrid,
  CellDataType,
  deepClone,
  ensureRowVisibleAndFocusFirstCell,
  handleCellEditingStopped,
  processCellForClipboard,
  processCellFromClipboard,
  StatusBarConfig
} from 'src/utils/ag-grid-utils';
import { getCurrentUTCTimeInISO } from 'src/utils/date-time-utilities';
import { generateUniqueId } from 'src/utils/generic-utilities';
import {
  currentBusinessGroup,
  currentBusinessGroupName,
  currentBusinessGroupShortDesc,
  currentDataClassificationId
} from '../businessGroupSelectors';
import { isUserAuthorizedToEditThisRow } from './forecast-utils/ForecastColumnGenerator';
import * as ForecastGridConstants from './forecast-utils/ForecastGridConstants';
import { submitForecastData } from './forecast-utils/ForecastTemplateDataSubmission';
import {
  validateBudgetOwnerAndCostCenterCombination,
  validateBudgetTypeAndAccountCombination,
  validateDuplicateRecords,
  validateMandatoryFields,
  validateSegments,
  validateUnAuthorizedRows
} from './forecast-utils/ForecastTemplateDataValidations';
import { getLocalStorageKeyForCorpSegments } from './forecast-utils/ForecastTemplateFilterUtils';
import {
  cloneForecastInputRow,
  forecastGridFileActions,
  getForecastExportFileName,
  getForecastTemplateHeaderInfo,
  getNewlyAddedSelections,
  isForecastGridRowModified,
  isSegmentModified,
  prepareForecastTemplateUploadData,
  updateBudgetTypeIfAccountChanged,
  useEnhancedMasterCorpSegmentDropdowns
} from './forecast-utils/ForecastTemplateUtils';
import ForecastValidationMessages from './forecast-utils/ValidationMessages';
import { useForecastTemplateContext } from './ForecastTemplateContext';
import ForecastTemplateErrorDisplay from './ForecastTemplateErrorDisplay';
import { ForecastTemplateFileUpload } from './ForecastTemplateFileUpload';
import useLockStatus from './hooks/useLockStatus';
import {
  setCurrentCorpSegmentDropDownSelections,
  setForecastDataSubmitClickCount,
  setForecastDataSubmitting,
  setForecastTemplateDataStatus,
  setValidationStatus
} from './redux/forecastTemplateSlice';
import { fetchForecastTemplateCompleteData } from './redux/forecastTemplateThunks';
import { additionalAccounts } from './forecast-utils/ForecastGridConstants';
import { useAgGridExcelExport } from './hooks/useAgGridExcelExport';

interface ForecastTemplateGridTableProps {
  forecastGridData: ForecastGridRowData[];
  forecastColDef: ColDef[];
}
1;

export const ForecastTemplateGridTable: React.FC<ForecastTemplateGridTableProps> = (props) => {
  const { userAlias } = useAuth();
  const dispatch = useAppDispatch();

  const { clearSpecificFlashMessage, notificationMessage } = useForecastTemplateContext();

  const themeClassName = useSelector((state: RootState) => state.xptAppMetadataStore.themeClassName);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const businessGroup = useSelector(currentBusinessGroup);
  const businessGroupName = useSelector(currentBusinessGroupName);
  const businessGroupShortDesc = useSelector(currentBusinessGroupShortDesc);
  const dataClassificationId = useSelector(currentDataClassificationId);

  const { userAccessForCurrentBusinessGroup, xptUsers, userCostCentersForCurrentBusinessGroup } = useSelector(
    (state: RootState) => state.xptAccessAndAuthorizationStore
  );
  const xptUsersOfCurrentDataClassification: UserAccessEntity[] = xptUsers.filter(
    (user) => user.is_active && user.data_classification_id === dataClassificationId
  );

  const isFinanceAdminOrFinanceOwner = userAccessForCurrentBusinessGroup?.isFinanceAdminOrFinanceOwner || false;
  const isBudgetLeader = userAccessForCurrentBusinessGroup?.isBudgetLeader || false;

  const currentPlanningCycle = useSelector((state: RootState) => state.forecastTemplateStore.currentPlanningCycle);
  const currentCorpSegmentDropDownSelections = useSelector((state: RootState) => state.forecastTemplateStore.currentCorpSegmentDropDownSelections);

  const masterCorpSegmentDropdowns = useSelector((state: RootState) => state.corpSegmentsStore.masterCorpSegmentDropdownValues);
  const masterBusinessSegments = useSelector((state: RootState) => state.corpSegmentsStore.masterBusinessSegments);
  const expenseTypesForCurrentGroup = useSelector(selectActiveExpenseTypesForCurrentGroup);
  const { accountBudgetTypeMapping } = useSelector((state: RootState) => state.xPTMappingStore);

  const { isCycleLocked } = useLockStatus(currentPlanningCycle, isFinanceAdminOrFinanceOwner);

  const isForecastTemplateDataLoading = useSelector((state: RootState) => state.forecastTemplateStore.forecastTemplateDataLoading);
  const isForecastTemplateDataSubmitting = useSelector((state: RootState) => state.forecastTemplateStore.forecastDataSubmitting);
  const showLoadingSpinner = isForecastTemplateDataLoading || isForecastTemplateDataSubmitting;

  const forecastDataStatus = useSelector((state: RootState) => state.forecastTemplateStore.forecastDataStatus);

  const gridRef = useRef<AgGridReact>(null);
  const gridStateKey = `UniqueGridStateKey-ForecastInput-${businessGroupShortDesc}`;
  const { saveGridState, restoreGridState, clearGridState, atLeastOneStateAvailable } = useGridState(gridRef, gridStateKey);
  const { exportToExcel } = useAgGridExcelExport({ gridRef, businessGroup, currentPlanningCycle, businessGroupShortDesc, userAlias });

  const [forecastTemplateColDef, setForecastTemplateColDef] = useState<ColDef[]>([]);
  const [forecastTemplateGridData, setForecastTemplateGridData] = useState<ForecastGridRowData[]>([]);
  const [originalData, setOriginalData] = useState<Map<number, ForecastGridRowData>>(new Map());
  const [gridDataLoaded, setGridDataLoaded] = useState(false);
  const [forecastRowsRemoved, setForecastRowsRemoved] = useState<any[]>([]);
  const [selectedRowCount, setSelectedRowCount] = useState<number>(0);
  const [showFileImportModal, setShowFileImportModal] = useState<boolean>(false);
  const [forecastTemplateColumnInfo, setForecastTemplateColumnInfo] = useState<ForecastTemplateColumns>({} as ForecastTemplateColumns);
  let submitClickCount = 0;
  const [showResetViewModal, setShowResetViewModal] = useState(false);
  const [showReloadModal, setShowReloadModal] = useState(false);

  useUnsavedChangesWarning(forecastDataStatus.dataStatus.status === ForecastGridConstants.ForecastTemplateDataStatuses.Modified.status);

  // Enhance master corporate segment dropdowns with additional accounts
  const enhancedMasterCorpSegmentDropdowns = useEnhancedMasterCorpSegmentDropdowns(
    masterCorpSegmentDropdowns,
    businessGroupShortDesc,
    additionalAccounts
  );

  useEffect(() => {
    if (businessGroup && currentPlanningCycle) {
      const templateColumns = getForecastTemplateHeaderInfo(businessGroup, currentPlanningCycle);
      setForecastTemplateColumnInfo(templateColumns);
      setForecastTemplateColDef(props.forecastColDef);
    }
  }, [props.forecastColDef]);

  useEffect(() => {
    const mutableForecastRowData = deepClone(props.forecastGridData);

    setForecastTemplateGridData(mutableForecastRowData);
    setOriginalData(
      new Map(mutableForecastRowData.map((row) => [row[ForecastGridConstants.ForecastGridFixedFields.XptLineItemSeqId.value]!, { ...row }]))
    );

    setGridDataLoaded(true);
    dispatch(setForecastTemplateDataStatus({ dataStatus: ForecastGridConstants.ForecastTemplateDataStatuses.Latest }));
    dispatch(setForecastDataSubmitClickCount(0));
    submitClickCount = 0;
  }, [props.forecastGridData]);

  const sideBarConfigRef = useRef<SideBarDef | null>(null);

  if (!sideBarConfigRef.current) {
    sideBarConfigRef.current = {
      ...ForecastGridConstants.SideBar,
      toolPanels: [...(ForecastGridConstants.SideBar.toolPanels || [])]
    };
  }

  const getRowId = (params: GetRowIdParams) => params.data[ForecastGridConstants.ForecastGridFixedFields.RowId.value];

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      restoreGrid();
      autoSizeAll(false);
    },
    [gridStateKey]
  );

  const restoreGrid = () => {
    setTimeout(() => {
      restoreGridState();
      gridRef?.current?.api.refreshCells();
    }, 0);
  };

  const onColumnGroupOpened = useCallback((params: ColumnGroupOpenedEvent) => {
    autoSizeAll(false);
  }, []);

  const autoSizeAll = useCallback((skipHeader: boolean) => {
    if (!atLeastOneStateAvailable()) {
      const allColumnIds: string[] = [];
      gridRef?.current?.api
        .getColumns()
        ?.filter((column) => !column.getId().toLocaleLowerCase().includes('description')) // Ignore Description like columns
        ?.forEach((column) => {
          allColumnIds.push(column.getId());
        });
      gridRef?.current?.api.autoSizeColumns(allColumnIds, skipHeader);
      gridRef?.current?.api.refreshCells();
    }
  }, []);

  const expandAllColumnGroups = () => {
    if (gridRef.current) {
      const allColumnGroups = gridRef.current.api.getAllDisplayedColumnGroups() || [];

      allColumnGroups.forEach((group: any) => {
        if (group.getChildren().length > 0) {
          gridRef?.current?.api.setColumnGroupOpened(group.getGroupId(), true);
        }
      });

      // Refresh the grid after expanding column groups
      gridRef.current.api.refreshHeader();
    }
  };

  /**
   * Handles adding a new row to the grid.
   * It initializes the new row with specific properties and adds it to the top of the grid.
   */
  const handleAddNewRow = () => {
    if (!gridRef.current) {
      return;
    }

    if (businessGroup && currentPlanningCycle) {
      // Initialize the newRow object with specific properties
      const newRow: ForecastGridRowData = {
        [ForecastGridConstants.ForecastGridFixedFields.RowId.value]: generateUniqueId(), // Generate a unique ID for the row
        [ForecastGridConstants.ForecastGridFixedFields.IsNewFERow.value]: true,
        [ForecastGridConstants.ForecastGridFixedFields.IsTouched.value]: true,
        [ForecastGridConstants.ForecastGridFixedFields.IsEdited.value]: true,
        [ForecastGridConstants.ForecastGridFixedFields.IsActive.value]: true,
        [ForecastGridConstants.ForecastGridFixedFields.IsSegmentEdited.value]: true,

        [ForecastGridConstants.ForecastGridFixedFields.XptLineItemId.value]: null,
        [ForecastGridConstants.ForecastGridFixedFields.XptLineItemSeqId.value]: null,
        [ForecastGridConstants.ForecastGridFixedFields.ScenarioSeqId.value]: currentPlanningCycle?.scenario_seq_id,
        [ForecastGridConstants.ForecastGridFixedFields.IsNew.value]: true,

        [ForecastGridConstants.ForecastGridFixedFields.BudgetOwner.value]: userAlias,
        [ForecastGridConstants.ForecastGridFixedFields.BudgetType.value]: null,
        [ForecastGridConstants.ForecastGridFixedFields.UpdatedAt.value]: getCurrentUTCTimeInISO(),
        [ForecastGridConstants.ForecastGridFixedFields.UpdatedBy.value]: userAlias
      };

      // Populate the newRow object based on the columns, ensuring not to overwrite the initialized properties
      forecastTemplateColumnInfo?.addNewRowColumns?.forEach((colName) => {
        if (newRow[colName] === undefined) {
          newRow[colName] = null;
        }
      });

      // Add the new row at the top of the grid and get the response from the transaction
      const transactionResult = addNewRowToGrid(gridRef, newRow, 0);

      // Ensure the newly added row is visible and set focus to the first cell
      if (transactionResult?.add) {
        const addedRowNode = transactionResult.add[0];
        if (addedRowNode && addedRowNode.rowIndex != null) {
          ensureRowVisibleAndFocusFirstCell(gridRef, addedRowNode.rowIndex);
        }
      }

      logUserClick('add new row', `Forecast Input`, businessGroupName);
      expandAllColumnGroups();
      debouncedSynchronizedData();
      debouncedValidateData();
    }
  };

  /**
   * Handles deletion of selected rows in the grid.
   * It updates the state and grid to reflect the removed rows.
   */
  const handleDeleteSelectedRows = () => {
    // Retrieve the selected rows from the grid
    const selectedRows = gridRef.current?.api?.getSelectedRows();

    // Proceed if there are selected rows
    if (selectedRows && selectedRows.length > 0) {
      // Identifying rows for deletion
      const removedRows = selectedRows
        .filter((row: ForecastGridRowData) => !row[ForecastGridConstants.ForecastGridFixedFields.IsNewFERow.value])
        .map((row: ForecastGridRowData) => {
          // In the case of deleting an existing row, to avoid saving modified content in the deleted row, the row should be deleted without saving any changes.
          const originalRow = originalData.get(row[ForecastGridConstants.ForecastGridFixedFields.XptLineItemSeqId.value]!);
          const currentRow = originalRow ? originalRow : row;
          return {
            ...currentRow,
            [ForecastGridConstants.ForecastGridFixedFields.IsTouched.value]: true,
            [ForecastGridConstants.ForecastGridFixedFields.IsEdited.value]: true,
            [ForecastGridConstants.ForecastGridFixedFields.IsActive.value]: false,
            [ForecastGridConstants.ForecastGridFixedFields.UpdatedAt.value]: getCurrentUTCTimeInISO(),
            [ForecastGridConstants.ForecastGridFixedFields.UpdatedBy.value]: userAlias
          };
        });

      // Update state to reflect the removed rows
      setForecastRowsRemoved((prevRemovedRows) => [...prevRemovedRows, ...removedRows]);

      // Remove the selected rows from the grid
      gridRef.current?.api?.applyTransaction({ remove: selectedRows });

      logUserClick('delete selected row', `Forecast Input`, businessGroupName);
      debouncedSynchronizedData();
      debouncedValidateData();
    }
  };

  /**
   * Handles cell value changes in the grid.
   * It updates the metadata of the row if it is modified.
   */
  const onCellValueChanged = useCallback(
    async (cellValueChangedEvent: CellValueChangedEvent) => {
      const { data: newData, colDef } = cellValueChangedEvent;
      const nameOfTheFieldChanged = colDef.field;
      console.debug('Cell value changed:', nameOfTheFieldChanged);

      let latestBudgetType = newData[ForecastGridConstants.ForecastGridFixedFields.BudgetType.value];
      if (nameOfTheFieldChanged === CorpSegmentNames.ACCOUNT || nameOfTheFieldChanged === CorpSegmentNames.ACCOUNT_DESCRIPTION) {
        const isAccountDescriptionChanged = nameOfTheFieldChanged === CorpSegmentNames.ACCOUNT_DESCRIPTION;
        latestBudgetType = updateBudgetTypeIfAccountChanged(
          cellValueChangedEvent,
          isAccountDescriptionChanged,
          enhancedMasterCorpSegmentDropdowns,
          accountBudgetTypeMapping
        );
      }

      const xptLineItemSeqId = newData[ForecastGridConstants.ForecastGridFixedFields.XptLineItemSeqId.value];
      const originalRow = originalData.get(xptLineItemSeqId!);

      // If the row exists, check if a cell value is actually modified or reverted to the original value.
      if (originalRow) {
        const isModified = isForecastGridRowModified(originalRow, newData);
        const isSegmentEdited = isSegmentModified(originalRow, newData, forecastTemplateColumnInfo.corpAndBussSegmentsMandatoryFields);

        const updatedRow = {
          ...newData,
          [ForecastGridConstants.ForecastGridFixedFields.IsTouched.value]: isModified,
          [ForecastGridConstants.ForecastGridFixedFields.IsEdited.value]: isModified,
          [ForecastGridConstants.ForecastGridFixedFields.IsSegmentEdited.value]: isSegmentEdited,

          [ForecastGridConstants.ForecastGridFixedFields.BudgetType.value]: latestBudgetType,

          [ForecastGridConstants.ForecastGridFixedFields.UpdatedBy.value]: userAlias,
          [ForecastGridConstants.ForecastGridFixedFields.UpdatedAt.value]: getCurrentUTCTimeInISO()
        };
        gridRef.current?.api?.applyTransaction({ update: [updatedRow] });
      }

      // New Row
      else {
        const updatedRow = {
          ...newData,
          [ForecastGridConstants.ForecastGridFixedFields.IsTouched.value]: true,
          [ForecastGridConstants.ForecastGridFixedFields.IsEdited.value]: true,
          [ForecastGridConstants.ForecastGridFixedFields.IsSegmentEdited.value]: true,

          [ForecastGridConstants.ForecastGridFixedFields.BudgetType.value]: latestBudgetType,

          [ForecastGridConstants.ForecastGridFixedFields.UpdatedAt.value]: getCurrentUTCTimeInISO(),
          [ForecastGridConstants.ForecastGridFixedFields.UpdatedBy.value]: userAlias
        };
        gridRef.current?.api?.applyTransaction({ update: [updatedRow] });
      }

      // Trigger debounced synchronization and validation of the data.
      debouncedSynchronizedData();
      debouncedValidateData();
    },
    [originalData]
  );

  // Function to synchronize state with grid data
  const synchronizeGridData = useCallback(() => {
    const api = gridRef.current?.api;
    const updatedRowData: ForecastGridRowData[] = [];
    api?.forEachNode((node) => {
      updatedRowData.push(node.data);
    });
    setForecastTemplateGridData(updatedRowData);
  }, []);
  const debouncedSynchronizedData = debounce(synchronizeGridData, 500);

  const hasChanges = useMemo(() => {
    const changesDetected =
      forecastTemplateGridData.some((item) => item[ForecastGridConstants.ForecastGridFixedFields.IsTouched.value]) || forecastRowsRemoved.length > 0;

    if (changesDetected) {
      dispatch(setForecastTemplateDataStatus({ dataStatus: ForecastGridConstants.ForecastTemplateDataStatuses.Modified }));
    } else {
      dispatch(setForecastTemplateDataStatus({ dataStatus: ForecastGridConstants.ForecastTemplateDataStatuses.Latest }));
    }
    return changesDetected;
  }, [forecastTemplateGridData, forecastRowsRemoved]);

  const validateData = async () => {
    if (businessGroup && currentPlanningCycle && userAccessForCurrentBusinessGroup) {
      console.time('ForecastTemplateDataValidation');

      const api = gridRef.current?.api;
      const updatedRowData: ForecastGridRowData[] = [];
      api?.forEachNode((node) => {
        updatedRowData.push(node.data);
      });

      const dirtyRows = updatedRowData.filter((row) => {
        return row[ForecastGridConstants.ForecastGridFixedFields.IsEdited.value];
      });

      const segmentEditedRows = updatedRowData.filter((row) => {
        return row[ForecastGridConstants.ForecastGridFixedFields.IsSegmentEdited.value];
      });

      logger.info(`Validating Dirty Rows of ${dirtyRows?.length} & Segment Edited rows of ${segmentEditedRows?.length}`);

      const mandatoryFieldValidation = await validateMandatoryFields(dirtyRows, forecastTemplateColumnInfo);
      const unauthorizedRowsValidation = await validateUnAuthorizedRows(dirtyRows, userAlias, isFinanceAdminOrFinanceOwner || isBudgetLeader);
      const segmentValidation = await validateSegments(
        dirtyRows,
        forecastTemplateColumnInfo,
        enhancedMasterCorpSegmentDropdowns,
        masterBusinessSegments,
        expenseTypesForCurrentGroup
      );
      const duplicateRecordValidationResult = await validateDuplicateRecords(forecastTemplateGridData, forecastTemplateColumnInfo);
      const validateBudgetTypeAndAccountCombinationResult = await validateBudgetTypeAndAccountCombination(dirtyRows, accountBudgetTypeMapping);
      const validateBudgetOwnerAndCostCenterCombinationResult = await validateBudgetOwnerAndCostCenterCombination(
        dirtyRows,
        xptUsersOfCurrentDataClassification,
        userAccessForCurrentBusinessGroup
      );

      dispatch(
        setValidationStatus({
          HeadersMatching: {
            validationStatus: 'success',
            colorOverride: 'green',
            validationMessage: ForecastValidationMessages.HEADER_VALIDATION_SUCCESS,
            validationDefaultMessage: '',
            validationErrorDetails: []
          },
          BudgetOwnerValidation: {
            validationStatus: 'success',
            colorOverride: 'green',
            validationMessage: ForecastValidationMessages.BUDGET_OWNER_VALIDATION_SUCCESS,
            validationDefaultMessage: '',
            validationErrorDetails: []
          },
          CostCenterValidation: {
            validationStatus: 'success',
            colorOverride: 'green',
            validationMessage: ForecastValidationMessages.CC_AUTHORIZATION_VALIDATION_SUCCESS,
            validationDefaultMessage: '',
            validationErrorDetails: []
          },
          ForecastMonthValidation: {
            validationStatus: 'success',
            colorOverride: 'green',
            validationMessage: ForecastValidationMessages.FORECAST_MONTH_DATA_VALIDATION_SUCCESS,
            validationDefaultMessage: '',
            validationErrorDetails: []
          },
          MandatoryFieldValidation: mandatoryFieldValidation,
          NonEditableFieldValidations: {
            validationStatus: 'success',
            colorOverride: 'green',
            validationMessage: ForecastValidationMessages.NON_EDITABLE_FIELD_VALIDATION_SUCCESS,
            validationDefaultMessage: '',
            validationErrorDetails: []
          },
          UnAuthorizedRows: unauthorizedRowsValidation,
          SegmentsValidation: segmentValidation,
          DuplicateRecordValidation: duplicateRecordValidationResult,
          RepeatedRecordValidation: {
            validationStatus: 'success',
            colorOverride: 'green',
            validationMessage: ForecastValidationMessages.NO_EXISTING_RECORDS,
            validationDefaultMessage: '',
            validationErrorDetails: []
          },
          BudgetTypeAccountMappingValidation: validateBudgetTypeAndAccountCombinationResult,
          BudgetOwnerCostCenterValidation: validateBudgetOwnerAndCostCenterCombinationResult
        })
      );

      console.timeEnd('ForecastTemplateDataValidation');
      return (
        mandatoryFieldValidation.validationStatus === 'success' &&
        segmentValidation.validationStatus === 'success' &&
        unauthorizedRowsValidation.validationStatus === 'success' &&
        duplicateRecordValidationResult.validationStatus === 'success' &&
        validateBudgetTypeAndAccountCombinationResult.validationStatus === 'success' &&
        validateBudgetOwnerAndCostCenterCombinationResult.validationStatus === 'success'
      );
    }
  };

  // Create a debounced version of validateData
  const debouncedValidateData = debounce(validateData, 500);

  useEffect(() => {
    return () => {
      // Cleanup debounced function on component unmount
      debouncedValidateData.cancel();
    };
  }, []);

  const incrementSubmitClickCount = () => {
    submitClickCount++;
    dispatch(setForecastDataSubmitClickCount(submitClickCount));
  };

  /**
   * Marks all touched rows as edited.
   * @param forecastTemplateFilteredData - The current state data.
   * @returns A promise that resolves to the updated data with touched rows marked as edited.
   */
  const markTouchedRowsAsEdited = async (forecastTemplateFilteredData: ForecastGridRowData[]): Promise<ForecastGridRowData[]> => {
    return Promise.resolve(
      forecastTemplateFilteredData.map((item) => {
        if (item[ForecastGridConstants.ForecastGridFixedFields.IsTouched.value]) {
          return {
            ...item,
            [ForecastGridConstants.ForecastGridFixedFields.IsEdited.value]: true
          };
        }
        return item;
      })
    );
  };

  const handleSubmit = async () => {
    if (!businessGroup || !currentPlanningCycle) {
      logger.error('Business group or planning cycle data is missing.');
      return;
    }

    incrementSubmitClickCount();
    const updatedData = await markTouchedRowsAsEdited(forecastTemplateGridData);
    setForecastTemplateGridData(updatedData); // Update state with marked data

    setTimeout(async () => {
      const editedRows = forecastTemplateGridData.filter((row) => {
        return row[ForecastGridConstants.ForecastGridFixedFields.IsEdited.value];
      });
      const dirtyRows = editedRows.concat(...forecastRowsRemoved);

      if (dirtyRows.length > 0) {
        const validationPassed = await validateData();
        if (!validationPassed) return;

        await submitForecast(dirtyRows);
      } else {
        handleNoChanges();
      }
    }, 500); // Delay to ensure debounced sync is complete
  };

  const submitForecast = async (dirtyRows: any[]) => {
    if (!businessGroup || !currentPlanningCycle || !currentCorpSegmentDropDownSelections) {
      logger.error('Business group or planning cycle data is missing.');
      return;
    }

    const inProgressMessageId = generateUniqueId();
    notificationMessage(XptMessages.FORECAST_UPDATE_IN_PROGRESS(currentPlanningCycle.scenario_year), 'info', false, inProgressMessageId);
    dispatch(setForecastDataSubmitting(true));

    const newlyAddedSelections = getNewlyAddedSelections(dirtyRows, currentCorpSegmentDropDownSelections, enhancedMasterCorpSegmentDropdowns);
    const forecastTemplateDataForUpload = prepareForecastTemplateUploadData(dirtyRows, currentPlanningCycle, businessGroup);

    try {
      logUserClick('submit button', `Forecast Input`, businessGroupName);
      const message = await submitForecastData({
        userAlias: userAlias,
        businessGroup,
        currentPlanningCycle,
        forecastTemplateDataForUpload,
        submitOperationType: OperationType.CRUD
      });
      clearSpecificFlashMessage(inProgressMessageId);
      notificationMessage(message, 'success', true);
      dispatch(setForecastDataSubmitting(false));
      setLocalStorageItem(getLocalStorageKeyForCorpSegments(currentPlanningCycle.scenario_seq_id!, businessGroupShortDesc), newlyAddedSelections);
      dispatch(setCurrentCorpSegmentDropDownSelections(newlyAddedSelections));
      dispatch(fetchForecastTemplateCompleteData(currentPlanningCycle));
    } catch (error: any) {
      handleForecastSubmissionError(error, inProgressMessageId);
    }
  };

  const handleForecastSubmissionError = (error: any, inProgressMessageId: string) => {
    logger.error(error);
    dispatch(setForecastDataSubmitting(false));
    clearSpecificFlashMessage(inProgressMessageId);
    notificationMessage(XptMessages.FORECAST_UPDATE_FAILED, 'error', true);
  };

  const handleNoChanges = () => {
    notificationMessage(XptMessages.FORECAST_UPDATE_NO_CHANGES, 'info', true);
    dispatch(setForecastTemplateDataStatus({ dataStatus: ForecastGridConstants.ForecastTemplateDataStatuses.Latest }));
    dispatch(setForecastDataSubmitClickCount(0));
    submitClickCount = 0;
  };

  // Memoize the isRowSelectable function to prevent unnecessary re-renders
  // Defines if a row is selectable based on user authorization.
  const isRowSelectable = useCallback(
    (node: any): boolean => {
      const rowData: any = node.data;
      return isUserAuthorizedToEditThisRow(rowData, userAlias, isFinanceAdminOrFinanceOwner, isBudgetLeader);
    },
    [userAlias, isFinanceAdminOrFinanceOwner, isBudgetLeader]
  );

  const selectionChanged = () => {
    setSelectedRowCount(gridRef.current!.api.getSelectedNodes().length);
  };

  const handleReloadConfirmDiscard = () => {
    setShowReloadModal(false);
    dispatch(fetchForecastTemplateCompleteData(currentPlanningCycle!));
  };

  const handleReloadCancelDiscard = () => {
    setShowReloadModal(false);
  };

  const forecastGridFileActionsClicked = (id: string) => {
    const isDataModified = forecastDataStatus.dataStatus.status === ForecastGridConstants.ForecastTemplateDataStatuses.Modified.status;

    switch (id) {
      case 'discard_all_changes':
        logUserClick('Refreshed Forecast Input', `Forecast Input`, businessGroupName);
        if (isDataModified) {
          setShowReloadModal(true);
        } else {
          dispatch(fetchForecastTemplateCompleteData(currentPlanningCycle!));
        }
        break;
      case 'ag_grid_export_to_excel':
        logUserClick('Exported to Excel', `Forecast Input`, businessGroupName);
        exportToExcel();
        break;
      case 'import_from_excel':
        setShowFileImportModal(true);
        break;
      case 'save-view':
        logUserClick('Saved current view', `Forecast Input`, businessGroupName);
        saveGridState();
        const messageId = generateUniqueId();
        notificationMessage('View saved successfully', 'success', true);
        setTimeout(() => clearSpecificFlashMessage(messageId), 3000);
        break;
      case 'reset-view':
        logUserClick('reset to default view', `Forecast Input`, businessGroupName);
        if (isDataModified) {
          setShowResetViewModal(true);
        } else {
          clearGridState();
          dispatch(fetchForecastTemplateCompleteData(currentPlanningCycle!));
        }
        break;
      default:
        logger.warn(`No handler defined for action ${id}`);
    }
  };

  const handleConfirmDiscard = () => {
    setShowResetViewModal(false);
    clearGridState();
    dispatch(fetchForecastTemplateCompleteData(currentPlanningCycle!));
  };

  const handleCancelDiscard = () => {
    setShowResetViewModal(false);
  };

  const onCancelFileImport = () => {
    setShowFileImportModal(false);
  };

  const onConfirmingFileImport = () => {
    setShowFileImportModal(false);
    currentPlanningCycle && dispatch(fetchForecastTemplateCompleteData(currentPlanningCycle));
  };

  const onChangeOfUserEvents = (eventName: string) => {
    logUserClick(eventName, `Forecast Input`, businessGroupName);
  };

  const handleCloneRow = (clonedNewRow: ForecastGridRowData) => {
    // Add the new row at the top of the grid and get the response from the transaction
    const transactionResult = addNewRowToGrid(gridRef, clonedNewRow, 0);

    // Ensure the newly added row is visible and set focus to the first cell
    if (transactionResult?.add) {
      const addedRowNode = transactionResult.add[0];
      if (addedRowNode && addedRowNode.rowIndex != null) {
        ensureRowVisibleAndFocusFirstCell(gridRef, addedRowNode.rowIndex);
      }
    }

    expandAllColumnGroups();
    debouncedSynchronizedData();
    debouncedValidateData();
  };

  const getContextMenuItems = useCallback(
    (contextParams: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      var result: (string | MenuItemDef)[] = [
        {
          name: 'Clone',
          subMenu: [
            {
              name: 'Clone this row with Forecast values',
              action: (params: IMenuActionParams<any, any>) => {
                const clonedRow = cloneForecastInputRow(params.node?.data, forecastTemplateColumnInfo, true, userAlias);
                logUserClick(`Cloned with Forecast values`, `Forecast Input`, businessGroupName);
                handleCloneRow(clonedRow);
              }
            },
            {
              name: 'Clone this row without Forecast values',
              action: (params: IMenuActionParams<any, any>) => {
                const clonedRow = cloneForecastInputRow(params.node?.data, forecastTemplateColumnInfo, false, userAlias);
                logUserClick(`Cloned with without Forecast values`, `Forecast Input`, businessGroupName);
                handleCloneRow(clonedRow);
              }
            }
          ]
        }
      ];
      return !isCycleLocked ? result : [];
    },
    [forecastTemplateColumnInfo, isCycleLocked]
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {showLoadingSpinner && <LoadingSpinner />}
      {!showLoadingSpinner && (
        <>
          <ForecastTemplateFileUpload
            gridRef={gridRef}
            showModal={showFileImportModal}
            onCancel={onCancelFileImport}
            onSuccessConfirm={() => onConfirmingFileImport()}
          />
          <ConfirmDiscardModal visible={showResetViewModal} onConfirm={handleConfirmDiscard} onCancel={handleCancelDiscard} />
          <ConfirmDiscardModal visible={showReloadModal} onConfirm={handleReloadConfirmDiscard} onCancel={handleReloadCancelDiscard} />
          <Container
            disableContentPaddings
            header={
              <Header
                actions={
                  currentPlanningCycle && (
                    <SpaceBetween size="s" direction="horizontal">
                      <Button iconName="refresh" onClick={() => forecastGridFileActionsClicked('discard_all_changes')} />
                      <ButtonDropdown
                        variant="normal"
                        onItemClick={({ detail }) => forecastGridFileActionsClicked(detail.id)}
                        items={forecastGridFileActions(isCycleLocked, hasChanges)}
                      >
                        {'Actions'}
                      </ButtonDropdown>
                      <>
                        <Button iconName="insert-row" disabled={isCycleLocked} onClick={handleAddNewRow}>
                          {'Add'}
                        </Button>
                        <Button iconName="delete-marker" disabled={isCycleLocked || selectedRowCount === 0} onClick={handleDeleteSelectedRows}>
                          {'Delete'}
                        </Button>
                        <Button variant="primary" disabled={isCycleLocked || !hasChanges} onClick={handleSubmit}>
                          {`Submit`}
                        </Button>
                      </>
                    </SpaceBetween>
                  )
                }
              >
                <SpaceBetween size="s" direction="horizontal">
                  {<ForecastTemplateErrorDisplay />}
                </SpaceBetween>
              </Header>
            }
          >
            <div className="ag-grid-container">
              <div className={themeClassName} style={gridStyle}>
                <ErrorBoundary
                  FallbackComponent={() => (
                    <Alert
                      type="error"
                      dismissible={false}
                      visible={true}
                      header="Error"
                      action={<Button onClick={() => window.location.reload()}>Reload</Button>}
                    >
                      {'Unable to load grid data, try reloading once'}
                    </Alert>
                  )}
                >
                  {!gridDataLoaded && <LoadingSpinner />}
                  {gridDataLoaded && (
                    <AgGridReact
                      ref={gridRef}
                      onGridReady={onGridReady}
                      onColumnGroupOpened={onColumnGroupOpened}
                      onSelectionChanged={selectionChanged}
                      getRowId={getRowId}
                      onCellValueChanged={onCellValueChanged}
                      onCellEditingStopped={handleCellEditingStopped}
                      defaultColDef={ForecastGridConstants.DefaultColDef}
                      columnDefs={forecastTemplateColDef}
                      rowData={forecastTemplateGridData}
                      rowSelection={'multiple'}
                      isRowSelectable={isRowSelectable}
                      sideBar={sideBarConfigRef.current}
                      onFilterChanged={() => onChangeOfUserEvents(`side panel filter changed`)}
                      onPasteEnd={() => onChangeOfUserEvents(`Used clipboard copy-paste`)}
                      icons={ForecastGridConstants.forecastDataGridIcons}
                      suppressRowClickSelection={true}
                      enableRangeHandle={true}
                      enableRangeSelection={true}
                      suppressMultiRangeSelection={true}
                      rowGroupPanelShow={ForecastGridConstants.RowGroupPanelShow}
                      noRowsOverlayComponent={XptGridOverlay}
                      enableAdvancedFilter={false}
                      alwaysMultiSort={true}
                      excelStyles={ForecastGridConstants.forecastExcelStyles}
                      statusBar={StatusBarConfig}
                      rowBuffer={10}
                      rowModelType="clientSide"
                      rowHeight={30}
                      tooltipShowDelay={0}
                      getContextMenuItems={getContextMenuItems}
                      undoRedoCellEditing
                      undoRedoCellEditingLimit={20}
                      enterNavigatesVerticallyAfterEdit
                      enterNavigatesVertically
                      processCellForClipboard={processCellForClipboard}
                      processCellFromClipboard={processCellFromClipboard}
                      suppressLastEmptyLineOnPaste
                      grandTotalRow="bottom"
                      suppressAggFuncInHeader={true}
                      suppressRowGroupHidesColumns
                    />
                  )}
                </ErrorBoundary>
              </div>
            </div>
          </Container>
        </>
      )}
    </ErrorBoundary>
  );
};
