import { Header } from '@amzn/awsui-components-react';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from 'src/components/common/ErrorFallback';
import XptWidgetTable from 'src/components/common/xpt-table/XptWidgetTable';
import { WidgetContainer } from '../WidgetContainer';
import { ACCOUNT_HIERARCHY_COLUMNS, ACCOUNT_HIERARCHY_DATA } from './constants/AccountHierarchy';

const XptAccountHierarchyInfo: React.FC = () => {
  const renderContent = () => {
    return (
      <XptWidgetTable
        variant="embedded"
        loadingStatus={false}
        allItems={ACCOUNT_HIERARCHY_DATA}
        columnDefinitions={ACCOUNT_HIERARCHY_COLUMNS}
        hasPagination={false}
        itemsPerPage={6}
      />
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <WidgetContainer height="120px" header={<Header variant="h3">xPT Account Hierarchy</Header>}>
        {renderContent()}
      </WidgetContainer>
    </ErrorBoundary>
  );
};

export default XptAccountHierarchyInfo;
