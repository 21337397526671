import { SideNavigation } from '@amzn/awsui-components-react';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'src/app/auth/AuthContextProvider';
import { findActiveHref } from 'src/utils/navigation-utilities';
import { selectIsFinanceAdmin, selectIsFinanceAdminForOperations } from '../../business-group/access-authorization/redux/userAuthorizationSelectors';
import { buildNavigationItems, createNavigationHandler } from './utils/NavigationHelpers';

interface NavigationConfig {
  isDev: boolean;
  isFinanceAdmin: boolean;
  isFinanceAdminOps: boolean;
}

/**
 * Side Navigation component for Admin Console
 * Renders navigation items based on user permissions
 */
const AdminConsoleSideNavigation: React.FC = () => {
  const { isDev } = useAuth();
  const isFinanceAdmin = useSelector(selectIsFinanceAdmin);
  const isFinanceAdminOps = useSelector(selectIsFinanceAdminForOperations);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const navigationConfig: NavigationConfig = {
    isDev,
    isFinanceAdmin,
    isFinanceAdminOps
  };

  const sideNavigationItems = useMemo(() => buildNavigationItems(navigationConfig), [isDev, isFinanceAdmin, isFinanceAdminOps]);

  const handleNavigation = createNavigationHandler(navigate);
  const activeHref = findActiveHref(pathname, sideNavigationItems);

  return (
    <nav aria-label="Admin Console Navigation" role="navigation">
      <SideNavigation
        data-testid="admin-console-side-navigation"
        activeHref={activeHref}
        onFollow={handleNavigation}
        items={sideNavigationItems}
        id="admin-console-nav"
      />
    </nav>
  );
};

export default AdminConsoleSideNavigation;
