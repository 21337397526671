import { SideNavigationProps } from '@amzn/awsui-components-react';
import { NavigationConfig, NavigationLink } from '../types/Navigation.types';

/**
 * Constants for navigation builder
 */
export const NAVIGATION_MESSAGES = {
  ERROR_CONTEXT: 'Error building navigation items',
  NAVIGATION_ERROR: 'Navigation error',
  EMPTY_ARRAY: [] as ReadonlyArray<SideNavigationProps.SectionGroup>
} as const;

/**
 * Section divider for navigation menu
 * @constant
 */
export const SECTION_DIVIDER: Readonly<SideNavigationProps.Divider> = {
  type: 'divider'
} as const;

/**
 * Application Monitor section configuration
 * @constant
 */
export const APPLICATION_MONITOR_SECTION: Readonly<SideNavigationProps.Section> = {
  type: 'section',
  text: 'Application Monitor',
  items: [
    {
      type: 'link',
      text: 'User Visits',
      href: '/admin-console/user-visits'
    },
    {
      type: 'link',
      text: 'API Stats',
      href: '/admin-console/api-stats'
    }
  ]
} as const;

/**
 * CapEx navigation link configuration
 * @constant
 */
export const CAPEX_LINK: Readonly<NavigationLink> = {
  type: 'link',
  text: 'CapEx File Upload',
  href: '/admin-console/capex',
  'aria-label': 'Upload CapEx Files',
  role: 'menuitem'
} as const;

/**
 * Main navigation configuration
 * Implements WCAG 2.1 requirements:
 * - Keyboard accessibility (2.1.1)
 * - Name/Role/Value (4.1.2)
 * - Navigation Structure (2.4.5)
 * @constant
 */
export const NAVIGATION_CONFIG: Readonly<NavigationConfig> = {
  financeAdmin: {
    title: 'Finance Admin Tools',
    'aria-label': 'Finance Administration Tools Section',
    role: 'navigation',
    items: [
      {
        type: 'link',
        text: 'Planning Cycles',
        href: '/admin-console/planning-cycle',
        'aria-label': 'Manage Planning Cycles',
        role: 'menuitem'
      },
      {
        type: 'link',
        text: 'Project Mapping',
        href: '/admin-console/project',
        'aria-label': 'Manage Project Mapping',
        role: 'menuitem'
      }
    ]
  },
  developer: {
    title: 'Developer Tools',
    'aria-label': 'Developer Tools Section',
    role: 'navigation',
    items: [
      {
        type: 'link',
        text: 'Business Groups',
        href: '/admin-console/onboarding',
        'aria-label': 'Manage Business Groups',
        role: 'menuitem'
      },
      {
        type: 'link',
        text: 'Audit Log',
        href: '/admin-console/audit-log',
        'aria-label': 'View Audit Logs',
        role: 'menuitem'
      }
    ]
  }
} as const;
