import { FlashbarProps, SelectProps } from '@amzn/awsui-components-react';
import { useCallback, useState } from 'react';
import { logger } from 'src/analytics/KatalLogger';
import { Status } from 'src/models/AuthContextModels';
import { POTaggingEntity } from 'src/models/POTaggingModel';
import { generateExcelFile } from 'src/utils/file-utils';
import { generateUniqueId } from 'src/utils/generic-utilities';
import { getActualsS3BucketName } from 'src/utils/xpt-s3-bucket-details';
import { processActualsAndPODataForDownload } from '../utils/GenerateDistributionReport';
import { filterS3KeysBasedOnCC } from '../utils/XptActualsReportGridColumnHelper';
import { useS3DataFetcher } from './useS3DataFetcher';
import { ACTUALS_REPORT_MESSAGES } from '../utils/ActualsMessageConstants';

export interface UseDistributionReportProps {
  selectedCostCenter: SelectProps.Option | null;
  distributionDataS3Keys: string[];
  actualsData: POTaggingEntity[];
  actualsDataStatus: Status;
  businessGroupName: string;
  dataClassificationShortDesc: string;
  dataClassificationId: number;
  notifyFlashMessage: (content: string, flashBarType: FlashbarProps.Type, isDismissible: boolean, messageId?: string) => void;
  clearSpecificFlashMessage: (messageId: string) => void;
}

interface UseDistributionReportReturn {
  handleDistributionDownload: () => Promise<void>;
  distributionReportStatus: Status;
}

export const useDistributionReport = ({
  selectedCostCenter,
  distributionDataS3Keys,
  actualsData,
  actualsDataStatus,
  businessGroupName,
  dataClassificationShortDesc,
  dataClassificationId,
  notifyFlashMessage,
  clearSpecificFlashMessage
}: UseDistributionReportProps): UseDistributionReportReturn => {
  const [distributionReportStatus, setDistributionReportStatus] = useState<Status>(Status.NotInitiated);

  const { fetchDataFromS3 } = useS3DataFetcher({
    bucketName: getActualsS3BucketName().bucketName,
    notifyFlashMessage
  });

  const handleDistributionDownload = useCallback(async () => {
    if (!selectedCostCenter) return;

    if (actualsDataStatus === Status.Failed) {
      notifyFlashMessage(ACTUALS_REPORT_MESSAGES.REPORT.DISTRIBUTION.NO_ACTUALS_ERROR, 'error', true);
      return;
    }

    const messageId = generateUniqueId();
    setDistributionReportStatus(Status.Loading);

    try {
      notifyFlashMessage('Generating Distribution Report', 'info', false, messageId);

      const selectedCC = selectedCostCenter.value || '';
      const s3KeysForSelectedCCs = filterS3KeysBasedOnCC(distributionDataS3Keys, [selectedCC]);

      const distributionData = await fetchDataFromS3({
        selectedCC,
        s3Keys: s3KeysForSelectedCCs,
        dataType: 'Distribution'
      });

      if (distributionData.length > 0) {
        const processedData = await processActualsAndPODataForDownload(
          actualsData,
          distributionData,
          businessGroupName,
          dataClassificationShortDesc,
          dataClassificationId,
          selectedCC
        );

        const fileNameWithoutExtension = `Detailed_PO_Report_${dataClassificationShortDesc}_${selectedCC}`;
        const sheetName = `${dataClassificationShortDesc}_${selectedCC}`;

        await generateExcelFile({ data: processedData, sheetName, fileNameWithoutExtension });

        logger.info('Distribution data Excel file generated successfully', {
          fileName: fileNameWithoutExtension,
          recordCount: processedData.length
        });
        setDistributionReportStatus(Status.Completed);
      } else {
        setDistributionReportStatus(Status.Failed);
        notifyFlashMessage('No distribution data found for the selected cost center', 'error', true);
      }

      clearSpecificFlashMessage(messageId);
    } catch (error: any) {
      setDistributionReportStatus(Status.Failed);
      clearSpecificFlashMessage(messageId);
      notifyFlashMessage('Error while generating Distribution Report', 'error', true);
      logger.error('Error generating distribution report', {
        error: error.message,
        costCenter: selectedCostCenter.value,
        dataClassification: dataClassificationShortDesc
      });
    }
  }, [
    selectedCostCenter,
    distributionDataS3Keys,
    actualsData,
    businessGroupName,
    dataClassificationShortDesc,
    dataClassificationId,
    notifyFlashMessage,
    clearSpecificFlashMessage,
    fetchDataFromS3
  ]);

  return { handleDistributionDownload, distributionReportStatus };
};
