/**
 * Constants and types for business groups
 * @file business-group-constants.ts
 */

/**
 * Represents all possible business group codes
 */
export const BUSINESS_GROUP_CODES = {
  OPERATIONS: 'OPS',
  HARDWARE: 'HDW',
  SOFTWARE: 'SW',
  DMM: 'DMM'
} as const;

/**
 * Type representing all possible business group code values
 */
export type BusinessGroupCode = (typeof BUSINESS_GROUP_CODES)[keyof typeof BUSINESS_GROUP_CODES];

/**
 * Interface for business group details
 */
export interface BusinessGroupDetails {
  code: BusinessGroupCode;
  name: string;
}

/**
 * Complete business group information mapping
 */
export const BUSINESS_GROUPS: Readonly<Record<BusinessGroupCode, BusinessGroupDetails>> = {
  [BUSINESS_GROUP_CODES.OPERATIONS]: {
    code: BUSINESS_GROUP_CODES.OPERATIONS,
    name: 'OPD Operations'
  },
  [BUSINESS_GROUP_CODES.HARDWARE]: {
    code: BUSINESS_GROUP_CODES.HARDWARE,
    name: 'OPD Hardware'
  },
  [BUSINESS_GROUP_CODES.SOFTWARE]: {
    code: BUSINESS_GROUP_CODES.SOFTWARE,
    name: 'OPD Software'
  },
  [BUSINESS_GROUP_CODES.DMM]: {
    code: BUSINESS_GROUP_CODES.DMM,
    name: 'DMM'
  }
} as const;

// Ensure immutability
Object.freeze(BUSINESS_GROUP_CODES);
Object.freeze(BUSINESS_GROUPS);
