import { SideNavigationProps } from '@amzn/awsui-components-react';
import { APPLICATION_MONITOR_SECTION, CAPEX_LINK, NAVIGATION_CONFIG, NAVIGATION_MESSAGES } from '../constants/NavigationConfig';
import { NavigationConfig, NavigationHandlerProps, NavigationSection } from '../types/Navigation.types';
import { handleError } from 'src/analytics/KatalErrorLogger';
import { NavigateFunction } from 'react-router-dom';

/**
 * Interface for navigation builder parameters
 */
interface BuildNavigationItemsParams {
  isDev: boolean;
  isFinanceAdmin: boolean;
  isFinanceAdminOps: boolean;
}

/**
 * Creates a section group with proper accessibility attributes
 * @param section - Navigation section configuration
 * @param additionalItems - Optional additional navigation items
 */
const createSectionGroup = (
  section: NavigationSection,
  additionalItems: ReadonlyArray<SideNavigationProps.Link | SideNavigationProps.Section> = []
): SideNavigationProps.SectionGroup => ({
  type: 'section-group',
  title: section.title,
  items: [...section.items, ...additionalItems]
});

/**
 * Builds navigation items based on user roles and permissions
 * @param params - User role parameters
 * @returns Array of navigation section groups
 */
export const buildNavigationItems = ({
  isDev,
  isFinanceAdmin,
  isFinanceAdminOps
}: BuildNavigationItemsParams): ReadonlyArray<SideNavigationProps.SectionGroup> => {
  const config: NavigationConfig = NAVIGATION_CONFIG;
  const items: SideNavigationProps.SectionGroup[] = [];

  try {
    if (isDev) {
      const financeSection = createSectionGroup(config.financeAdmin, isFinanceAdminOps ? [CAPEX_LINK] : []);

      const developerSection = createSectionGroup(config.developer, [APPLICATION_MONITOR_SECTION]);

      items.push(financeSection, developerSection);
    } else if (isFinanceAdmin) {
      items.push(createSectionGroup(config.financeAdmin, isFinanceAdminOps ? [CAPEX_LINK] : []));
    }

    return items;
  } catch (error) {
    return handleError(error, NAVIGATION_MESSAGES.ERROR_CONTEXT, NAVIGATION_MESSAGES.EMPTY_ARRAY);
  }
};

export const createNavigationHandler = (navigate: NavigateFunction): ((event: CustomEvent<NavigationHandlerProps>) => void) => {
  return (event: CustomEvent<NavigationHandlerProps>): void => {
    try {
      if (!event.detail?.href) {
        throw new Error('Navigation event missing href');
      }

      if (!event.detail.external) {
        event.preventDefault();
        navigate(event.detail.href);
      }
    } catch (error) {
      return handleError(error, NAVIGATION_MESSAGES.NAVIGATION_ERROR, undefined);
    }
  };
};
