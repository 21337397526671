import { SelectProps } from '@amzn/awsui-components-react';
import moment from 'moment-timezone';
import { logger } from 'src/analytics/KatalLogger';
import { BusinessGroupEntity, DateAbsoluteRangePicker, DropdownModel } from 'src/models/AppContextModels';
import { AdminPlanningCycleBusinessGroupsForm, AdminPlanningCycleForm, NonAdminPlanningCycleForm } from 'src/models/PlanningCycleFormModel';
import { PlanningCycleEntity, PlanningCycleFlatTable } from 'src/models/PlanningCycleModel';
import {
  convertPacificTimeToUTC,
  convertToUserLocalAndSplitDateTime,
  formatUTCAsLocalReadable,
  getCurrentUTCTimeInISO,
  getReadableFormatOfMonthDateYear,
  getReadableFormatOfMonthYear,
  getRemainingTimeDetails,
  getYearWithOffset
} from 'src/utils/date-time-utilities';
import { compareNullableNumbers } from 'src/utils/generic-utilities';
import { getForecastS3BucketName } from 'src/utils/xpt-s3-bucket-details';
import { getBusinessGroupByName } from '../business-group/BusinessGroupUtils';

export enum ReportingFilterTags {
  PLANNING_CYCLE = `Planning Cycle`,
  SNAPSHOT = `Snapshot`
}

/**
 * Transforms a PlanningCycle object into a selection option format suitable for dropdown menus.
 *
 * @param {PlanningCycleEntity | null | undefined} cycle - The PlanningCycle object to transform.
 * @returns {SelectProps.Option} An object representing a dropdown option with label and value properties.
 */
export const toPlanningCycleSelectOption = (cycle: PlanningCycleEntity | null | undefined): SelectProps.Option => {
  const defaultLabel = 'Select a cycle';
  return {
    label: cycle ? `${cycle.scenario.scenario_name}` : defaultLabel,
    value: cycle ? `${cycle.scenario_seq_id}` : '',
    description: cycle?.scenario.planning_cycle_name || ''
  };
};

/**
 *
 * @param {SelectProps.Option | null | undefined} option - A dropdown option
 * @param {PlanningCycleEntity[]} cycles - Planning Cycles
 * @returns {PlanningCycleEntity} Planning Cycle from selected Dropdown
 */
export const fromPlanningCycleSelectOption = (option: SelectProps.Option | null | undefined, cycles: PlanningCycleEntity[]): PlanningCycleEntity =>
  cycles.find((cycle) => String(cycle.scenario_seq_id) === option?.value)!;

/**
 * Converts PlanningCycleEntity array to AdminPlanningCycleForm
 * @param planningCycles - Array of planning cycle entities
 * @returns AdminPlanningCycleForm formatted data
 */
export const convertToAdminPlanningCycleForm = (planningCycles: PlanningCycleEntity[]): AdminPlanningCycleForm => {
  const firstPlanningCycle = planningCycles[0];

  return {
    ...createCommonFormFields(firstPlanningCycle),
    business_groups: planningCycles.map(createBusinessGroup)
  };
};

/**
 * Creates date range object for Cloudscape DateRangePicker
 */
export const createDateRange = (startDate: string | null, endDate: string | null): DateAbsoluteRangePicker | undefined => {
  if (!startDate || !endDate) return undefined;

  return {
    type: 'absolute',
    startDate,
    endDate
  };
};

/**
 * Creates dropdown model object
 */
export const createDropdownModel = (label: string | null | undefined, value: string | number | null | undefined): DropdownModel => ({
  label: label ?? '',
  value: `${value ?? ''}`
});

/**
 * Creates common form fields from first planning cycle
 */
export const createCommonFormFields = (planningCycle: PlanningCycleEntity) => ({
  scenario: createDropdownModel(planningCycle.scenario.scenario_name, planningCycle.scenario.scenario_id),
  scenario_year: planningCycle.scenario_year,
  planning_cycle_year: createDropdownModel(`${planningCycle.planning_cycle_year}`, planningCycle.planning_cycle_year),
  planning_cycle_name: createDropdownModel(planningCycle.scenario.planning_cycle_name, planningCycle.scenario.planning_cycle_name)
});

/**
 * Creates business group form data from planning cycle
 */
export const createBusinessGroup = (planningCycle: PlanningCycleEntity): AdminPlanningCycleBusinessGroupsForm => ({
  scenario_seq_id: planningCycle.scenario_seq_id,

  data_classification: createDropdownModel(
    planningCycle.data_classification.data_classification_name,
    `${planningCycle.data_classification.data_classification_id}`
  ),

  baseline_scenario: createDropdownModel(
    planningCycle.baseline_scenario?.baseline_scenario_name,
    planningCycle.baseline_scenario?.baseline_scenario_seq_id
  ),

  budget_owner_lock_date: planningCycle.budget_owner_lock_date,

  // Date ranges
  actuals_date_range: createDateRange(planningCycle.actuals_start_month_id, planningCycle.actuals_end_month_id),
  forecast_date_range: createDateRange(planningCycle.forecast_start_month_id, planningCycle.forecast_end_month_id),
  prophecy_actuals_date_range: createDateRange(planningCycle.prophecy_actuals_start_month_id, planningCycle.prophecy_actuals_end_month_id),
  prophecy_forecast_date_range: createDateRange(planningCycle.prophecy_forecast_start_month_id, planningCycle.prophecy_forecast_end_month_id),
  planning_cycle_date_time_range: createDateRange(planningCycle.planning_cycle_open_date, planningCycle.planning_cycle_lock_date),

  // Prophecy related fields
  forecast_data_available: planningCycle.forecast_data_available,
  is_prophecy_enabled: planningCycle.is_prophecy_enabled,
  last_synced_to_prophecy: planningCycle.last_synced_to_prophecy,
  prophecy_created_at: planningCycle.prophecy_created_at,
  prophecy_created_by: planningCycle.prophecy_created_by,

  // Metadata
  is_active: planningCycle.is_active,
  created_at: planningCycle.created_at,
  created_by: planningCycle.created_by,
  updated_at: planningCycle.updated_at,
  updated_by: planningCycle.updated_by
});

/**
 * Converts "AdminPlanningCycleForm" to "PlanningCycle"
 * @param form AdminPlanningCycleForm
 * @param userAlias userAlias
 * @param businessGroups BusinessGroupEntity[]
 * @returns PlanningCycle
 */
export const convertToPlanningCycle = (
  form: AdminPlanningCycleForm,
  userAlias: string,
  businessGroups: BusinessGroupEntity[]
): PlanningCycleEntity[] => {
  /**
   * Creates date fields with UTC conversion
   * @param dateRange DateAbsoluteRangePicker object
   * @returns object with start and end dates in UTC
   */
  const createDateFields = (dateRange: DateAbsoluteRangePicker | undefined) => {
    if (!dateRange) return { startDate: undefined, endDate: undefined };
    return {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate
    };
  };

  /**
   * Creates planning cycle date fields with UTC conversion
   * @param dateRange DateAbsoluteRangePicker object
   * @returns object with converted dates
   */
  const createPlanningCycleDateFields = (dateRange: DateAbsoluteRangePicker | undefined) => {
    if (!dateRange) return { openDate: undefined, lockDate: undefined };
    return {
      openDate: convertPacificTimeToUTC(dateRange.startDate),
      lockDate: convertPacificTimeToUTC(dateRange.endDate)
    };
  };

  /**
   * Creates audit fields based on action type
   * @param existingData existing audit data
   * @param action 'Create' or 'Edit'
   * @returns audit fields
   */
  const createAuditFields = (existingData: { created_at?: string; created_by?: string }, action: 'Create' | 'Edit') => ({
    created_at: action === 'Create' ? getCurrentUTCTimeInISO() : existingData.created_at,
    created_by: action === 'Create' ? userAlias : existingData.created_by,
    updated_at: getCurrentUTCTimeInISO(),
    updated_by: userAlias
  });

  const parsedPlanningCycle = form.business_groups
    .map((businessGroupForm) => {
      const businessGroup = getBusinessGroupByName(businessGroupForm.data_classification.label, businessGroups);

      if (!businessGroup?.data_classification) {
        logger.error(`Business group not found for: ${businessGroupForm.data_classification.label}`);
        return null;
      }

      const formAction = businessGroupForm.scenario_seq_id ? 'Edit' : 'Create';

      // Create date ranges
      const actualsDate = createDateFields(businessGroupForm.actuals_date_range);
      const forecastDate = createDateFields(businessGroupForm.forecast_date_range);
      const prophecyActualsDate = createDateFields(businessGroupForm.prophecy_actuals_date_range);
      const prophecyForecastDate = createDateFields(businessGroupForm.prophecy_forecast_date_range);
      const planningCycleDate = createPlanningCycleDateFields(businessGroupForm.planning_cycle_date_time_range);

      return {
        // Basic Information
        scenario_seq_id: businessGroupForm.scenario_seq_id,
        planning_cycle_year: +form.planning_cycle_year.label,

        // Scenario Information
        scenario: {
          scenario_id: +form.scenario.value,
          scenario_name: form.scenario.label,
          planning_cycle_name: form.planning_cycle_name.label
        },

        // Baseline Scenario
        baseline_scenario: {
          baseline_scenario_name: businessGroupForm.baseline_scenario.label || '',
          baseline_scenario_seq_id: businessGroupForm.baseline_scenario.value ? Number(businessGroupForm.baseline_scenario.value) || null : null
        },

        // Business Group Data
        data_classification: businessGroup.data_classification,

        // Date Ranges
        actuals_start_month_id: actualsDate.startDate,
        actuals_end_month_id: actualsDate.endDate,
        forecast_start_month_id: forecastDate.startDate,
        forecast_end_month_id: forecastDate.endDate,
        prophecy_actuals_start_month_id: prophecyActualsDate.startDate,
        prophecy_actuals_end_month_id: prophecyActualsDate.endDate,
        prophecy_forecast_start_month_id: prophecyForecastDate.startDate,
        prophecy_forecast_end_month_id: prophecyForecastDate.endDate,

        // Prophecy Related Fields
        is_prophecy_enabled: businessGroupForm.is_prophecy_enabled,
        last_synced_to_prophecy: businessGroupForm.last_synced_to_prophecy,
        prophecy_created_at: businessGroupForm.prophecy_created_at,
        prophecy_created_by: businessGroupForm.prophecy_created_by,

        // Forecast Information
        forecast_data_available: businessGroupForm.forecast_data_available,
        export_s3_bucket: getForecastS3BucketName().bucketName,

        // Planning Cycle Dates
        planning_cycle_open_date: planningCycleDate.openDate,
        planning_cycle_lock_date: planningCycleDate.lockDate,
        budget_owner_lock_date: businessGroupForm.budget_owner_lock_date ? convertPacificTimeToUTC(businessGroupForm.budget_owner_lock_date) : null,

        // Status and Audit Fields
        is_active: businessGroupForm.is_active,
        ...createAuditFields(businessGroupForm, formAction)
      };
    })
    .filter((cycle) => cycle !== null) as PlanningCycleEntity[];

  return parsedPlanningCycle;
};

/**
 * Converting Planning Cycle to Non Admin (BL) Planning Cycle form
 * @param planningCycle
 * @returns NonAdminPlanningCycleForm
 */
export const convertPlanningCycleAPIToNonAdminPlanningCycleForm = (planningCycle: PlanningCycleEntity): NonAdminPlanningCycleForm => {
  return {
    scenario_seq_id: planningCycle.scenario_seq_id,
    scenario_year: planningCycle.scenario_year,
    planning_cycle_date_time_range:
      planningCycle.planning_cycle_open_date && planningCycle.planning_cycle_lock_date
        ? {
            type: 'absolute',
            startDate: planningCycle.planning_cycle_open_date,
            endDate: planningCycle.planning_cycle_lock_date
          }
        : undefined,
    datePickerValue: planningCycle.budget_owner_lock_date ? convertToUserLocalAndSplitDateTime(planningCycle.budget_owner_lock_date).date : '',
    timePickerValue: planningCycle.budget_owner_lock_date ? convertToUserLocalAndSplitDateTime(planningCycle.budget_owner_lock_date).time : '',
    budget_owner_lock_date: planningCycle.budget_owner_lock_date || '',
    last_updated_at: planningCycle.updated_at,
    last_updated_by: planningCycle.updated_by,

    is_prophecy_enabled: planningCycle.is_prophecy_enabled,
    prophecy_created_at: planningCycle.prophecy_created_at,
    prophecy_created_by: planningCycle.prophecy_created_by,
    last_synced_to_prophecy: planningCycle.last_synced_to_prophecy
  };
};

/**
 * Converting BL Planning Cycle form to Planning Cycle.
 * Also, scenario_year is removing as it belongs to only frontend. It is not part of the API
 * @param planningCycle
 * @returns NonAdminPlanningCycleForm
 */
export const convertNonAdminPlanningCycleFormToAPI = (
  currentPlanningCycle: PlanningCycleEntity,
  planningCycleForm: NonAdminPlanningCycleForm,
  userAlias: string
): PlanningCycleEntity => {
  // Destructure scenario_year out and capture the rest of the properties
  const { scenario_year, ...restCurrentPlanningCycle } = currentPlanningCycle;

  let prophecy_created_at = null;
  let prophecy_created_by = null;

  if (currentPlanningCycle.is_prophecy_enabled !== planningCycleForm.is_prophecy_enabled) {
    prophecy_created_at = getCurrentUTCTimeInISO();
    prophecy_created_by = userAlias;
  } else {
    prophecy_created_at = currentPlanningCycle.prophecy_created_at;
    prophecy_created_by = currentPlanningCycle.prophecy_created_by;
  }

  return {
    ...restCurrentPlanningCycle,
    is_prophecy_enabled: planningCycleForm.is_prophecy_enabled,
    prophecy_created_at: prophecy_created_at,
    prophecy_created_by: prophecy_created_by,

    last_synced_to_prophecy: planningCycleForm.last_synced_to_prophecy,
    export_s3_bucket: getForecastS3BucketName().bucketName,
    budget_owner_lock_date: convertPacificTimeToUTC(planningCycleForm.budget_owner_lock_date),
    updated_at: getCurrentUTCTimeInISO(),
    updated_by: userAlias
  } as PlanningCycleEntity;
};

export const planningCycleWindowText = (planningCycle: PlanningCycleEntity, isAdmin: boolean): string => {
  const lockDate = isAdmin && planningCycle.budget_owner_lock_date ? planningCycle.budget_owner_lock_date : planningCycle.planning_cycle_lock_date;
  return lockDate && planningCycle.planning_cycle_open_date
    ? `${getReadableFormatOfMonthDateYear(planningCycle.planning_cycle_open_date)} - ${formatUTCAsLocalReadable(lockDate)}`
    : '-';
};

/**
 * Converts PlanningCycle to PlanningCycleFlatTable for Widgets & other table with default Property Filtering
 */
export const convertPlanningsToFlat = (planningCycles: PlanningCycleEntity[], isAdmin: boolean): PlanningCycleFlatTable[] => {
  return planningCycles.map((planningCycle) => {
    const { isLocked, buttonText, statusType } = calculateLockDates(planningCycle, isAdmin);

    return {
      scenario_seq_id: planningCycle.scenario_seq_id,
      scenario_name: planningCycle.scenario.scenario_name,
      scenario_year: planningCycle.scenario_year,
      data_classification_id: planningCycle.data_classification.data_classification_id,
      data_classification_name: planningCycle.data_classification.data_classification_name,
      actuals_date_range: getActualsDateRange(planningCycle),
      forecast_date_range: getForecastDateRange(planningCycle),
      budget_owner_lock_date: planningCycle.budget_owner_lock_date ? formatUTCAsLocalReadable(planningCycle.budget_owner_lock_date) : null,
      planning_cycle_window: planningCycleWindowText(planningCycle, isAdmin),

      isLocked: isLocked,
      planningCycleLockStatusMessage: buttonText,
      statusType: statusType,

      is_active: planningCycle.is_active,
      created_at: planningCycle.created_at,
      created_by: planningCycle.created_by,
      updated_at: planningCycle.updated_at,
      updated_by: planningCycle.updated_by
    };
  });
};

export const getActualsDateRange = (cycle: PlanningCycleEntity): string => {
  return `${getReadableFormatOfMonthYear(cycle.actuals_start_month_id || '')} - ${getReadableFormatOfMonthYear(cycle.actuals_end_month_id || '')}`;
};

export const getForecastDateRange = (cycle: PlanningCycleEntity): string => {
  return `${getReadableFormatOfMonthYear(cycle.forecast_start_month_id || '')} - ${getReadableFormatOfMonthYear(cycle.forecast_end_month_id || '')}`;
};

export const isValidScenarioYear = (scenarioYear: string, planningCycles: PlanningCycleEntity[]) => {
  return planningCycles.some((planningCycle) => planningCycle.scenario_year === scenarioYear);
};

// Filters planning cycles by scenario year, aiding in narrowing down cycles for a given year.
export const selectPlanningCyclesByScenarioYear = (scenarioYear: string, planningCycles: PlanningCycleEntity[]) => {
  return planningCycles.filter((planningCycle) => planningCycle.scenario_year === scenarioYear);
};

// Factory selector for finding a planning cycle based on scenario year and data classification name.
export const selectPlanningCycleByScenarioYearAndDataClassification = (
  scenarioYear: string,
  dataClassificationName: string,
  planningCycles: PlanningCycleEntity[]
) => {
  return planningCycles.find(
    (cycle) => cycle.scenario_year === scenarioYear && cycle.data_classification.data_classification_name === dataClassificationName
  );
};

// Selector for filtering planning cycles by data classification ID.
export const selectPlanningCyclesByDataClassificationId = (dataClassificationId: number, activePlanningCycles: PlanningCycleEntity[]) => {
  return activePlanningCycles.filter((cycle) => cycle.data_classification.data_classification_id === dataClassificationId);
};

export const findPlanningCyclesByScenarioSeqId = (scenarioSeqId: number, activePlanningCycles: PlanningCycleEntity[]) => {
  return activePlanningCycles.find((cycle) => cycle.scenario_seq_id === scenarioSeqId);
};

// Selector for filtering planning cycles by data classification Name.
export const selectPlanningCyclesByDataClassificationName = (dataClassificationName: string, planningCycles: PlanningCycleEntity[]) => {
  return planningCycles.filter((cycle) => cycle.data_classification.data_classification_name === dataClassificationName);
};

export const atLeastOnePlanningCycleIsActive = (dataClassificationId: number, activePlanningCycles: PlanningCycleEntity[]): boolean => {
  return activePlanningCycles.some((cycle) => cycle.data_classification.data_classification_id === dataClassificationId);
};

/**
 * Retrieves baseline scenario options for the dropdown selection.
 * Returns planning cycles from previous year onwards for a specific business group.
 * Planning cycles are sorted by scenario sequence ID in descending order.
 *
 * @param groupName - Business group name to filter planning cycles
 * @param allPlanningCycles - Complete list of available planning cycles
 * @returns Array of select options with scenario year as label and sequence ID as value
 */
// Updated Oct 13, 2024: Include planning cycles from previous year onwards per team discussion
export const getBaseLineScenarioOptions = (groupName: string, allPlanningCycles: PlanningCycleEntity[]): SelectProps.Option[] => {
  const previousYear = getYearWithOffset(-1);

  return allPlanningCycles
    .filter(
      ({ data_classification, planning_cycle_year }) =>
        data_classification.data_classification_name === groupName && planning_cycle_year >= previousYear
    )
    .sort((a, b) => compareNullableNumbers(a.scenario_seq_id, b.scenario_seq_id, 'desc'))
    .map(({ scenario_seq_id, scenario_year }) => ({
      label: `${scenario_year}`,
      value: `${scenario_seq_id}`
    }));
};

export const getPlanningCycleYearOptions = (): SelectProps.Option[] => {
  const currentYear = getYearWithOffset();
  const nextYear = getYearWithOffset(1);

  return [
    { label: currentYear.toString(), value: currentYear.toString() },
    { label: nextYear.toString(), value: nextYear.toString() }
  ];
};

// Helper function to calculate lock dates and status
export const calculateLockDates = (planningCycle: PlanningCycleEntity, isAdmin: boolean) => {
  // Get opening date in local time
  const openLockDateInLocalTime = moment.utc(planningCycle.planning_cycle_open_date).local().format();

  // Determine the appropriate lock date based on admin status
  const lockDateKey = isAdmin ? 'planning_cycle_lock_date' : 'budget_owner_lock_date';
  const lockDateUTC = planningCycle[lockDateKey] || planningCycle.planning_cycle_lock_date;
  const closingDateInLocalTime = moment.utc(lockDateUTC).local().format();

  // Determine if the cycle is locked
  const now = moment();
  const isLocked = now.isBefore(openLockDateInLocalTime) || now.isAfter(closingDateInLocalTime);

  let buttonText = '';
  let popOverText = '';
  let statusType: 'info' | 'success' | 'error' = 'info';

  if (now.isBefore(openLockDateInLocalTime)) {
    // Before opening date
    buttonText = getRemainingTimeDetails(openLockDateInLocalTime, 'to open');
    popOverText = `Opening on: ${formatUTCAsLocalReadable(openLockDateInLocalTime)}`;
    statusType = 'info';
  } else if (now.isBetween(openLockDateInLocalTime, closingDateInLocalTime)) {
    // Between opening and closing dates
    buttonText = getRemainingTimeDetails(closingDateInLocalTime, 'left');
    popOverText = `Closing on: ${formatUTCAsLocalReadable(closingDateInLocalTime)}`;
    statusType = 'success';
  } else {
    // After closing date
    buttonText = 'Locked';
    popOverText = `Closed on: ${formatUTCAsLocalReadable(closingDateInLocalTime)}`;
    statusType = 'error';
  }

  return { openLockDateInLocalTime, closingDateInLocalTime, isLocked, buttonText, popOverText, statusType };
};

/**
 * This function will disable adding new business group button if all of the data classification id (userDataClassificationIds) already exists in the form (formDataClassificationIds)
 * @param userDataClassificationIds are data classification ids that current user owns
 * @param formDataClassificationIds are exiting data classification ids in the form
 * @returns boolean indicating if adding new business group should be disabled
 */
export const disableAddingNewBusinessGroupButton = (userDataClassificationIds: number[], formDataClassificationIds: number[]): boolean => {
  // Check if every user data classification id exists in the form data classification ids
  return userDataClassificationIds.every((id) => formDataClassificationIds.includes(id));
};
