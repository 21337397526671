import React from 'react';
import type { CustomTooltipProps } from 'ag-grid-react';

const DEFAULT_BACKGROUND_COLOR = '#999';

interface AgGridCellTooltipProps extends CustomTooltipProps {
  backgroundColor?: string;
  filedName: string;
}

const AgGridCellTooltip: React.FC<AgGridCellTooltipProps> = ({ backgroundColor = DEFAULT_BACKGROUND_COLOR, filedName, value }) => {
  return (
    <div
      className="business-segment-custom-tooltip"
      style={{
        backgroundColor,
        opacity: value ? 1 : 0.7 // Optional: reduce opacity if no value
      }}
    >
      <p className="tooltip-header">{filedName}</p>
      <p className="tooltip-content">{value || 'No value available'}</p>
    </div>
  );
};

export default AgGridCellTooltip;
