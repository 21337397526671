import { FlashbarProps } from '@amzn/awsui-components-react';
import { useCallback, useState } from 'react';
import { logger } from 'src/analytics/KatalLogger';
import { eErrorMessages } from 'src/constants/generic-constants';
import { Status } from 'src/models/AuthContextModels';
import { getAllObjectKeysFromS3 } from 'src/utils/aws-s3-services';

interface useActualsS3KeysProps {
  dataClassificationId: number;
  dataClassificationShortDesc: string;
  bucketName: string;
  notifyFlashMessage: (content: string, flashBarType: FlashbarProps.Type, isDismissible: boolean, messageId?: string | undefined) => void;
}

export const ACTUAL_FILE_SUFFIX = 'list_actuals_data.json';
export const DISTRIBUTION_FILE_SUFFIX = 'list_actuals_distribution_data_test.json';

export const useActualsS3Keys = ({ dataClassificationId, dataClassificationShortDesc, bucketName, notifyFlashMessage }: useActualsS3KeysProps) => {
  // Tracks the loading state of S3 keys fetching process
  const [s3KeysFetchStatus, setS3KeysFetchStatus] = useState<Status>(Status.NotInitiated);
  // Stores the S3 keys for actuals data
  const [actualsDataS3Keys, setActualsDataS3Keys] = useState<string[]>([]);
  // Stores the S3 keys for distribution data
  const [distributionDataS3Keys, setDistributionDataS3Keys] = useState<string[]>([]);

  /**
   * Initializes S3 keys by fetching and filtering data from the specified bucket
   * Separates keys into actuals data and distribution data
   * Updates the respective state variables and handles error cases
   */
  const initializeS3Keys = useCallback(async () => {
    if (!dataClassificationId || !dataClassificationShortDesc) return;

    try {
      setS3KeysFetchStatus(Status.Loading);

      // Construct the S3 key prefix for the specific data classification
      const s3KeyPrefix = `${dataClassificationShortDesc}_${dataClassificationId}/`;

      // Fetch all S3 keys for the given classification
      const allS3ActualKeysForDataClassification = await getAllObjectKeysFromS3(bucketName, s3KeyPrefix);

      // Filter keys for actuals data
      const actualsDataOnlyKeys = allS3ActualKeysForDataClassification.filter((key) => !key.includes('mutation') && key.endsWith(ACTUAL_FILE_SUFFIX));

      // Filter keys for distribution data
      const distributionDataOnlyKeys = allS3ActualKeysForDataClassification.filter(
        (key) => !key.includes('mutation') && key.endsWith(DISTRIBUTION_FILE_SUFFIX)
      );

      // Update state with filtered keys
      setActualsDataS3Keys(actualsDataOnlyKeys);
      setDistributionDataS3Keys(distributionDataOnlyKeys);

      // Show error message if no actuals data is available
      if (actualsDataOnlyKeys.length === 0) {
        notifyFlashMessage(`No data available for this business group, ${eErrorMessages.GENERIC_ERROR_WITH_TICKET_INFO}`, 'error', true);
      }

      setS3KeysFetchStatus(Status.Completed);
    } catch (error: any) {
      logger.error('Error while fetching s3 keys:', error);
      notifyFlashMessage(`Unable to load data for this business group, ${eErrorMessages.GENERIC_ERROR_WITH_TICKET_INFO}`, 'error', true);
      setS3KeysFetchStatus(Status.Failed);
    }
  }, [dataClassificationId, dataClassificationShortDesc, bucketName, notifyFlashMessage]);

  return {
    initializeS3Keys,
    s3KeysFetchStatus,
    actualsDataS3Keys,
    distributionDataS3Keys
  };
};
