import { Box, Button, Container, FlashbarProps, Header, SpaceBetween } from '@amzn/awsui-components-react';
import {
  ColDef,
  ColumnGroupOpenedEvent,
  ExcelExportParams,
  ExcelStyle,
  GetRowIdParams,
  GridReadyEvent,
  ProcessCellForExportParams
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { logUserClick } from 'src/analytics/KatalLogger';
import { LoadingSpinner } from 'src/components/common/LoadingSpinner';
import { useGridStateV2 } from 'src/hooks/useGridStateV2';
import { XptReportExportFileDetails } from 'src/models/XptReportingModels';
import { RootState } from 'src/store/store';
import { CellDataType, processCellForClipboard, StatusBarConfig } from 'src/utils/ag-grid-utils';
import { useAuth } from '../../app/auth/AuthContextProvider';
import {
  AUTO_COLUMN_GROUP_DEF_DEFAULT,
  COLUMN_DEF_DEFAULT,
  GROUP_DISPLAY_TYPE,
  GROUP_EXPANSION_LEVEL,
  ROW_GROUP_PANEL_SHOW,
  TOTAL_ROW_POSITION,
  XptReportGridFixedFields
} from './XptReportGridConstants';
import { GridErrorAlert, PersistChangesButtonDropdown } from './XptReportGridUtilsComponents';
import { getReportingSideNavBarConfig } from './XptReportingUtils';
import { isEmptyValue } from 'src/utils/generic-utilities';
import { formatISOToReadable } from 'src/utils/date-time-utilities';

interface ReportGridProps {
  gridRef: React.RefObject<AgGridReact>;
  showPivotModeOptionInPanel: boolean;
  columnDefs: ColDef[];
  rowData: any[];
  uniqueIdField: string;
  isLoading: boolean;
  localStorageKey: string;
  refreshReport: () => void;
  exportFileDetails?: XptReportExportFileDetails;
  notificationMessage: (content: string, flashBarType: FlashbarProps.Type, isDismissible: boolean, messageId?: string) => void;
  enablePivotMode: boolean;
  page: string;
  mainPage: string;
}

export const ReportGrid: React.FC<ReportGridProps> = ({
  gridRef,
  showPivotModeOptionInPanel,
  columnDefs,
  rowData,
  uniqueIdField,
  isLoading,
  localStorageKey,
  refreshReport,
  exportFileDetails,
  notificationMessage,
  enablePivotMode,
  page,
  mainPage
}) => {
  const { userAlias: Alias } = useAuth();
  const themeClassName = useSelector((state: RootState) => state.xptAppMetadataStore.themeClassName);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const { saveGridState, restoreGridState, clearGridState, atLeastOneStateAvailable, isPivotMode } = useGridStateV2(gridRef, localStorageKey);

  const computedPivotMode = useMemo(() => {
    return atLeastOneStateAvailable() ? isPivotMode : enablePivotMode;
  }, [isPivotMode, enablePivotMode, atLeastOneStateAvailable]);

  const getRowId = (params: GetRowIdParams) => params.data[uniqueIdField]?.toString();

  const onGridReady = (params: GridReadyEvent) => {
    // Call restoreGridState here to handle any restoration after grid is ready
    if (atLeastOneStateAvailable()) {
      restoreGridState();
    }
    autoSizeAll(false);
  };

  useEffect(() => {
    // call restoreGridState whenever columnDefs or rowData change
    if (atLeastOneStateAvailable()) {
      restoreGridState();
    }
  }, [columnDefs, rowData]);

  const onColumnGroupOpened = useCallback((params: ColumnGroupOpenedEvent) => {
    autoSizeAll(false);
  }, []);

  const autoSizeAll = useCallback((skipHeader: boolean) => {
    if (!atLeastOneStateAvailable()) {
      const allColumnIds: string[] = [];
      gridRef?.current?.api.getColumns()?.forEach((column) => {
        allColumnIds.push(column.getId());
      });
      gridRef?.current?.api.autoSizeColumns(allColumnIds, skipHeader);
      gridRef?.current?.api.refreshCells();
    }
  }, []);

  const processCellForExport = ({ value, column }: ProcessCellForExportParams) => {
    // Return empty string for null/undefined values
    if (isEmptyValue(value)) {
      return '';
    }

    // Format timestamp for UpdatedAt column
    const isUpdatedAtColumn = column.getColId() === XptReportGridFixedFields.UpdatedAt.value;
    if (isUpdatedAtColumn) {
      return formatISOToReadable(value);
    }

    // Add single quote prefix for text fields
    const isTextField = column.getColDef().cellDataType === CellDataType.TEXT;
    return isTextField ? `'${value}` : value;
  };

  const handleExportToExcel = () => {
    if (exportFileDetails) {
      const exportParams: ExcelExportParams = {
        author: Alias,
        fileName: exportFileDetails.fileName,
        sheetName: exportFileDetails.sheetName,
        freezeRows: `headers`,
        processCellCallback: processCellForExport
      };
      logUserClick('Exported to Excel', page, mainPage);
      gridRef.current?.api.exportDataAsExcel(exportParams);
    }
  };

  const handleResetView = () => {
    clearGridState();
    notificationMessage('View reset to default view', 'success', true);
    setTimeout(() => {
      logUserClick('reset to default view', page, mainPage);
      refreshReport();
    }, 0);
  };

  const onChangeOfUserEvents = (eventName: string) => {
    logUserClick(eventName, page, mainPage);
  };

  return (
    <Box padding={{ top: 'm' }}>
      <Container
        disableContentPaddings
        header={
          <Header
            variant="h2"
            actions={
              <SpaceBetween size="m" direction="horizontal">
                <Button
                  iconName="refresh"
                  disabled={isLoading}
                  onClick={() => {
                    logUserClick(`Refreshed ${page}`, page, mainPage);
                    refreshReport();
                  }}
                />
                <Button iconName="download" disabled={isLoading} onClick={handleExportToExcel}>
                  Export to Excel
                </Button>
                {
                  <PersistChangesButtonDropdown
                    isLoading={isLoading}
                    handleResetView={handleResetView}
                    saveGridState={() => {
                      logUserClick('Saved current view', page, mainPage);
                      saveGridState();
                    }}
                    notificationMessage={notificationMessage}
                  />
                }
              </SpaceBetween>
            }
          />
        }
      >
        <div className="xpt-report-ag-grid-container">
          <div className={themeClassName} style={gridStyle}>
            <ErrorBoundary FallbackComponent={() => <GridErrorAlert />}>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <AgGridReact
                  ref={gridRef}
                  onGridReady={onGridReady}
                  getRowId={getRowId}
                  columnDefs={columnDefs}
                  rowData={rowData}
                  onColumnGroupOpened={onColumnGroupOpened}
                  sideBar={getReportingSideNavBarConfig(showPivotModeOptionInPanel)}
                  processCellForClipboard={processCellForClipboard}
                  onFilterChanged={() => {
                    onChangeOfUserEvents(`Modified side panel filter`);
                    gridRef?.current?.api?.refreshClientSideRowModel('aggregate');
                  }}
                  onColumnRowGroupChanged={() => onChangeOfUserEvents(`Changed row grouping`)}
                  onColumnPivotChanged={() => onChangeOfUserEvents(`Changed pivot column`)}
                  onColumnPivotModeChanged={() => onChangeOfUserEvents(`Changed pivot mode`)}
                  statusBar={StatusBarConfig}
                  grandTotalRow={TOTAL_ROW_POSITION}
                  rowGroupPanelShow={ROW_GROUP_PANEL_SHOW}
                  autoGroupColumnDef={AUTO_COLUMN_GROUP_DEF_DEFAULT}
                  defaultColDef={COLUMN_DEF_DEFAULT}
                  groupDisplayType={GROUP_DISPLAY_TYPE}
                  groupDefaultExpanded={GROUP_EXPANSION_LEVEL}
                  excelStyles={ReportingExcelStyles}
                  rowHeight={30}
                  pivotPanelShow="onlyWhenPivoting"
                  suppressCsvExport={true}
                  enterNavigatesVerticallyAfterEdit={true}
                  enterNavigatesVertically={true}
                  alwaysMultiSort={true}
                  enableAdvancedFilter={false}
                  suppressContextMenu={false}
                  suppressAggFuncInHeader={true}
                  groupMaintainOrder={true}
                  suppressRowGroupHidesColumns={true}
                  enableRangeHandle={true}
                  enableRangeSelection={true}
                  suppressMultiRangeSelection={true}
                  pivotMode={computedPivotMode}
                />
              )}
            </ErrorBoundary>
          </div>
        </div>
      </Container>
    </Box>
  );
};

export const ReportingExcelStyles = [
  {
    id: 'text-field',
    dataType: 'String'
  },
  {
    id: 'number-field',
    dataType: 'Number'
  }
] as ExcelStyle[];
