import { logger } from './KatalLogger';

/**
 * Constants for error messages and defaults
 */
export const ERROR_CONSTANTS = {
  MESSAGES: {
    UNKNOWN_ERROR: 'An unknown error occurred',
    LOGGER_REQUIRED: 'Logger instance is required'
  },
  DEFAULTS: {
    SEPARATOR: ': ',
    METADATA_START: ' (',
    METADATA_END: ')'
  }
} as const;

/**
 * Interface for error context options
 */
interface ErrorContextOptions {
  context?: string;
  metadata?: Record<string, unknown>;
}

/**
 * Formats an error message with optional context
 * @param error - The error to format
 * @param options - Optional context and metadata
 * @returns Formatted error message
 */
export const formatError = (error: unknown, options?: ErrorContextOptions): string => {
  const { context, metadata } = options || {};
  const { MESSAGES, DEFAULTS } = ERROR_CONSTANTS;

  const baseMessage =
    error instanceof Error
      ? error.message
      : typeof error === 'string'
      ? error
      : `${MESSAGES.UNKNOWN_ERROR} - Original error: ${JSON.stringify(error)}`;

  return [
    context && `${context}${DEFAULTS.SEPARATOR}`,
    baseMessage,
    metadata && `${DEFAULTS.METADATA_START}${JSON.stringify(metadata)}${DEFAULTS.METADATA_END}`
  ]
    .filter(Boolean)
    .join('');
};

/**
 * Generic error handler with logging
 * @template T - Type of fallback value
 * @param error - The error to handle
 * @param context - Error context description
 * @param fallback - Fallback value to return
 * @returns Fallback value of type T
 */
export const handleError = <T>(error: unknown, context: string, fallback: T): T => {
  if (!logger) {
    throw new Error(ERROR_CONSTANTS.MESSAGES.LOGGER_REQUIRED);
  }

  const errorMessage = formatError(error, { context });
  logger.error(errorMessage);

  return fallback;
};

/**
 * Type guard to check if value is Error instance
 */
export const isError = (value: unknown): value is Error => {
  return value instanceof Error;
};

// Freeze exports to prevent modifications
Object.freeze(ERROR_CONSTANTS);
Object.freeze(formatError);
Object.freeze(handleError);
Object.freeze(isError);
